import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'mde-popover-target, [mdePopoverTarget]',
  exportAs: 'mdePopoverTarget',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MdePopoverTarget {
  // tslint:disable-line:directive-class-suffix

  constructor(public _elementRef: ElementRef) {}
}
