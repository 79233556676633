import { Routes } from '@angular/router';

export const lomtRoutes: Routes = [
  {
    path: 'lomt',
    data: {
      mainTool: 'lomt',
      title: 'Live Ops Tool',
    },
    children: [
      {
        path: '',
        redirectTo: 'schedule',
        pathMatch: 'full',
      },
      // Lazy modules
      {
        path: 'segments',
        loadChildren: () =>
          import('@app/lomt/segments/segments.module').then(
            (m) => m.SegmentsModule,
          ),
      },
      {
        path: 'ab',
        loadChildren: () =>
          import('@app/lomt/ab/ab.module').then((m) => m.ABModule),
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('@app/lomt/schedule/schedule.module').then(
            (m) => m.SchedulesModule,
          ),
      },
      {
        path: 'contents',
        loadChildren: () =>
          import('@app/lomt/contents/contents.module').then(
            (m) => m.ContentsModule,
          ),
      },
      {
        path: 'contenttypes',
        loadChildren: () =>
          import('@app/lomt/contenttypes/contenttypes.module').then(
            (m) => m.ContentTypesModule,
          ),
      },
      {
        path: 'environments',
        loadChildren: () =>
          import('@app/lomt/environments/lomt-environment.module').then(
            (m) => m.LomtEnvironmentsModule,
          ),
      },
      {
        path: 'usersets',
        loadChildren: () =>
          import('@app/lomt/usersets/usersets.module').then(
            (m) => m.UsersetsModule,
          ),
      },
      {
        path: 'tags',
        loadChildren: () =>
          import('@app/lomt/tags/tags.module').then((m) => m.TagsModule),
      },
    ],
  },
];
