import { Component } from '@angular/core';
import { Beta, betaInfo, configurableBetas } from './beta.model';

const BETA_ACTIVE_VALUE = 'true';

@Component({
  selector: 'app-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.scss'],
})
export class BetaComponent {
  static HasOptedIn(beta: Beta): boolean {
    return localStorage.getItem(beta) === BETA_ACTIVE_VALUE;
  }

  static OptIn(beta: Beta): void {
    localStorage.setItem(beta, BETA_ACTIVE_VALUE);
  }

  static OptOut(beta: Beta): void {
    localStorage.removeItem(beta);
  }

  betas = configurableBetas;
  info = betaInfo;
  status: { [key: string]: boolean } = {};

  constructor() {
    for (const beta of this.betas) {
      this.status[beta] = BetaComponent.HasOptedIn(beta);
    }
  }

  enable(beta: Beta): void {
    this.status[beta] = true;
    BetaComponent.OptIn(beta);
  }

  disable(beta: Beta): void {
    this.status[beta] = false;
    BetaComponent.OptOut(beta);
  }
}
