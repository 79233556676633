import { PipeTransform, Pipe } from '@angular/core';
import { Chronos } from '@app/shared/utils/chronos';

@Pipe({ name: 'timestampToDate' })
export class TimestampToDate implements PipeTransform {
  transform(value: number | string | Date): Date {
    if (value instanceof Date) return value;

    if (!value || (typeof value !== 'string' && typeof value !== 'number'))
      return new Date();

    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }

    // milliseconds?
    if (typeof value === 'number' && value.toString().length > 11) {
      value = Math.round(value / 1000); // convert to seconds
    }
    const date = Chronos.createFromServer(value).forDatepicker();

    return date instanceof Date ? date : new Date();
  }
}
