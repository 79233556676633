import { Injectable } from '@angular/core';
import { Emoji, EmojiData, EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';

export const FIXED_VALUES = {
  sheetRows: 61,
  sheetColumns: 61,
  backgroundImageFn: (set: string, sheetSize: number) =>
    `https://cdn.jsdelivr.net/npm/emoji-datasource-${set}@14.0.0/img/${set}/sheets-256/${sheetSize}.png`,
};

@Injectable()
export class FixEmojiService extends EmojiService {
  /**
   * This overloaded class fixes params
   *   sheetRows: 57 -> 61
   *   sheetColumns: 58 -> 61
   *   backgroundImageFn: v6.0.1 -> v14.0.0
   */
  emojiSpriteStyles(
    sheet: EmojiData['sheet'],
    set?: Emoji['set'],
    size?: Emoji['size'],
    sheetSize?: Emoji['sheetSize'],
    sheetRows?: Emoji['sheetRows'],
    backgroundImageFn?: Emoji['backgroundImageFn'],
    sheetColumns?: number,
    url?: string,
  ): {
    width: string;
    height: string;
    display: string;
    'background-image': string;
    'background-size': string;
    'background-position': string;
  } {
    return super.emojiSpriteStyles(
      sheet,
      set,
      size,
      sheetSize,
      FIXED_VALUES.sheetRows,
      FIXED_VALUES.backgroundImageFn,
      FIXED_VALUES.sheetColumns,
      url,
    );
  }
}
