import { ModuleWithProviders, NgModule } from '@angular/core';

import { GoogleApiService, NG_GAPI_CONFIG } from './google-api.service';
import { GoogleAuthService } from '../auth/google-auth.service';
import { GoogleSheetsService } from '../sheets/google-sheets.service';
import { jarvisConfig } from './google-api.model';

@NgModule({})
export class GoogleApiModule {
  static forRoot(): ModuleWithProviders<GoogleApiModule> {
    return {
      ngModule: GoogleApiModule,
      providers: [
        {
          provide: NG_GAPI_CONFIG,
          useValue: jarvisConfig,
        },
        GoogleApiService,
        GoogleAuthService,
        GoogleSheetsService,
      ],
    };
  }
}
