import { Injectable } from '@angular/core';
import { concat, Observable } from 'rxjs';
import { toArray } from 'rxjs/operators';

import { Api } from '@app/providers/api/api';
import {
  DataModelView,
  DataModel,
  GroupModel,
  CompileData,
} from './ab-manager.model';
import { STATUS_TEXT } from '@app/shared/data-tables/data-tables.model';
import { SegmentsService } from '@app/lomt/shared/segments/segments.service';
import {
  Segment,
  SegmentMetaData,
  SegmentNode,
} from '@app/lomt/shared/segments/segments.model';
import { Chronos } from '@app/shared/utils/chronos';

@Injectable()
export class ABService {
  static getIDGroups(data: GroupModel[]): number[] {
    const groups: number[] = [];

    if (Array.isArray(data) && data.length) {
      const total: number = data.length;
      for (let i = 0; i < total; i++) {
        if (!data[i].id) continue;
        groups.push(data[i].id);
      }
    }

    return groups;
  }

  static compileData(obj: DataModelView): CompileData {
    let handler = 'update';
    const data: any = Object.assign({}, obj);

    data.start = Chronos.createFromDatepicker(obj.start).forServer();
    data.end = Chronos.createFromDatepicker(obj.end).forServer();
    if (obj.analytics_type === 'ngu') {
      data.analytics_start = Chronos.createFromDatepicker(
        obj.analytics_start,
      ).forServer();
      data.analytics_end = Chronos.createFromDatepicker(
        obj.analytics_end,
      ).forServer();
    } else if (obj.analytics_type === 'dau' && obj.analytics_capture) {
      data.analytics_start = Chronos.createFromDatepicker(
        obj.analytics_start,
      ).forServer();
      data.analytics_end = Chronos.createFromDatepicker(
        obj.analytics_end,
      ).forServer();
    } else {
      data.analytics_start = null;
      data.analytics_end = null;
    }

    // Is new?
    if (!obj.id) {
      delete data.id;
      handler = 'create';
    }

    return { handler, data };
  }

  static parseData(obj: DataModel, segments: Segment[] = []): DataModelView {
    const data: any = Object.assign({}, obj);
    data.start = Chronos.createFromServer(obj.start).forDatepicker();
    data.end = Chronos.createFromServer(obj.end).forDatepicker();
    data.analytics_start = obj.analytics_start
      ? Chronos.createFromServer(obj.analytics_start).forDatepicker()
      : null;
    data.analytics_end = obj.analytics_end
      ? Chronos.createFromServer(obj.analytics_end).forDatepicker()
      : null;

    // Replace segment expression by ID
    this.parseSegments(obj.segment_metadata, segments);

    data.segment_metadata =
      obj.segment_metadata !== null
        ? SegmentsService.compileNodes(obj.segment_metadata)
        : new SegmentNode();
    if (!Array.isArray(obj.groups) || !obj.groups) return data;

    const t = obj.groups.length;
    for (let i = 0; i < t; i++) {
      if (!obj.groups[i].schedules) obj.groups[i].schedules = [];

      const te = obj.groups[i].schedules.length;
      for (let e = 0; e < te; e++) {
        data.groups[i].schedules[e].start = obj.groups[i].schedules[e].start
          ? Chronos.createFromServer(
              obj.groups[i].schedules[e].start,
            ).forDatepicker()
          : null;
        data.groups[i].schedules[e].end = obj.groups[i].schedules[e].end
          ? Chronos.createFromServer(
              obj.groups[i].schedules[e].end,
            ).forDatepicker()
          : null;
        data.groups[i].schedules[e].parent = i;
        data.groups[i].schedules[e].segment_metadata =
          obj.groups[i].schedules[e].segment_metadata !== null
            ? SegmentsService.compileNodes(
                obj.groups[i].schedules[e].segment_metadata,
              )
            : new SegmentNode();
      }
    }

    return data;
  }

  static getStatus(start: Chronos, end: Chronos): string {
    const now = Chronos.create();

    if (now.isBefore(start)) {
      return STATUS_TEXT.future.toLowerCase();
    } else if (now.isBefore(end)) {
      return STATUS_TEXT.live.toLowerCase();
    } else {
      return STATUS_TEXT.expired.toLowerCase();
    }
  }

  private static diff(arrayA: number[], arrayB: number[]): number[] {
    const arrayB2Set = new Set(arrayB);
    return arrayA.filter((x) => !arrayB2Set.has(x));
  }

  private static parseSegments(
    data: SegmentMetaData,
    segments: Segment[] = [],
  ) {
    Object.keys(data).forEach((key) => {
      switch (key) {
        case 'nodes':
          const [item] = data[key];
          if (item) {
            this.parseSegments(item, segments);
          }
          break;
        case 'segments':
          data[key].forEach((segment: Segment) => {
            if (segments.length) {
              const result = segments.find(
                ({ id }: { id: number }) => id === segment.id,
              );
              if (
                segment.id &&
                result &&
                result.expression !== segment.expression
              ) {
                segment.expression = result.expression;
              }
            }
          });
          break;
      }
    });
  }

  constructor(private api: Api) {}

  _findDuplicatedIndex<T>(
    item: T,
    items: Array<T>,
    selectorFn: Function,
  ): number {
    return items.findIndex(
      (currentItem: T) => selectorFn(currentItem) === selectorFn(item),
    );
  }

  // remove duplicates in array, accepts selector function
  // to generate the criterion by which uniqueness is computed.
  uniq<T>(sourceArray: Array<T> = [], selectorFn: Function): Array<T> {
    return sourceArray.filter(
      (item: T, index: number, items: Array<T>): boolean =>
        this._findDuplicatedIndex(item, items, selectorFn) === index,
    );
  }

  compileGroups(
    data: GroupModel[],
    groupsID: number[],
    abtestID: number,
  ): Observable<Api[]> {
    let groups: any[] = [];
    let ids: number[] = [];

    if (data && data.length) {
      groups = JSON.parse(JSON.stringify(data));
      ids = ABService.getIDGroups(groups);
      const groupsTotal: number = groups.length;

      for (let i = 0; i < groupsTotal; i++) {
        groups[i].position = i;

        const t = groups[i].schedules.length;
        for (let e = 0; e < t; e++) {
          groups[i].schedules[e].start = Chronos.createFromDatepicker(
            groups[i].schedules[e].start,
          ).forServer();
          groups[i].schedules[e].end = Chronos.createFromDatepicker(
            groups[i].schedules[e].end,
          ).forServer();

          // Remove metadata
          delete groups[i].schedules[e].created_by;
          delete groups[i].schedules[e].updated_by;
          delete groups[i].schedules[e].created_at;
          delete groups[i].schedules[e].updated_at;
        }
      }
    }

    /** Return a single Observable handling the execution of all the queries
    /* FIRST delete removed groups
    /* SECOND create new groups
     */
    return concat(
      ...this.getDeletedGroups(groupsID, ids),
      ...this.getApiGroups(groups, abtestID),
    ).pipe(toArray());
  }

  // Private methods
  private getDeletedGroups(
    arrayA: number[],
    arrayB: number[],
  ): Observable<Api>[] {
    const api: Array<Observable<Api>> = [];
    const ids: number[] = ABService.diff(arrayA, arrayB).concat(
      ABService.diff(arrayB, arrayA),
    );
    const total: number = ids.length;

    for (let i = 0; i < total; i++) {
      api[i] = this.api.rpc(
        {
          handler: 'delete',
          repository: 'abtests/groups',
        },
        {
          id: ids[i],
        },
      );
    }

    return api;
  }

  private getApiGroups(
    groups: GroupModel[],
    abtestID: number,
  ): Observable<Api>[] {
    const api: Array<Observable<Api>> = [];

    if (groups && groups.length) {
      const total: number = groups.length;
      for (let i = 0; i < total; i++) {
        let handler = 'update';

        if (!groups[i].id) {
          handler = 'create';
          groups[i].abtest = abtestID;
          delete groups[i].id;
        }

        api[i] = this.api.rpc(
          {
            handler,
            repository: 'abtests/groups',
          },
          groups[i],
        );
      }
    }

    return api;
  }
}
