import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { GithubInterceptor } from './github.interceptor';
import { GithubService } from './github.service';
import {
  GITHUB_CONFIG,
  GITHUB_INTERCEPTOR_SETTINGS,
  GithubParams,
} from './github.model';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GithubInterceptor,
      multi: true,
    },
  ],
})
export class GithubModule {
  static forRoot(params: GithubParams): ModuleWithProviders<GithubModule> {
    return {
      ngModule: GithubModule,
      providers: [
        GithubService,
        {
          provide: GITHUB_INTERCEPTOR_SETTINGS,
          useValue: params.interceptor || {},
        },
        {
          provide: GITHUB_CONFIG,
          useValue: params.config || {},
        },
      ],
    };
  }
}
