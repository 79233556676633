<div class="tableNestedRow">
  <span class="table-header-row">
    <div
      class="table-cell"
      [class.media-visible]="!cell.important"
      *ngFor="let cell of columnsDef"
      [ngStyle]="{ 'flex-grow': cell.flexGrow }"
    >
      {{ cell.headerName }}
    </div>
  </span>
  <span class="table-body">
    <mat-accordion #tableRow [multi]="false" *ngFor="let rowData of data">
      <mat-expansion-panel [disabled]="!rowsDef.hasChild(rowData)">
        <mat-expansion-panel-header class="table-main-row">
          <div
            class="table-cell"
            [class.media-visible]="!cell.important"
            *ngFor="let cell of columnsDef"
            [ngStyle]="{ 'flex-grow': cell.flexGrow }"
            matTooltip="{{ cell.tooltip ? cell.tooltip(rowData) : null }}"
            [matTooltipPosition]="'after'"
          >
            <div [innerHTML]="cell.field ? cell.field(rowData) : null"></div>
          </div>
        </mat-expansion-panel-header>

        <div
          class="table-child-row"
          *ngFor="let childRowData of rowsDef.getChild(rowData)"
        >
          <div
            class="table-cell"
            [class.media-visible]="!cell.important"
            *ngFor="let cell of columnsDef"
            [ngStyle]="{ 'flex-grow': cell.flexGrow }"
            matTooltip="{{
              cell.childTooltip ? cell.childTooltip(childRowData) : null
            }}"
          >
            <div
              [innerHTML]="
                cell.childField ? cell.childField(childRowData) : null
              "
            ></div>
          </div>
        </div>

        <div class="table-child-details" *ngIf="rowsDef.hasDetail(rowData)">
          <div class="table-cell">
            <div
              [innerHTML]="
                rowsDef.getDetail ? rowsDef.getDetail(rowData) : null
              "
            ></div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </span>
</div>
