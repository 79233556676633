<h3 *ngIf="title">
  {{ title
  }}<mat-hint *ngIf="tooltip">
    <mat-icon class="info-icon" [matTooltip]="tooltip">info</mat-icon>
  </mat-hint>
</h3>

<div class="card" [class.padding]="padding">
  <ng-content></ng-content>
</div>
<mat-divider class="divider"></mat-divider>
