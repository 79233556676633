<mat-card appearance="outlined" class="chart">
  <mat-card-content>
    <div *ngIf="!loading; else loader">
      <canvas
        *ngIf="datasets.length; else noData"
        baseChart
        [datasets]="datasets"
        [labels]="labels"
        type="bar"
        [options]="options"
      ></canvas>
      <ng-template #noData>
        <p>No chart available</p>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #loader>
  <h4>Loading delivery graph ...</h4>
  <br />
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
