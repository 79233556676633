import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { GoogleAuthService } from '../auth/google-auth.service';
import { GoogleApiService } from '../api/google-api.service';
import { DriveFile, GapiSheets } from './google-sheets.model';

@Injectable()
export class GoogleSheetsService {
  static forEachCell<T>(
    sheet: GapiSheets.RowData[],
    middleware: (cell: GapiSheets.CellData) => T,
    callback: (row: number, col: number, cell: T) => void,
  ) {
    sheet.forEach(({ values = [] }: GapiSheets.RowData, row: number) =>
      values.forEach((cell: GapiSheets.CellData, col: number) =>
        callback(row, col, middleware(cell)),
      ),
    );
  }

  static getDriveFilesMessage(message): DriveFile[] {
    return [{ id: '', name: message }];
  }

  constructor(
    private gapiService: GoogleApiService,
    private authService: GoogleAuthService,
  ) {}

  getSheetValues(
    spreadsheetId: string,
    sheetId: number,
    fields: string,
  ): Promise<GapiSheets.RowData[]> {
    return this.getSheet(
      spreadsheetId,
      { sheetId },
      `sheets.data.rowData.values(${fields})`,
    ).then((sheet) => sheet.data[0].rowData);
  }

  getSheetValue(
    spreadsheetId: string,
    sheetId: number,
    rowIndex: number,
    columnIndex: number,
    fields: string,
  ): Promise<GapiSheets.CellData> {
    const startColumnIndex = columnIndex;
    const endColumnIndex = columnIndex + 1;
    const startRowIndex = rowIndex;
    const endRowIndex = rowIndex + 1;

    return this.getSheet(
      spreadsheetId,
      { sheetId, startColumnIndex, endColumnIndex, startRowIndex, endRowIndex },
      `sheets.data.rowData.values(${fields})`,
    ).then((sheet) => {
      if (!sheet.data[0].rowData[0] || !sheet.data[0].rowData[0].values)
        return null;

      return sheet.data[0].rowData[0].values[0];
    });
  }

  getSheetRowValues(
    spreadsheetId: string,
    sheetId: number,
    rowIndex: number,
    fields: string,
  ): Promise<GapiSheets.CellData[]> {
    const startRowIndex = rowIndex;
    const endRowIndex = rowIndex + 1;

    return this.getSheet(
      spreadsheetId,
      { sheetId, startRowIndex, endRowIndex },
      `sheets.data.rowData.values(${fields})`,
    ).then((sheet) => sheet.data[0].rowData[0]?.values);
  }

  async getSheet(
    spreadsheetId: string,
    gridRange: GapiSheets.GridRange,
    fields: string,
  ): Promise<GapiSheets.Sheet> {
    const params = {
      spreadsheetId,
      dataFilters: [{ gridRange }],
      fields,
      access_token: await this.authService.getAccessToken(),
    };

    return this.gapiService
      .onSheetsLoaded()
      .then((sheets) => sheets.spreadsheets.getByDataFilter(params))
      .then((response) => response.result.sheets[0]);
  }

  async getSpreadsheet(spreadsheetId: string): Promise<GapiSheets.Spreadsheet> {
    const params = {
      spreadsheetId,
      access_token: await this.authService.getAccessToken(),
    };

    return this.gapiService
      .onSheetsLoaded()
      .then((sheets) => sheets.spreadsheets.get(params))
      .then((response) => response.result);
  }

  async createSpreadsheet(
    spreadsheet: GapiSheets.Spreadsheet,
  ): Promise<GapiSheets.Spreadsheet> {
    const params = {
      access_token: await this.authService.getAccessToken(),
      properties: spreadsheet.properties,
      sheets: spreadsheet.sheets.map((sheet) =>
        Object.assign({}, sheet, { protectedRanges: [] }),
      ),
    };

    const sheets = await this.gapiService.onSheetsLoaded();
    const response = await sheets.spreadsheets.create(params);
    return response.result;
  }

  async sendRequests(
    spreadsheetId: string,
    resource: GapiSheets.BatchUpdateSpreadsheetRequest,
  ): Promise<GapiSheets.BatchUpdateSpreadsheetResponse> {
    const params = {
      spreadsheetId,
      resource,
      access_token: await this.authService.getAccessToken(),
    };

    return this.gapiService
      .onSheetsLoaded()
      .then((sheets) => sheets.spreadsheets.batchUpdate(params))
      .then((response) => response.result)
      .catch((response) => Promise.reject(response.result.error));
  }

  addSheetRequest(
    spreadsheetId: string,
    title: string,
  ): Promise<GapiSheets.AddSheetResponse> {
    return this.sendRequests(spreadsheetId, {
      requests: [{ addSheet: { properties: { title } } }],
    }).then((response) => response.replies[0].addSheet);
  }

  cleanAndUpdateSheetRequest(
    spreadsheetId: string,
    sheetId: number,
    sheet: GapiSheets.Sheet,
  ): Promise<GapiSheets.BatchUpdateSpreadsheetResponse> {
    return this.sendRequests(spreadsheetId, {
      requests: [
        {
          deleteRange: {
            range: { sheetId },
            shiftDimension: GapiSheets.Dimension.columns,
          },
        },
        {
          updateCells: {
            range: { sheetId },
            fields: '*',
            rows: sheet.data[0].rowData,
          },
        },
      ],
    });
  }

  insertDimensionsRequest(
    spreadsheetId: string,
    sheetId: number,
    index: number,
    dimension: GapiSheets.Dimension,
    count = 1,
  ): Promise<GapiSheets.BatchUpdateSpreadsheetResponse> {
    const startIndex = index;
    const endIndex = index + 1;

    return this.sendRequests(spreadsheetId, {
      requests: [
        {
          insertDimension: {
            range: { sheetId, startIndex, endIndex, dimension },
            inheritFromBefore: false,
          },
        },
      ],
    });
  }

  insertColumnsRequest(
    spreadsheetId: string,
    sheetId: number,
    index: number,
    count = 1,
  ): Promise<GapiSheets.BatchUpdateSpreadsheetResponse> {
    return this.insertDimensionsRequest(
      spreadsheetId,
      sheetId,
      index,
      GapiSheets.Dimension.columns,
      count,
    );
  }

  insertRowsRequest(
    spreadsheetId: string,
    sheetId: number,
    index: number,
    count = 1,
  ): Promise<GapiSheets.BatchUpdateSpreadsheetResponse> {
    return this.insertDimensionsRequest(
      spreadsheetId,
      sheetId,
      index,
      GapiSheets.Dimension.rows,
      count,
    );
  }

  updateSheetByMappedColumnRequest(
    spreadsheetId: string,
    sheetId: number,
    columnIndex: number,
    mappedColumnIndex: number,
    sheet: GapiSheets.Sheet,
    fields = '*',
  ): Promise<GapiSheets.BatchUpdateSpreadsheetResponse> {
    const startColumnIndex = columnIndex;
    const endColumnIndex = columnIndex + 1;
    const startMappedColumnIndex = mappedColumnIndex;
    const endMappedColumnIndex = mappedColumnIndex + 1;

    const trimmedRowData = this.getRowsWithSlicedColumns(
      sheet,
      startColumnIndex,
      endColumnIndex,
    );

    return this.sendRequests(spreadsheetId, {
      requests: [
        {
          updateCells: {
            range: {
              sheetId,
              startColumnIndex: startMappedColumnIndex,
              endColumnIndex: endMappedColumnIndex,
            },
            fields,
            rows: trimmedRowData,
          },
        },
      ],
    });
  }

  getRowsWithSlicedColumns(
    sheet: GapiSheets.Sheet,
    startColumnIndex: number,
    endColumnIndex: number,
  ): GapiSheets.RowData[] {
    return sheet.data[0].rowData.map((row) => ({
      values: row.values.slice(startColumnIndex, endColumnIndex),
    }));
  }

  createOrUpdateSheetProtectionsRequest(
    spreadsheetId: string,
    sheetId: number,
    sheet: GapiSheets.Sheet,
    ignore = false,
  ): Promise<GapiSheets.BatchUpdateSpreadsheetResponse> {
    const localProtectedRanges = sheet.protectedRanges || [];
    const remoteSheet$: Promise<GapiSheets.Sheet> = ignore
      ? Promise.resolve({} as any)
      : this.getSheet(spreadsheetId, { sheetId }, 'sheets.protectedRanges');

    const hasSameDescription = (a, b) => a.description === b.description;
    const hasSameColumnIndex = (a, b) =>
      a.range.startColumnIndex === b.range.startColumnIndex;

    return remoteSheet$.then(({ protectedRanges = [] }) => {
      const requests = [];

      // Remove existing protected ranges that changed position in the local sheet
      protectedRanges
        .filter((pr) =>
          localProtectedRanges.some(
            (lpr) =>
              hasSameDescription(pr, lpr) && !hasSameColumnIndex(pr, lpr),
          ),
        )
        .forEach(({ protectedRangeId }) => {
          requests.push({ deleteProtectedRange: { protectedRangeId } });
        });

      // Add protected ranges that don't exist in remote or have changed in remote
      localProtectedRanges
        .filter(
          (lpr) =>
            !protectedRanges.some(
              (pr) =>
                hasSameDescription(pr, lpr) && hasSameColumnIndex(pr, lpr),
            ),
        )
        .forEach((protectedRange) => {
          protectedRange.range.sheetId = sheetId;
          requests.push({ addProtectedRange: { protectedRange } });
        });

      if (requests.length) {
        return this.sendRequests(spreadsheetId, { requests });
      }
    });
  }

  async searchSheets(search: string): Promise<DriveFile[]> {
    const params = {
      q: `name contains '${search.replace(
        `'`,
        `\\'`,
      )}' and (mimeType = 'application/vnd.google-apps.spreadsheet')`,
      orderBy: 'name,modifiedTime',
      fields: 'files(id,name,modifiedTime)',
      includeTeamDriveItems: true,
      supportsTeamDrives: true,
      access_token: await this.authService.getAccessToken(),
    };

    return this.gapiService
      .onDriveLoaded()
      .then((drive) => drive.files.list(params))
      .then((response) =>
        response.result.files.map((file) =>
          Object.assign(file, {
            modifiedTime: moment(file.modifiedTime).format('YYYY/MM/DD hh:mm'),
          }),
        ),
      );
  }
}
