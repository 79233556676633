import {
  ProjectLocalConfig,
  getProjectLocalConfig,
} from './project-local-config';
import {
  ProjectRemoteConfig,
  parseProjectRemoteConfig,
} from './project-remote-config';

export class ProjectConfig implements ProjectLocalConfig, ProjectRemoteConfig {
  disabled: boolean;
  studio: string;
  madId: number;
  isGit: boolean;

  static create(): ProjectConfig {
    return new this();
  }

  withConfig(config: Partial<ProjectConfig>): this {
    return Object.assign(this, config);
  }

  withLocalProject(projectId: string): this {
    return this.withConfig(getProjectLocalConfig(projectId));
  }

  withRemoteJson(remoteJson: string): this {
    return this.withConfig(parseProjectRemoteConfig(remoteJson));
  }
}
