<mat-form-field class="sp-dropdown-autocomplete">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    [disabled]="disabled"
    [matAutocomplete]="auto"
    type="text"
    (ngModelChange)="update($event)"
    [ngModel]="input"
    (blur)="updateOnBlur($event)"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <ng-container *ngIf="customOptionFn">
      <mat-option
        [value]="input"
        (onSelectionChange)="setSelection($event, input)"
        >{{ customOptionFn(input) }}</mat-option
      >
      <mat-divider></mat-divider>
    </ng-container>
    <mat-option
      *ngFor="let option of filteredOptions | async | slice: 0:500"
      [value]="option"
      (onSelectionChange)="setSelection($event, option)"
    >
      {{ displayFn(option) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
