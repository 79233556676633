import { Component, Input } from '@angular/core';

@Component({
  selector: 'sp-content-toolbar',
  templateUrl: './content-toolbar.component.html',
  styleUrls: ['./content-toolbar.component.scss'],
})
export class ContentToolbarComponent {
  @Input('loader') loader = false;
}
