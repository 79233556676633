import { MatTableDataSource } from '@angular/material/table';
import { ABService } from '@app/lomt/ab/manager/ab-manager.service';
import { STATUS_TEXT } from '@app/shared/data-tables/data-tables.model';
import { Chronos } from '@app/shared/utils';

interface SchedulesData {
  id: string;
  status: string;
  created_by: string;
}

interface AbtestsData {
  id: string;
  name: string;
  status: string;
  created_by: string;
}

const createSchedulesData = (dataSource: any[]): SchedulesData[] =>
  dataSource
    .filter((data) => data?.is_live)
    .map(({ id, created_by }) => ({
      id,
      status: STATUS_TEXT.live.toLowerCase(),
      created_by,
    }));
const createAbtestsData = (dataSource: any[]): AbtestsData[] =>
  dataSource
    .filter(
      (data) =>
        ABService.getStatus(
          Chronos.createFromServer(data.start),
          Chronos.createFromServer(data.end),
        ) === STATUS_TEXT.live.toLowerCase(),
    )
    .map(({ id, name, created_by }) => ({
      id,
      name,
      status: STATUS_TEXT.live.toLowerCase(),
      created_by,
    }));
const scheduleLinkFn = (id: string, project: string): string[] => [
  'projects',
  project,
  'tools',
  'lomt',
  'schedule',
  id,
];
const abtestLinkFn = (id: string, project: string): string[] => [
  'projects',
  project,
  'tools',
  'lomt',
  'ab',
  id,
];

interface Dependencies {
  name: string;
  createFn: Function;
  columns: string[];
  link: Function | null;
}
export const dependenciesMap: Map<string, Dependencies> = new Map([
  [
    'Schedules',
    {
      name: 'Schedules',
      createFn: createSchedulesData,
      columns: ['id', 'status', 'created_by'],
      link: scheduleLinkFn,
    },
  ],
  [
    'ABTests',
    {
      name: 'A/B Tests',
      createFn: createAbtestsData,
      columns: ['id', 'status', 'created_by'],
      link: abtestLinkFn,
    },
  ],
]);

export class DependenciesDataSource extends MatTableDataSource<any> {
  constructor(data: any[]) {
    super();
    this.data = data;
  }
}

export enum Actions {
  save = 'save',
  delete = 'delete',
}

export enum Types {
  content = 'content',
  schedule = 'schedule',
}

export const actionHeaderText = new Map([
  [
    Actions.save,
    'Any modifications will affect all these Schedules or A/B Tests in the list are LIVE. Be careful!',
  ],
  [
    Actions.delete,
    'Any remove will affect all these Schedules or A/B Tests in the list are LIVE. Be careful!',
  ],
]);
