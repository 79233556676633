import { Injectable } from '@angular/core';
import * as Moment from 'moment';

@Injectable()
export class PushNotificationHelperService {
  static dateToStringDate(date: Date): string {
    return Moment(date).format('YYYY/MM/DD');
  }

  static stringDateToDate(date: string): Date {
    return Moment(date, 'YYYY/MM/DD').toDate();
  }

  static stringDateAndTimeToDate(date: string, time: string): Date {
    const [hours, minutes] = time.split(':');
    return Moment(date, 'YYYY/MM/DD')
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .toDate();
  }

  static getDateEpoch(): Date {
    return Moment.unix(0).utc().toDate();
  }

  static getTodayDate(): Date {
    return Moment().toDate();
  }

  static getTomorrowDate(): Date {
    return Moment().add(1, 'days').toDate();
  }

  constructor() {}
}
