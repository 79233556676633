import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DatePipe,
  TimeFromNowPipe,
  TimeToDatePipe,
  FormatDurationPipe,
} from './data-tables-dates.pipe';
import { EmailPipe } from './data-tables-email.pipe';
import { StatusIconPipe, StatusLabelPipe } from './data-tables-status.pipe';
import { NamePipe } from './data-tables-name.pipe';
import { ContentNamePipe } from './data-tables-content.pipe';
import { DataTablesHelper } from './data-tables.helper';

@NgModule({
  imports: [CommonModule],
  declarations: [
    NamePipe,
    DatePipe,
    FormatDurationPipe,
    TimeFromNowPipe,
    TimeToDatePipe,
    EmailPipe,
    StatusIconPipe,
    StatusLabelPipe,
    ContentNamePipe,
  ],
  exports: [
    NamePipe,
    DatePipe,
    FormatDurationPipe,
    TimeFromNowPipe,
    TimeToDatePipe,
    EmailPipe,
    StatusIconPipe,
    StatusLabelPipe,
    ContentNamePipe,
  ],
  providers: [DataTablesHelper],
})
export class DataTablesModule {}
