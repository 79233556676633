import { NgModule } from '@angular/core';
import { MaterialDesignModule } from '../md/md.module';
import { CommonModule } from '@angular/common';

import { NotificationMessageComponent } from './notification-message.component';
import { NotificationMessageService } from './notification-message.service';

@NgModule({
  imports: [CommonModule, MaterialDesignModule],
  declarations: [NotificationMessageComponent],
  exports: [NotificationMessageComponent],
  providers: [NotificationMessageService],
})
export class NotificationMessageModule {}
