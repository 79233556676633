import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  forwardRef,
  ViewChild,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const SELECT_SEARCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectSearchComponent),
  multi: true,
};

@Component({
  selector: 'sp-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  providers: [SELECT_SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class SelectSearchComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() placeholder: string;
  @Input() valueKey: string;
  @Input() options: any[];
  @Input() multiple: boolean;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() disableOptionCentering: boolean;
  @Input() error: string;
  @Output() change = new EventEmitter<any>();
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('selectElement', { static: true })
  private _selectElement: MatSelect;

  itemFilterCtrl: UntypedFormControl = new UntypedFormControl();
  filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private _maxRows = 300;
  private _onDestroy = new Subject<void>();
  private _selected: any;
  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  constructor(private _renderer: Renderer2) {}

  @Input()
  get selected() {
    return this._selected;
  }

  set selected(value) {
    if (
      this.options &&
      this.options.some((op) => this.getValueFromOption(op) === value)
    ) {
      this.selectedChange.emit(value);
      this._selected = value;
    }
  }

  get selectElement(): MatSelect {
    return this._selectElement;
  }

  ngOnInit() {
    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.filterItems());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filterItems();
    }

    if (changes.selected) {
      this.writeValue(changes.selected.currentValue);
    }

    if (changes.disabled) {
      this.setDisabledState(changes.disabled.currentValue);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  writeValue(obj: any): void {
    this._selected = obj;
    this.filterItems();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._renderer.setProperty(this._selectElement, 'disabled', isDisabled);
  }

  getValueFromOption(op: any) {
    if (!this.valueKey) return op;
    return op[this.valueKey];
  }

  getOptionFromValue(value: any) {
    if (!this.valueKey) return value;
    return this.options.find((op) => op[this.valueKey] === value);
  }

  getOptionDisplay(op: any) {
    return op.name || op;
  }

  onChange({ value }: { value: any }): void {
    this._onChange(value);
    this.change.emit(value);
  }

  onBlur(): void {
    this._onTouched();
  }

  private filterItems(): void {
    if (!this.options) {
      this.filteredItems.next([]);
      return;
    }

    let search = this.itemFilterCtrl.value;
    if (!search) {
      const selectedOption = this.getOptionFromValue(this.selected);
      if (
        selectedOption != null &&
        this.options.indexOf(selectedOption) >= this._maxRows
      ) {
        this.filteredItems.next(
          this.options.slice(0, this._maxRows - 1).concat(selectedOption),
        );
      } else {
        this.filteredItems.next(this.options.slice(0, this._maxRows));
      }
      return;
    }

    search = search.toLowerCase();
    this.filteredItems.next(
      this.options
        .filter(
          (item) =>
            this.getOptionDisplay(item).toLowerCase().indexOf(search) > -1,
        )
        .sort(
          (a, b) =>
            this.getOptionDisplay(a).length - this.getOptionDisplay(b).length,
        )
        .slice(0, this._maxRows),
    );
  }
}
