import { Component } from '@angular/core';

import { AuthService } from '@app/providers/auth/auth.service';
import { NavigationService } from '@app/shared/navigation/navigation.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loading = true;
  isAuthenticated: boolean;
  authErrors: string[];
  autoLogin = false;

  private readonly lastLoginKey = 'lastLoginTS';
  private readonly autoLoginOffset = 5 * 60 * 1000; // 5 min

  constructor(
    private auth: AuthService,
    private navigation: NavigationService,
  ) {
    this.auth.isAuthenticated().then((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
      this.loading = false;

      if (isAuthenticated) {
        this.navigation.restoreDesiredUserNavigation();
      } else if (this.auth.hasAuthErrors()) {
        this.authErrors = this.auth.getAuthErrors();
      } else if ((this.autoLogin = this.canAutoLogin())) {
        this.login();
      }
    });
  }

  login() {
    this.setLastLogin();
    this.auth.login();
  }

  private canAutoLogin(): boolean {
    const lastLogin = this.getLastLogin();
    const canAutoLogin =
      !lastLogin || Date.now() - lastLogin > this.autoLoginOffset;
    if (!canAutoLogin) this.removeLastLogin();

    return canAutoLogin;
  }

  private getLastLogin(): number {
    const lastAutoLogin = sessionStorage.getItem(this.lastLoginKey);
    return lastAutoLogin != null ? parseInt(lastAutoLogin, 10) : null;
  }

  private setLastLogin() {
    sessionStorage.setItem(this.lastLoginKey, `${Date.now()}`);
  }

  private removeLastLogin() {
    sessionStorage.removeItem(this.lastLoginKey);
  }
}
