import { Injectable } from '@angular/core';

import {
  ValidateIntegerFunction,
  ValidateNumberFunction,
  ValidateJsonFunction,
  ValidateStringFunction,
  ValidateUlidFunction,
  ValidateUniqueFunction,
} from './function/index';
import { UlidEditor } from './editor/index';
import { FormatByColumnValidationDecorator } from './decorator/index';

@Injectable()
export class KendoSetupService {
  /**
   * Register all the editors inside kendo lib
   */
  private static registerEditors() {
    UlidEditor.register();
  }

  /**
   * Register all the validators inside kendo lib
   */
  private static registerValidators() {
    ValidateUniqueFunction.register();
    ValidateJsonFunction.register();
    ValidateIntegerFunction.register();
    ValidateNumberFunction.register();
    ValidateStringFunction.register();
    ValidateUlidFunction.register();
  }

  static registerFormatByColumnValidationDecorator(
    getColumnValidation: (col: number) => string,
  ) {
    FormatByColumnValidationDecorator.unregister();
    FormatByColumnValidationDecorator.register(getColumnValidation);
  }

  constructor() {
    KendoSetupService.registerEditors();
    KendoSetupService.registerValidators();
  }
}
