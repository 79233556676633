import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';

import {
  NotificationMessage,
  notificationText,
  notificationIcon,
} from './notification-message.model';

@Component({
  selector: 'sp-notification-message',
  templateUrl: 'notification-message.component.html',
  styleUrls: ['notification-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationMessageComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SimpleSnackBar>,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationMessage,
  ) {
    if (!data.text) {
      data.text = notificationText[this.data.type];
    }
    if (data.icon) {
      data.icon = notificationIcon[data.icon];
    }
  }
}
