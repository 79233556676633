import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SharedModule } from '@app/shared/shared';
import { ChartsModule } from '@app/shared/charts/charts.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BetaComponent } from './beta/beta.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  imports: [
    ChartsModule,
    NgxDatatableModule,
    // Jarvis
    SharedModule,
    AgGridModule,
  ],
  declarations: [DashboardComponent, BetaComponent],
})
export class HomeModule {}
