export const STATUS_ICON = {
  live: 'cloud_done',
  future: 'cloud_done',
  expired: 'cloud_off',
  active: 'check',
  error: 'error',
};

export const STATUS_TEXT = {
  live: 'Live',
  future: 'Future',
  expired: 'Expired',
  active: 'Active',
  terminating: 'Terminating',
  error: 'Error',
};

export const STATUS_CODE = {
  live: 2,
  future: 1,
  expired: 0,
};
