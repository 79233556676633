<div class="notification-container">
  <div>
    <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
    <span
      class="notification-html"
      *ngIf="data.html; else onlyText"
      [innerHTML]="data.html"
    ></span>
    <ng-template #onlyText>
      <span class="notification-text">{{ data.text }}</span>
    </ng-template>
  </div>
  <button
    mat-icon-button
    (click)="snackBarRef.dismissWithAction()"
    *ngIf="!data.button"
  >
    <mat-icon aria-label="Close">clear</mat-icon>
  </button>
</div>
