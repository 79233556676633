import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { ProjectService } from '@app/providers/projects';
import { Api } from '@app/providers/api/api';
import { ContentModelView } from '@app/lomt/contents/contents.model';
import { ABService } from '@app/lomt/ab/manager/ab-manager.service';
import { Chronos } from '@app/shared/utils';
import { DataModel as ABTest } from '@app/lomt/ab/manager/ab-manager.model';
import { PushNotificationApiService } from '@app/push-notification/push-notification-api.service';
import { Notification } from '@app/push-notification/push-notification.model';
import { PaymentValidationApiService } from '@app/payment-validation/payment-validation-api.service';
import { DynamicEnvironmentService } from '@app/dynamic-environment/shared/dynamic-environment-api.service';
import { Pod } from '@app/dynamic-environment/shared/dynamic-environment-api.model';
import { TimeAggregate, timeUnit } from '@app/home/dashboard/dashboard.model';

@Injectable()
export class DashboardService {
  constructor(
    private projectService: ProjectService,
    private api: Api,
    private apiNotification: PushNotificationApiService,
    private apiPayment: PaymentValidationApiService,
    private apiDynamicEnv: DynamicEnvironmentService,
  ) {}

  fetchActiveContents(
    storeData?: (data: ContentModelView[]) => void,
  ): Observable<number> {
    return this.api
      .rpc({
        repository: 'contents',
        handler: 'all',
      })
      .pipe(
        tap((data: ContentModelView[]) => storeData && storeData(data)),
        map((data: ContentModelView[]) =>
          data.filter((content: ContentModelView) => content.is_active),
        ),
        map((data: ContentModelView[]) => data.length),
      );
  }

  fetchActiveABTests(storeData?: (data: ABTest[]) => void): Observable<number> {
    return this.api
      .rpc({
        repository: 'abtests',
        handler: 'all',
      })
      .pipe(
        tap((data: ABTest[]) => storeData && storeData(data)),
        map((data: ABTest[]) =>
          data.filter(
            (content: ABTest) =>
              ABService.getStatus(
                Chronos.createFromServer(content.start),
                Chronos.createFromServer(content.end),
              ) === 'live',
          ),
        ),
        map((data: ABTest[]) => data.length),
      );
  }

  fetchActiveNotifications(
    storeData?: (data: Notification[]) => void,
  ): Observable<number> {
    return this.apiNotification.getNotifications().pipe(
      map((data: Notification[]) =>
        data.filter(
          (notification: Notification) =>
            notification.campaign_id !== 'sp-integration-test' &&
            !notification.aborted,
        ),
      ),
      tap((data: Notification[]) => storeData && storeData(data)),
      map((data: Notification[]) =>
        data.filter(
          (notification: Notification) => notification.status === 'running',
        ),
      ),
      map((data: Notification[]) => data.length),
    );
  }

  fetchSize(storeData?: (data: any) => void): Observable<number> {
    const from = moment().subtract(12, 'months').unix();
    const to = moment().unix();

    const extractSerie = (serie: any): [number, number][] => {
      if (!serie || !('pointlist' in serie)) return [];

      return serie.pointlist.map(([date, value]) => [
        date,
        parseFloat((value / 1024).toFixed(2)),
      ]);
    };

    return this.apiPayment
      .getSize({ from, to, owner: this.projectService.getProject()['alias'] })
      .pipe(
        map(({ series }) => ({
          avg: extractSerie(series[0]),
          percentile95: extractSerie(series[1]),
          max: extractSerie(series[2]),
        })),
        tap(
          (data: { [key: string]: [number, number][] }) =>
            storeData && storeData(data),
        ),
        map((data: { [key: string]: [number, number][] }) =>
          data.avg.length ? data.avg[data.avg.length - 1][1] : null,
        ),
      );
  }

  fetchActivePayments(storeData?: (data: any) => void): Observable<number> {
    const from = moment().subtract(12, 'months').unix();
    const to = moment().unix();

    return this.apiPayment
      .getRefunds({
        from,
        to,
        project: this.projectService.getProject()['short_name'],
      })
      .pipe(
        map(({ series }) =>
          series.length && 'pointlist' in series[0] ? series[0].pointlist : [],
        ),
        tap((data: [number, number][]) => storeData && storeData(data)),
        map((data: [number, number][]) =>
          data.length ? data[data.length - 1][1] : null,
        ),
      );
  }

  fetchActiveDynamicEnvironments(
    storeData?: (data: Pod[]) => void,
  ): Observable<number> {
    return this.apiDynamicEnv.getEnvironments().pipe(
      tap((data: Pod[]) => storeData && storeData(data)),
      map((data: Pod[]) =>
        data.filter(({ status }: { status: string }) => status === 'Active'),
      ),
      map((data: Pod[]) => data.length),
    );
  }

  getGraphOptions(aggregate: string): any {
    return {
      responsive: true,
      tooltips: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          type: 'time',
          distribution: 'linear',
          time: {
            unit: timeUnit[aggregate],
          },
          ticks: {
            source: 'auto',
          },
        },
        y: {
          ticks: {
            min: 0,
          },
          scaleLabel: {
            display: true,
          },
          stacked: true,
        },
      },
    };
  }
}
