import { Injectable } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';

// @dynamic
@Injectable()
export class DataTablesHelper {
  static options = {
    columnMode: ColumnMode.force,
    headerHeight: 35,
    footerHeight: 50,
    rowHeight: 50,
    limit: 10,
    scrollbarV: true,
    scrollbarH: true,
    sorts: [{ prop: 'id', dir: 'desc' }],
  };

  static booleanComparator(propA: any, propB: any): number {
    if (propA === propB) return 0;
    return propA ? -1 : 1;
  }

  static scoreComparator(
    scoreFn: (prop: any) => number,
    propA: any,
    propB: any,
  ): number {
    return scoreFn(propB) - scoreFn(propA);
  }

  /**
   * Case study:
   * (pA/pB) | live | future | expired |
   * --------|------|--------|---------|
   * live    |  0   |   -1   |   -2    |
   * future  |  1   |    0   |   -1    |
   * expired |  2   |    1   |    0    |
   */
  static scoreComparatorWrapper(
    scoreFn: (prop: any) => number,
  ): (propA: any, propB: any) => number {
    return DataTablesHelper.scoreComparator.bind(this, scoreFn);
  }
}
