import ClientConfig = gapi.auth2.ClientConfig;

export const CLIENT_ID =
  '124858358517-4v6728k7su08lgcj06sc291dqa7f2bk5.apps.googleusercontent.com';
export const SCOPE = 'https://www.googleapis.com/auth/';

export interface NgGapiClientConfig extends ClientConfig {
  discoveryDocs: string[];
}

export class GoogleApiConfig {
  protected clientConfig: NgGapiClientConfig;

  constructor(clientConfig: NgGapiClientConfig) {
    this.clientConfig = clientConfig;
  }

  public getClientConfig(): NgGapiClientConfig {
    return this.clientConfig;
  }
}

export const jarvisConfig: NgGapiClientConfig = {
  client_id: CLIENT_ID,
  discoveryDocs: [],
  scope: [
    'openid',
    `${SCOPE}userinfo.email`,
    `${SCOPE}userinfo.profile`,
    `${SCOPE}drive.readonly`,
    `${SCOPE}spreadsheets`,
  ].join(' '),
};
