import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { AUTH_INTERCEPTOR_SETTINGS } from './auth.model';
import { DomainChecker, DomainCheckerSettings } from '@app/shared/utils/regexp';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private domainChecker: DomainChecker;

  constructor(
    @Inject(AUTH_INTERCEPTOR_SETTINGS) settings: DomainCheckerSettings,
    private auth: AuthService,
  ) {
    this.domainChecker = new DomainChecker(settings);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.domainChecker.isForbidden(request.url)) {
      return next.handle(request);
    }

    return from(this.auth.getIdToken()).pipe(
      switchMap((idToken) =>
        next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${idToken}`,
            },
          }),
        ),
      ),
    );
  }
}
