import { mainTools, tools } from '@app/shared/navigation/navigation.model';

type UsersVisibility = string[];
type ProjectsVisibility = string[];

export interface ToolVisibility {
  projects?: ProjectsVisibility;
  users?: UsersVisibility;
}

export interface MainToolVisibility extends ToolVisibility {
  tools?: { [key: string]: ToolVisibility };
}

export interface VisibilityConfig {
  [key: string]: MainToolVisibility;
}

function getToolTitle(key: string): string {
  const [mainTool, tool] = key.split('.');

  if (!tool) return mainTools.get(mainTool).title;
  return tools.get(mainTool).get(tool).title;
}

export function isVisibleInConfig(
  config: ToolVisibility,
  project: string,
  user: string,
): boolean {
  if (!config) return true;
  if (!config.projects && !config.users) return true;

  if (Array.isArray(config.projects) && config.projects.includes(project))
    return true;
  if (Array.isArray(config.users) && config.users.includes(user)) return true;

  return false;
}

export const noDynamic = ['demo'];
export const earlyAdopters = [
  'julio.calle',
  'manuel.jurado',
  'eric.pinto',
  'jordi.forns',
  'manuel.peralta',
];

export const mainToolsConfig: VisibilityConfig = {
  [getToolTitle('lomt')]: {
    tools: {
      [getToolTitle('lomt.environment')]: {
        projects: noDynamic,
      },
    },
  },
};
