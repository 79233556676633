import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import { DateFormats } from '@app/lomt/shared/utils';

const moment = extendMoment(Moment);

export class Chronos {
  /**
   * Creates a new instance from the current datetime
   */
  static create(): Chronos {
    return new Chronos(moment().startOf('minutes'));
  }

  /**
   * Create a new instance from a server datetime
   */
  static createFromServer(timestamp: number): Chronos {
    return new Chronos(moment.unix(timestamp));
  }

  /**
   * Create a new instance from a datepicker datetime
   */
  static createFromDatepicker(date: Date): Chronos {
    return date
      ? new Chronos(moment(date).startOf('minutes'))
      : new Chronos(null);
  }

  /**
   * Get the current timezone.
   */
  static getZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Get the date range
   */
  static range(start: Date, end: Date): Date[] {
    const range = moment.range(
      moment(start).startOf('minutes'),
      moment(end).startOf('minutes'),
    );
    const days = Array.from(range.by('days'));

    return days.map((day: Moment.Moment) => day.toDate());
  }

  /**
   * Check if the date falls within a date range
   */
  static within(start: Date, end: Date, current: Date): boolean {
    const range = moment.range(
      moment(start).startOf('minutes'),
      moment(end).startOf('minutes'),
    );
    const when = moment(current).startOf('minutes');

    return when.within(range);
  }

  /**
   * Compare if instance isSameOrBefore another instance datetime
   */
  static isSame(dateA: Date, dateB: Date): boolean {
    return moment(dateA).isSame(moment(dateB));
  }

  constructor(public value?: Moment.Moment) {}

  /**
   * Format datetime for server usage
   */
  forServer(): number {
    if (this.value == null) return null;
    return this.value.unix();
  }

  /**
   * Format datetime for datepicker usage
   */
  forDatepicker(): Date {
    if (this.value == null) return null;
    return this.value.toDate();
  }

  /**
   * Format datetime for table usage
   */
  forTable(): string {
    if (this.value == null) return null;
    return this.value.format(DateFormats.FORMAT_LONG);
  }

  // Pass-through methods
  /**
   * Subtract time from the current datetime
   */
  subtract(
    amount: Moment.DurationInputArg1,
    unit: Moment.DurationInputArg2,
  ): Chronos {
    return new Chronos(moment(this.value).subtract(amount, unit));
  }

  /**
   * Add time from the current datetime
   */
  add(
    amount: Moment.DurationInputArg1,
    unit: Moment.DurationInputArg2,
  ): Chronos {
    return new Chronos(moment(this.value).add(amount, unit));
  }

  /**
   * Compare if instance isAfter another instance datetime
   */
  isAfter(inp: Chronos): boolean {
    if (this.value == null || inp.value == null) return null;
    return this.value.isAfter(inp.value);
  }

  /**
   * Compare if instance isBefore another instance datetime
   */
  isBefore(inp: Chronos): boolean {
    if (this.value == null || inp.value == null) return null;
    return this.value.isBefore(inp.value);
  }

  /**
   * Compare if instance isSameOrBefore another instance datetime
   */
  isSameOrBefore(inp: Chronos): boolean {
    if (this.value == null || inp.value == null) return null;
    return this.value.isSameOrBefore(inp.value);
  }

  /**
   * Compare if instance isSameOrAfter another instance datetime
   */
  isSameOrAfter(inp: Chronos): boolean {
    if (this.value == null || inp.value == null) return null;
    return this.value.isSameOrAfter(inp.value);
  }
}
