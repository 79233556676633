import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialDesignModule } from '@app/shared/md/md.module';
import { ContentToolbarComponent } from './content-toolbar/content-toolbar.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';
import { CardComponent } from './card/card.component';

@NgModule({
  imports: [MaterialDesignModule, CommonModule],
  exports: [ContentToolbarComponent, ContentLayoutComponent, CardComponent],
  declarations: [
    ContentToolbarComponent,
    ContentLayoutComponent,
    CardComponent,
  ],
})
export class LayoutModule {}
