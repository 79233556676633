import { Injectable } from '@angular/core';

import { Environment } from './environment.model';

enum ENVIRONMENT {
  PRO = 'pro',
  PRE = 'pre',
  INT = 'int',
}
const ENVS_ORDER: Array<string> = [
  ENVIRONMENT.INT,
  ENVIRONMENT.PRE,
  ENVIRONMENT.PRO,
];

@Injectable()
export class LomtEnvironmentService {
  static sort(environments: Array<Environment>): Array<Environment> {
    const list = Array.isArray(environments) ? environments : [];
    return list.sort(
      (_env1: Environment, env2: Environment) =>
        ENVS_ORDER.indexOf(env2.name) - ENVS_ORDER.indexOf(_env1.name),
    );
  }

  static getProdID(environments: Array<Environment>): number {
    const total = environments.length;
    for (let i = 0; i < total; i++) {
      if (environments[i].name === ENVIRONMENT.PRO) {
        return environments[i].id;
      }
    }

    return 0;
  }

  static hasProdByName(envs: string[]): boolean {
    return envs.includes(ENVIRONMENT.PRO);
  }

  static hasProd(
    environments: Environment[],
    current: Array<number> = [],
  ): boolean {
    const ENV = LomtEnvironmentService.getProdID(environments);
    return LomtEnvironmentService.hasId(current, ENV);
  }

  static hasId(current: Array<number> = [], environmentId: number): boolean {
    return (
      typeof current.find((id: number) => id === environmentId) !== 'undefined'
    );
  }
}
