import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { ReplaySubject, Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Project, ProjectService } from '@app/providers/projects';
import { AuthService } from '@app/providers/auth/auth.service';
import {
  Branch,
  Config,
  Section,
  Environment,
  Report,
  SectionLink,
  ConfigLinks,
  OptimisticLocking,
  GitDiffResponse,
} from '@app/config-manager/shared/api.model';
import { NotificationMessageService } from '@app/shared/notification-message/notification-message.service';
import { ConfigLegacy } from '@app/config-manager/editor/editor.model';
import { environment as environmentConfig } from '@env/environment';
import { Author } from '@app/shared/author/author';
import { BetaComponent } from '@app/home/beta/beta.component';
import { Beta } from '@app/home/beta/beta.model';

@Injectable()
export class ConfigManagerService {
  private project: Project;

  private readonly coolGames = ['ml', 'dc', 'mlgit'];
  private readonly origin = environmentConfig.configManagerApi;
  private readonly fastOrigin = environmentConfig.configManagerFastApi;
  private readonly baseUI = 'http://configmanager.bs.laicosp.net';

  protected sectionOL = new OptimisticLocking(
    ({ branch, config, section }) => `${branch}/${config}/${section}`,
  );

  isGitProject: boolean;
  isGitProject$: ReplaySubject<boolean> = new ReplaySubject(1);

  static CreateUser(http: HttpClient, email: string): Observable<void> {
    return http.post<void>(`${environmentConfig.configManagerApi}/users`, {
      email,
    });
  }

  static IsGitConfigFile(file: string): boolean {
    return file.endsWith('.json') && !file.startsWith('.github/');
  }

  constructor(
    private http: HttpClient,
    private service: ProjectService,
    private message: NotificationMessageService,
    private authService: AuthService,
  ) {
    this.service.project$.subscribe((project: Project) => {
      this.project = project;
      this.sectionOL.reset();

      const isGitProject = project.config.isGit;
      if (this.isGitProject !== isGitProject) {
        this.isGitProject$.next((this.isGitProject = isGitProject));
      }
    });
  }

  private handleError<T>(_operation = 'operation', result?: T) {
    return ({ error }: { error: any }): Observable<T> => {
      console.error(error);
      this.message.open({
        type: 'error',
        html: (error && error.message) || 'Error',
      });
      return of(result as T);
    };
  }

  getPath(canUseFastApi: boolean = false): string {
    if (canUseFastApi && this.useFastApi) {
      return `${this.fastOrigin}/products/${this.project.short_name_cm}`;
    }
    return `${this.origin}/products/${this.project.short_name_cm}`;
  }

  getUIPath(): string {
    return `${this.baseUI}/${this.project.short_name_cm}`;
  }

  getBranch(branch: string): Observable<{} | Branch> {
    return this.http
      .get<Branch>(`${this.getPath(true)}/branches/${branch}`)
      .pipe(
        map((branchEntity: any): Branch => Branch.create(branchEntity)),
        catchError(this.handleError('getBranch')),
      );
  }

  getBranchesFields(...fields: string[]): Observable<Branch[]> {
    return this.http
      .get<Branch[]>(
        `${this.getPath(true)}/branches?fields=${fields.join(
          ',',
        )}&deleted=0&hidden=0`,
      )
      .pipe(
        map((branches) =>
          branches.map((branch) =>
            Object.assign(branch, { owner: Author.clean(branch.owner) }),
          ),
        ),
        catchError(this.handleError('getBranchesFields', [])),
      );
  }

  addBranch({
    name,
    description,
  }: {
    name: string;
    description: string;
  }): Observable<{}> {
    return this.http
      .post<{}>(`${this.getPath()}/branches`, { name, description })
      .pipe(catchError(this.handleError('addBranch')));
  }

  forkBranch({
    source,
    name,
    links,
    description,
    project = this.project.short_name_cm,
  }: {
    source: string;
    name: string;
    links: boolean;
    description: string;
    project?: string;
  }): Observable<{}> {
    return this.http.post<{}>(`${this.getPath()}/branches`, {
      source,
      name,
      links,
      description,
      project,
    });
  }

  deleteBranch({ branch }: { branch: string }): Observable<{}> {
    return this.http.delete(`${this.getPath()}/branches/${branch}`);
  }

  editBranch({
    branch,
    description,
    name,
  }: {
    branch: string;
    description: string;
    name: string;
  }): Observable<{}> {
    return this.http.patch(`${this.getPath()}/branches/${branch}`, {
      description,
      name,
      branch,
      project: this.project.short_name_cm,
    });
  }

  protectBranch(branch: string, protect = true): Observable<{}> {
    const action = protect ? 'protect' : 'unprotect';

    return this.http.patch<Branch>(
      `${this.getPath()}/branches/${branch}/${action}`,
      {},
    );
  }

  addConfig({
    branch,
    name,
    description,
    data,
    metadata,
  }: {
    branch: string;
    name: string;
    description: string;
    data: any;
    metadata: any;
  }): Observable<Config[]> {
    return this.http
      .post<Config[]>(`${this.getPath()}/branches/${branch}/files`, {
        branch,
        name,
        description,
        data,
        metadata,
      })
      .pipe(catchError(this.handleError('addConfig', [])));
  }

  renameConfig({
    branch,
    description,
    name,
    file,
  }: {
    branch: string;
    description: string;
    name: string;
    file: string;
  }): Observable<{}> {
    return this.http
      .patch(`${this.getPath()}/branches/${branch}/files/${file}`, {
        description,
        name,
      })
      .pipe(catchError(this.handleError('renameConfig')));
  }

  cloneConfig({
    local,
    description,
    name,
    source,
  }: {
    local: string;
    description?: string;
    name: string;
    source: string;
  }): Observable<{}> {
    return this.http
      .post(`${this.getPath()}/branches/${local}/files`, {
        local,
        description,
        name,
        source,
        project: this.project.short_name_cm,
      })
      .pipe(catchError(this.handleError('renameConfig')));
  }

  deleteConfig({
    branch,
    file,
  }: {
    branch: string;
    file: string;
  }): Observable<{}> {
    return this.http
      .delete(`${this.getPath()}/branches/${branch}/files/${file}`)
      .pipe(catchError(this.handleError('deleteConfig')));
  }

  getConfigs(branch: string): Observable<Config[]> {
    return this.http
      .get<Config[]>(`${this.getPath(true)}/branches/${branch}/files`)
      .pipe(catchError(this.handleError('getConfigs', [])));
  }

  downloadConfig(branch: string, config: string, options: any): void {
    this.authService.getIdToken().then(
      (idToken) => {
        const fromObject = Object.assign({ Authorization: idToken }, options);
        const params = new HttpParams({ fromObject });

        window.open(
          `${this.getPath()}/branches/${branch}/files/${config}/download?${params.toString()}`,
        );
      },
      (error) => console.error(`downloadConfig: ${error}`),
    );
  }

  getSections(branch: string, config: string): Observable<string[]> {
    return this.http
      .get<string[]>(
        `${this.getPath(true)}/branches/${branch}/files/${config}/sections`,
      )
      .pipe(catchError(this.handleError('getSections', [])));
  }

  getSection(
    branch: string,
    config: string,
    section: string,
  ): Observable<ConfigLegacy> {
    const params = new HttpParams().set('lock', 'false');

    return this.http
      .get<ConfigLegacy>(
        `${this.getPath(
          true,
        )}/branches/${branch}/files/${config}/sections/${section}`,
        { params, observe: 'response' },
      )
      .pipe(
        catchError((res: HttpErrorResponse) => {
          // Status: HTTP_LOCKED (423)
          if (res.status === 423) {
            return of(res.error);
          }
          return this.handleError('getSection')(res);
        }),
        map(({ body, headers }: HttpResponse<ConfigLegacy>) => {
          this.sectionOL.setFromHeaders({ branch, config, section }, headers);
          return body;
        }),
      );
  }

  getBranchLinks(branch: string): Observable<ConfigLinks[]> {
    return this.http
      .get<ConfigLinks[]>(`${this.getPath()}/branches/${branch}/link`)
      .pipe(catchError(this.handleError('getBranchLinks', [])));
  }

  getLinks(branch: string, config: string): Observable<SectionLink[]> {
    return this.http
      .get<SectionLink[]>(
        `${this.getPath()}/branches/${branch}/files/${config}/link`,
      )
      .pipe(catchError(this.handleError('getLinks', [])));
  }

  updateLink(
    branch: string,
    config: string,
    link: SectionLink,
  ): Observable<{}> {
    return this.http
      .post(
        `${this.getPath()}/branches/${branch}/files/${config}/sections/${
          link.name
        }/google/link`,
        link,
      )
      .pipe(catchError(this.handleError('updateLink')));
  }

  deleteLink(branch: string, config: string, section: string): Observable<{}> {
    return this.http
      .delete(
        `${this.getPath()}/branches/${branch}/files/${config}/sections/${section}/google/link`,
      )
      .pipe(catchError(this.handleError('deleteLink')));
  }

  getConfigSections(branch: string, config: string): Observable<unknown> {
    const params = new HttpParams({
      fromObject: {
        render: 'true',
        metadata: 'true',
      },
    });
    return this.http
      .get(`${this.getPath()}/branches/${branch}/files/${config}/download`, {
        params,
      })
      .pipe(catchError(this.handleError('getConfigSections')));
  }

  validateBranch(branch: string, environment: string): Observable<any> {
    return this.http
      .post<Report[]>(
        `${this.getPath()}/envs/${environment}/branches/${branch}/validate`,
        {},
      )
      .pipe(catchError(this.handleError('validateBranch', [])));
  }

  downloadBranch(branch: string, options: any): void {
    this.authService.getIdToken().then(
      (idToken) => {
        const fromObject = Object.assign({ Authorization: idToken }, options);
        const params = new HttpParams({ fromObject });

        let endpoint = 'download';
        if (options.unique) {
          endpoint = 'files';
        }

        window.open(
          `${this.getPath()}/branches/${branch}/${endpoint}?${params.toString()}`,
        );
      },
      (error) => console.error(`downloadBranch: ${error}`),
    );
  }

  getEnvironments(): Observable<Environment[]> {
    return this.http
      .get<Environment[]>(`${this.getPath()}/envs`)
      .pipe(catchError(this.handleError('getEnvironments', [])));
  }

  getEnvironmentNamesByBranch(): Observable<Map<string, string[]>> {
    return this.getEnvironments().pipe(
      map((environments) => {
        const mapEnvs = new Map<string, string[]>();
        for (const env of environments) {
          if (!env.branch) continue;
          const branchEnvs = mapEnvs.get(env.branch) || [];

          const name = env.name.replace(/^dyn/i, '');
          mapEnvs.set(env.branch, branchEnvs.concat(name));
        }

        return mapEnvs;
      }),
    );
  }

  deployEnvironment(environment: string, branch: string): Observable<void> {
    return this.http
      .post<any>(
        `${this.getPath()}/envs/${environment}/branches/${branch}/deploy`,
        {},
      )
      .pipe(
        catchError(
          this.handleError('deployEnvironment', [environment, branch]),
        ),
      );
  }

  unDeployEnvironment(environment: string): Observable<void> {
    return this.http
      .post<any>(`${this.getPath()}/envs/${environment}/undeploy`, {})
      .pipe(catchError(this.handleError('unDeployEnvironment', [environment])));
  }

  addSection({
    local,
    file,
    section_name,
    data = [],
    metadata,
  }: {
    local: string;
    file: string;
    section_name: string;
    data: any[];
    metadata: any;
  }): Observable<object> {
    return this.http
      .post<{ position: number }>(
        `${this.getPath()}/branches/${local}/files/${file}/sections`,
        {
          local,
          file,
          section_name,
          data,
          metadata,
          project: this.project.short_name_cm,
        },
      )
      .pipe(catchError(this.handleError('addSection', {})));
  }

  deleteSection({
    branch,
    config,
    section,
  }: {
    branch: string;
    config: string;
    section: string;
  }): Observable<unknown> {
    return this.http
      .delete(
        `${this.getPath()}/branches/${branch}/files/${config}/sections/${section}`,
      )
      .pipe(catchError(this.handleError('deleteSection')));
  }

  putSection({
    branch,
    config,
    section,
    body,
  }: {
    branch: string;
    config: string;
    section: string;
    body: Section;
  }): Observable<any> {
    const headers = this.sectionOL.getCheckHeaders({ branch, config, section });
    return this.http
      .put<any>(
        `${this.getPath(
          true,
        )}/branches/${branch}/files/${config}/sections/${section}`,
        body,
        { headers, observe: 'response' },
      )
      .pipe(
        catchError((res: HttpErrorResponse) => {
          // Status: HTTP_BAD_REQUEST (400) or HTTP_PRECONDITION_FAILED (412) or HTTP_LOCKED (423)
          if (res.status === 400 || res.status === 412 || res.status === 423) {
            return throwError(res);
          }
          return this.handleError('putSection')(res);
        }),
        map((response: HttpResponse<ConfigLegacy>) => {
          this.sectionOL.setFromHeaders(
            { branch, config, section },
            response.headers,
          );
          return response.body;
        }),
      );
  }

  renameSection({
    local,
    file,
    section,
    section_name,
    action,
  }: {
    local: string;
    file: string;
    section: string;
    section_name: string;
    action: string;
  }): Observable<{}> {
    return this.http
      .put(
        `${this.getPath()}/branches/${local}/files/${file}/sections/${section}`,
        { local, file, section, section_name, action },
      )
      .pipe(catchError(this.handleError('renameSection')));
  }

  getSectionLock({
    branch,
    config,
    section,
  }: {
    branch: string;
    config: string;
    section: string;
  }): Observable<object> {
    return this.http
      .get(
        `${this.getPath()}/branches/${branch}/files/${config}/sections/${section}/lock`,
      )
      .pipe(
        catchError((res: HttpErrorResponse) => {
          // Status: HTTP_BAD_REQUEST (400) or HTTP_NOT_FOUND (404)
          if (res.status === 400 || res.status === 404) {
            return of(null);
          }
          return this.handleError('getSectionLock', [])(res);
        }),
      );
  }

  setSectionLock({
    branch,
    config,
    section,
  }: {
    branch: string;
    config: string;
    section: string;
  }): Observable<object> {
    return this.http
      .post(
        `${this.getPath()}/branches/${branch}/files/${config}/sections/${section}/lock`,
        {},
      )
      .pipe(
        catchError((res: HttpErrorResponse) => {
          // Status: HTTP_BAD_REQUEST (400) or HTTP_LOCKED (423)
          if (res.status === 400) {
            return of(null);
          } else if (res.status === 423) {
            return throwError(res.error);
          }
          return this.handleError('setSectionLock', [])(res);
        }),
      );
  }

  deleteSectionLock({
    branch,
    config,
    section,
  }: {
    branch: string;
    config: string;
    section: string;
  }): Observable<object> {
    return this.http
      .delete(
        `${this.getPath()}/branches/${branch}/files/${config}/sections/${section}/lock`,
      )
      .pipe(
        catchError((res: HttpErrorResponse) => {
          // Status: HTTP_BAD_REQUEST (400) or HTTP_NOT_FOUND (404)
          if (res.status === 400 || res.status === 404) {
            return of(null);
          }
          return this.handleError('deleteSectionLock', [])(res);
        }),
      );
  }

  getGitDiff(
    local: string,
    remote: string = 'master',
    onlyConflicts: boolean = true,
  ): Observable<GitDiffResponse> {
    const params = new HttpParams().set(
      'onlyConflicts',
      onlyConflicts ? '1' : '0',
    );
    return this.http.get<GitDiffResponse>(
      `${this.getPath()}/branches/${local}/compare/${remote}`,
      { params },
    );
  }

  addGitPatch(
    local: string,
    remote: string,
    selectedFiles: string[],
  ): Observable<object> {
    return this.http.patch(
      `${this.getPath()}/branches/${local}/merge/${remote}`,
      { selectedFiles },
    );
  }

  addGitDryMerge(
    local: string,
    remote: string,
    selectedFiles: string[],
  ): Observable<object> {
    return this.http.patch(
      `${this.getPath()}/branches/${local}/dry-merge/${remote}`,
      { selectedFiles },
    );
  }

  getGithubAuth({
    code,
    state,
  }: {
    code: string;
    state: string;
  }): Observable<any> {
    return this.http
      .post(`${this.origin}/github/auth`, { code, state })
      .pipe(catchError(this.handleError('getGithubAuth')));
  }

  createGithubPullRequest({
    branch,
    name,
    title,
    body,
    access_token,
  }: {
    branch: string;
    name: string;
    title: string;
    body: string;
    access_token: string;
  }): Observable<any> {
    const format = this.isCoolGame ? 'json' : 'php';
    return this.http.post(
      `${this.getPath()}/branches/${branch}/github/pull_request`,
      { name, title, body, access_token, format },
    );
  }

  get isCoolGame(): boolean {
    return this.coolGames.includes(this.project.short_name_cm);
  }

  get useFastApi(): boolean {
    return this.isGitProject && BetaComponent.HasOptedIn(Beta.CmUseFastApi);
  }
}
