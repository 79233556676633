import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Api } from '@app/providers/api/api';
import {
  Payment,
  paymentFactory,
  PaymentModel,
} from './payment-validation.model';
import { ProjectService } from '@app/providers/projects';

@Injectable()
export class PaymentValidationApiService {
  private readonly auditURL = '//pavs.bs.laicosp.net/api/v1/audit';
  private readonly gateway = '//gateway.bs.laicosp.net/api/console/datadog';

  constructor(private project: ProjectService, private http: HttpClient) {}

  getUserPayment(id: number): Observable<Payment[]> {
    const search = new URLSearchParams();
    const projectData = this.project.getProject();

    search.set('project', projectData['short_name']);
    search.set('user_id', id.toString());

    return this.http.get(`${this.auditURL}?${search.toString()}`).pipe(
      map((payments: PaymentModel[]): Payment[] =>
        payments.map((payment: PaymentModel) => paymentFactory(payment)),
      ),
      catchError((error) => throwError(Api.replaceError(error))),
    );
  }

  getRefunds({
    from,
    to,
    project,
  }: {
    from: number;
    to: number;
    project: string;
  }): Observable<any> {
    return this.http.get(
      `${this.gateway}/query?query=sum:sp.backend.services.pavs.transaction.validation{refunded:true,project:${project}}.as_count()&from=${from}&to=${to}`,
    );
  }

  getSize({
    from,
    to,
    owner,
  }: {
    from: number;
    to: number;
    owner: string;
  }): Observable<any> {
    const aggrSize = (agg) =>
      `avg:jarvis.get.response.size.${agg}{owner:${owner}}`;
    return this.http.get(
      `${this.gateway}/query?query=${aggrSize('avg')},${aggrSize(
        '95percentile',
      )},${aggrSize('max')}&from=${from}&to=${to}`,
    );
  }
}
