import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CallbackComponent } from './callback.component';

export const callbackRoutes: Routes = [
  {
    path: 'callback/:service',
    component: CallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule],
  declarations: [CallbackComponent],
})
export class CallbackModule {}
