export function dayOfWeekParse(raw) {
  if (raw === '*') return [0, 1, 2, 3, 4, 5, 6];
  if (raw === '?') return [];
  return raw.split(',').map((dow) => parseInt(dow, 10));
}

export function dayOfWeekUnparse(data: number[]) {
  // Prepare a unique and sorted list
  data = Array.from(new Set(data)).sort();
  if (data.length === 7) return '*';
  if (data.length === 0) return '?';
  return data.join(',');
}

export function monthParse(raw) {
  if (raw === '*') return 1;
  return parseInt(raw.split('/')[1], 10);
}

export function monthUnparse(every, month) {
  if (every === 1) return '*';
  if (month == null) return `*/${every}`;

  const baseMonth = month % every || every;
  return `${baseMonth}/${every}`;
}
