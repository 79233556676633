import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared';
import { ProjectItemComponent } from './project-item/project-item.component';
import { ProjectSelectorComponent } from './project-selector/project-selector.component';
import { ProjectDialogComponent } from './project-dialog/project-dialog.component';

@NgModule({
  imports: [
    // Jarvis
    SharedModule,
  ],
  declarations: [
    ProjectItemComponent,
    ProjectSelectorComponent,
    ProjectDialogComponent,
  ],
  exports: [
    ProjectItemComponent,
    ProjectSelectorComponent,
    ProjectDialogComponent,
  ],
})
export class ProjectModule {}
