import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Breadcrumb } from '@sentry/types';

import { AuthService } from '@app/providers/auth/auth.service';
import { environment } from '@env/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  isSetup = false;

  constructor(private auth: AuthService) {
    if (!environment.sentryDSN) return;

    Sentry.init({
      dsn: environment.sentryDSN,
      environment: environment.name,
      release: environment.build,
    });
    this.isSetup = true;

    auth.profile$.subscribe(({ email }) => {
      if (!email) return;

      Sentry.configureScope((scope) => {
        scope.setUser({ email });
      });
    });
  }

  handleError(error): void {
    if (this.isSetup) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }
}

export function addBreadcrumb(category, message, level = Sentry.Severity.Info) {
  const breadCrumb: Breadcrumb = { category, message, level };

  if (environment.sentryDSN) {
    Sentry.addBreadcrumb(breadCrumb);
  }
}

export function captureMessage(message, level = Sentry.Severity.Info) {
  if (environment.sentryDSN) {
    Sentry.captureMessage(message, level);
  }
}
