import { Metadata } from '@app/lomt/shared/metadata/metadata.interface';

export interface DataTable extends Metadata {
  id: number;
  content_type: string;
  name: string;
}

export class DateFormats {
  public static readonly FORMAT = 'YYYY-MM-DD';
  public static readonly TIME = 'HH:mm';
  public static readonly FORMAT_LONG = `${DateFormats.FORMAT} ${DateFormats.TIME}`;
  public static readonly DAY: number = 24 * 60 * 60;
}
