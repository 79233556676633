import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { firstValueFrom, from, Observable, ReplaySubject } from 'rxjs';
import {
  concatMap,
  delay,
  first,
  map,
  retry,
  switchMap,
  tap,
} from 'rxjs/operators';

import { ConfigManagerService } from '@app/config-manager/shared/api.service';
import {
  GithubStatusSummaryResponse,
  GithubData,
  GithubPermissionStatus,
  GithubRelease,
  GithubResponse,
  GithubResponseError,
  GithubStatus,
  GithubUser,
  GITHUB_CONFIG,
  GithubConfig,
} from '@app/providers/github/github.model';
import { routerConfig } from '@env/environment';
import { WindowRefService } from '@app/providers/window/window-ref.service';
import { addBreadcrumb, captureMessage } from '@app/providers/error-handler';

@Injectable()
export class GithubService {
  static onStatusChange$ = new ReplaySubject(1);

  user: GithubUser;

  private readonly apiEndpoint = 'https://api.github.com/repos/socialpoint';
  private readonly redirectEndpoint =
    'https://configmanager.pro.backend.bs.laicosp.net/github';
  private readonly authenticationUri =
    'https://github.com/login/oauth/authorize';
  private readonly clientId = '43e91653af5b90e4fdf8';
  private readonly scope = 'user:email,repo';
  private token$: ReplaySubject<string> = new ReplaySubject(1);
  private window: Window;

  private loadToken = () => localStorage.getItem('github_token');
  private saveToken = (token: string) =>
    localStorage.setItem('github_token', token);
  private clearToken = () => localStorage.removeItem('github_token');
  private createAuthState = () => Math.random().toString(36).substring(2, 15);

  constructor(
    @Inject(GITHUB_CONFIG) private config: GithubConfig,
    private api: ConfigManagerService,
    private http: HttpClient,
    windowRef: WindowRefService,
  ) {
    // Filtering forbidden urls if defined
    if (
      config.forbiddenPaths &&
      config.forbiddenPaths.some(
        (url) => window.location.href.indexOf(url) !== -1,
      )
    ) {
      return;
    }

    this.status = GithubStatus.loading;
    this.window = windowRef.nativeWindow;

    const token = this.loadToken();
    if (token) {
      this.checkToken(token)
        .then(() => {
          this.status = GithubStatus.authorized;
          this.token$.next(token);
        })
        .catch(() => {
          this.fetchToken();
        });
    } else {
      this.status = GithubStatus.unauthorized;
      this.fetchToken();
    }
  }

  /**
   * Lazy fetch githubToken from the github authorization api
   *
   * @returns {Promise<string>}
   */
  async getToken(): Promise<string> {
    return firstValueFrom(this.token$.pipe(first((token) => token != null)));
  }

  fetchAuthHeaders<T = any>(
    switchFn: (headers: HttpHeaders) => Observable<T>,
  ): Observable<T> {
    return from(this.getToken()).pipe(
      map((token) => new HttpHeaders().set('Authorization', `token ${token}`)),
      switchMap(switchFn),
    );
  }

  fetchToken() {
    // Clear previous credentials (it's ok if aren't any)
    this.status = GithubStatus.unauthorized;
    this.clearToken();
    this.token$.next(null);

    // Request new credentials
    this.status = GithubStatus.authorizing;

    this.requestAuthPermission((data) => {
      if (!data.status) {
        return this.retryFetchToken(data.error);
      }

      this.status = GithubStatus.validating;
      this.api.getGithubAuth(data.response).subscribe(
        (response: GithubResponse) => {
          this.status = GithubStatus.authorized;
          this.saveToken(response.access_token);
          this.token$.next(response.access_token);
        },
        (err: GithubResponseError) =>
          this.retryFetchToken('Error: ' + err.message),
      );
    });
  }

  getRepository(repository: string): Observable<any> {
    return this.fetchAuthHeaders((headers) =>
      this.http.get(`${this.apiEndpoint}/${repository}`, {
        headers,
      }),
    );
  }

  getRelease(repository: string, releaseId: string): Observable<any> {
    return this.fetchAuthHeaders((headers) =>
      this.http.get(`${this.apiEndpoint}/${repository}/releases/${releaseId}`, {
        headers,
      }),
    );
  }

  getReleaseByTag(repository: string, tag: string): Observable<any> {
    return this.fetchAuthHeaders((headers) =>
      this.http.get(`${this.apiEndpoint}/${repository}/releases/tags/${tag}`, {
        headers,
      }),
    );
  }

  getReleases(repository: string): Observable<any> {
    return this.fetchAuthHeaders((headers) =>
      this.http.get(`${this.apiEndpoint}/${repository}/releases`, { headers }),
    );
  }

  /**
   * Create a release
   *
   * step 1: Delete pre-release
   * step 2: Create release
   *
   * @returns {Observable<object>}
   */
  reCreateRelease(
    repository: string,
    { id, tag_name, name, body, target_commitish }: GithubRelease,
  ): Observable<any> {
    return this.deleteRelease(repository, { id }).pipe(
      delay(500),
      concatMap(() => this.deleteTag(repository, tag_name)),
      delay(500),
      concatMap(() =>
        this.createRelease(repository, {
          target_commitish,
          tag_name,
          name,
          body,
          prerelease: false,
          draft: false,
        }),
      ),
    );
  }

  deleteRelease(
    repository: string,
    { id }: Partial<GithubRelease>,
  ): Observable<any> {
    addBreadcrumb('github', `deleteRelease id: ${id}`);
    return this.fetchAuthHeaders((headers) =>
      this.http
        .delete(`${this.apiEndpoint}/${repository}/releases/${id}`, { headers })
        .pipe(retry(2)),
    );
  }

  deleteTag(repository: string, tag: string): Observable<any> {
    addBreadcrumb('github', `deleteTag tag: ${tag}`);
    return this.fetchAuthHeaders((headers) =>
      this.http
        .delete(`${this.apiEndpoint}/${repository}/git/refs/tags/${tag}`, {
          headers,
        })
        .pipe(retry(2)),
    );
  }

  createRelease(
    repository: string,
    body: Partial<GithubRelease>,
  ): Observable<any> {
    addBreadcrumb(
      'github',
      `createRelease tag: ${body.tag_name}, target: ${
        body.target_commitish
      }, preRelease: ${!!body.prerelease}`,
    );
    return this.fetchAuthHeaders((headers) =>
      this.http
        .post(`${this.apiEndpoint}/${repository}/releases`, body, { headers })
        .pipe(retry(2)),
    );
  }

  updateRelease(
    repository: string,
    id: number,
    body: Partial<GithubRelease>,
  ): Observable<any> {
    addBreadcrumb(
      'github',
      `updateRelease tag: ${id}, preRelease: ${!!body.prerelease}`,
    );
    return this.fetchAuthHeaders((headers) =>
      this.http.patch(
        `${this.apiEndpoint}/${repository}/releases/${id}`,
        body,
        {
          headers,
        },
      ),
    );
  }

  compareCommits(repository: string, version: string): Observable<any> {
    return this.fetchAuthHeaders((headers) =>
      this.http.get(
        `${this.apiEndpoint}/${repository}/compare/${version}...master`,
        { headers },
      ),
    );
  }

  compareRefs(
    repository: string,
    local: string,
    remote: string,
    asDiff = false,
  ): Observable<any> {
    return this.fetchAuthHeaders((headers) => {
      const options: any = { headers };
      if (asDiff) {
        options.headers = options.headers.set(
          'Accept',
          'application/vnd.github.diff',
        );
        options.responseType = 'text';
      }
      return this.http.get(
        `${this.apiEndpoint}/${repository}/compare/${remote}...${local}`,
        options,
      );
    });
  }

  getStatus(
    repository: string,
    ref: string,
    prev?: HttpResponse<any>,
  ): Observable<any> {
    return this.fetchAuthHeaders((headers) => {
      if (prev) {
        headers = headers.set('If-None-Match', prev.headers.get('ETag'));
      }
      return this.http.get(
        `${this.apiEndpoint}/${repository}/commits/${ref}/status`,
        { headers, observe: 'response' },
      );
    });
  }

  getChecks(
    repository: string,
    ref: string,
    prev?: HttpResponse<any>,
  ): Observable<any> {
    return this.fetchAuthHeaders((headers) => {
      if (prev) {
        headers = headers.set('If-None-Match', prev.headers.get('ETag'));
      }
      return this.http.get(
        `${this.apiEndpoint}/${repository}/commits/${ref}/check-runs`,
        { headers, observe: 'response' },
      );
    });
  }

  getStatusSummary(): Observable<GithubStatusSummaryResponse> {
    return this.http.get<GithubStatusSummaryResponse>(
      `https://www.githubstatus.com/api/v2/summary.json?t=${Date.now()}`,
    );
  }

  set status(value: GithubStatus) {
    GithubService.onStatusChange$.next(value);
  }

  private async checkToken(token: string): Promise<void> {
    try {
      await firstValueFrom(
        this.http
          .get<GithubUser>(`https://api.github.com/user`, {
            headers: new HttpHeaders().set('Authorization', `token ${token}`),
          })
          .pipe(tap((user) => (this.user = user))),
      );
    } catch (e) {
      if (e.status === 401) {
        throw new Error('Unauthorized: Invalid token');
      }
    }

    if (!token.startsWith('gho_')) {
      captureMessage(
        `Github user ${this.user.name} (${this.user.login}) had an outdated token`,
      );
      throw new Error('Outdated: Request a new token');
    }
  }

  private retryFetchToken(error) {
    setTimeout(() => this.fetchToken(), 5000);
  }

  /**
   * Open a github auth window and when the user finalize the process run the callback with the status
   *
   * @param {string} url
   * @param {(data: GithubPermissionStatus) => void} callback
   */
  private openAuthWindow(
    url: string,
    callback: (data: GithubPermissionStatus) => void,
  ): void {
    const authWindow: any = this.window.open(url);
    const maxTime = Date.now() + 300 * 1000; // Max. 5 min with the window open

    if (authWindow == null) {
      return callback({
        status: false,
        error: 'Error: Authentication window closed by the browser',
      });
    }

    const finalize = (data: GithubPermissionStatus): void => {
      clearInterval(timer);
      authWindow.close();
      callback(data);
    };

    const timer = setInterval(() => {
      let callbackData: GithubData;
      try {
        callbackData = authWindow.callbackData;
        authWindow.trigger('blur');
      } catch (e) {}

      if (callbackData) {
        return finalize({
          status: true,
          response: Object.assign({}, callbackData),
        });
      }
      if (maxTime < Date.now()) {
        return finalize({
          status: false,
          error: 'Error: Authentication timeout exceeded',
        });
      }
      if (authWindow.closed) {
        return finalize({
          status: false,
          error: 'Error: Authentication window closed manually',
        });
      }
    }, 500);
  }

  /**
   * Request the user for github permission access and run the callback with the status
   *
   * @param {(data: GithubPermissionStatus) => void} callback
   */
  private requestAuthPermission(
    callback: (data: GithubPermissionStatus) => void,
  ) {
    const state = this.createAuthState();
    const authParams = new HttpParams({
      fromObject: {
        client_id: this.clientId,
        scope: this.scope,
        state,
        redirect_uri: this.buildRedirectUri(),
      },
    });

    this.openAuthWindow(
      `${this.authenticationUri}?${authParams.toString()}`,
      (data) => {
        if (!data.status) {
          callback(data);
        } else if (!data.response) {
          callback({
            status: false,
            error: 'Error: Authentication response not found',
          });
        } else if (!data.response.state) {
          callback({
            status: false,
            error: 'Error: State parameter is not found',
          });
        } else if (!data.response.code) {
          callback({
            status: false,
            error: 'Error: Code parameter is not found',
          });
        } else if (data.response.state !== state) {
          callback({
            status: false,
            error: 'Error: State parameter is not matching expectation',
          });
        } else {
          callback(data);
        }
      },
    );
  }

  private buildRedirectUri() {
    let params = new HttpParams();

    if (routerConfig['useHash']) {
      params = params.set(
        'cb',
        `${this.window.location.origin}${this.window.location.pathname}#`,
      );
    } else {
      params = params.set('cb', this.window.location.origin);
    }

    return `${this.redirectEndpoint}?${params.toString()}`;
  }
}
