import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';

import { SessionService } from './session.service';
import { captureMessage } from '@app/providers';

@Injectable()
export class SessionGuardService implements CanActivate, CanActivateChild {
  constructor(private sessionService: SessionService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    try {
      await this.sessionService.profilePromise;
      return true;
    } catch (err) {
      captureMessage(
        `SessionGuardService blocked page activation, ${JSON.stringify({
          error: `${err}`,
          url: state.url.toString(),
        })}`,
      );
      return false;
    }
  }

  canActivateChild = this.canActivate;
}
