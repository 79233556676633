import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import {
  CheckerFinding,
  CheckerPlugin,
  CheckerPluginChecks,
  CheckerPluginFinding,
  CheckerStatus,
} from '../checker.model';
import {
  GithubCheckerCheck,
  GithubCheckerPermission,
  GithubGlobalCheckerCheck,
  statusEmoji,
} from './github-checker.model';
import { GithubService } from '@app/providers/github/github.service';
import { captureMessage } from '@app/providers';
import { GithubStatusSummaryResponse } from '@app/providers/github/github.model';

@Injectable()
export class GithubCheckerPlugin implements CheckerPlugin {
  static GlobalChecks(
    ...checks: GithubGlobalCheckerCheck[]
  ): CheckerPluginChecks {
    return { plugin: this, checks };
  }

  static Checks(...checks: GithubCheckerCheck[]): CheckerPluginChecks {
    return { plugin: this, checks };
  }

  private cache: Map<string, CheckerFinding> = new Map();

  constructor(private api: GithubService) {}

  async globalCheck(
    check: GithubGlobalCheckerCheck,
  ): Promise<CheckerPluginFinding> {
    let finding: CheckerFinding = {
      status: CheckerStatus.warn,
      title: 'Github Plugin',
      message: `Unexpected global check id: ${check.id}`,
    };
    if (check.id === 'status') {
      finding = await this.checkGithubStatus();
    }
    return { check, finding };
  }

  async pageCheck(
    url: string,
    params: Record<string, any>,
    check: GithubCheckerCheck,
  ): Promise<CheckerPluginFinding> {
    return this.runCheck(params, check);
  }

  async runCheck(
    params: Record<string, any>,
    check: GithubCheckerCheck,
  ): Promise<CheckerPluginFinding> {
    const repo = check.repo(params);
    const key = `${repo}:${check.permissions}`;

    let finding = this.cache.get(key);
    if (finding == null) {
      finding = await this.checkRepoPermissions(repo, check.permissions);
      this.cache.set(key, finding);
    }

    return { check, finding };
  }

  async checkRepoPermissions(
    repo: string,
    permissions: GithubCheckerPermission[],
  ): Promise<CheckerFinding> {
    let data;
    try {
      data = await firstValueFrom(this.api.getRepository(repo));
    } catch (e) {
      return this.findingError(repo, permissions);
    }

    const missing = [];
    for (const key of permissions) {
      if (!data.permissions[key]) {
        missing.push(key);
      }
    }

    return missing.length
      ? this.findingError(repo, missing)
      : this.findingSuccess(repo);
  }

  async checkGithubStatus(): Promise<CheckerFinding> {
    let data: GithubStatusSummaryResponse;
    try {
      data = await firstValueFrom(this.api.getStatusSummary());
    } catch (e) {
      return {
        status: CheckerStatus.error,
        title: `Github Status`,
        message: `API is not responsive`,
      };
    }

    const warns = {};
    for (const comp of data.components) {
      const emoji = statusEmoji[comp.status];
      if (emoji !== undefined) {
        if (warns[emoji] === undefined) {
          warns[emoji] = `${emoji} ${comp.name.replace(/\s+/g, '')}`;
        } else {
          warns[emoji] += `,${comp.name.replace(/\s+/g, '')}`;
        }
      }
    }

    const warnsStr = Object.values(warns).sort().join(' ');
    return warnsStr.length
      ? {
          status: CheckerStatus.warn,
          title: `Github Status (Service degraded)`,
          message: warnsStr,
          link: `https://www.githubstatus.com/`,
        }
      : {
          status: CheckerStatus.ok,
          title: `Github Status`,
          message: `All components operational`,
        };
  }

  private findingSuccess(repo: string): CheckerFinding {
    return {
      status: CheckerStatus.ok,
      title: `Github: socialpoint/${repo}`,
      message: `Permissions are correct`,
      link: `https://github.com/socialpoint/${repo}`,
    };
  }

  private findingError(repo: string, missing: string[]): CheckerFinding {
    // Report the errors to sentry (only in PRO)
    captureMessage(
      `GithubChecker -> ${
        this.api.user?.login
      } -> socialpoint/${repo}: require ${missing.join(' and ')} permissions`,
    );
    return {
      status: CheckerStatus.error,
      title: `Github: socialpoint/${repo}`,
      message: `Require permission to ${missing.join(' and ')}`,
      link: 'https://socialpoint.atlassian.net/wiki/spaces/BS/pages/4395008095/How+to+request+permissions+on+apps+through+Kissflow',
    };
  }
}
