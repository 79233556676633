import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterPipe } from './filter.pipe';
import { GroupByPipe } from './group-by.pipe';
import { IterableToArray } from './iterable-to-array.pipe';
import { TimestampToDate } from './timestamp-to-date.pipe';
import {
  NumeralFormat,
  NumeralFormatInteger,
  PercentageFormat,
} from '@app/shared/pipes/numeral-format.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterPipe,
    GroupByPipe,
    IterableToArray,
    TimestampToDate,
    NumeralFormat,
    NumeralFormatInteger,
    PercentageFormat,
    SanitizeHtmlPipe,
  ],
  providers: [
    FilterPipe,
    GroupByPipe,
    IterableToArray,
    TimestampToDate,
    NumeralFormat,
    NumeralFormatInteger,
    PercentageFormat,
    SanitizeHtmlPipe,
  ],
  exports: [
    FilterPipe,
    GroupByPipe,
    IterableToArray,
    TimestampToDate,
    NumeralFormat,
    NumeralFormatInteger,
    PercentageFormat,
    SanitizeHtmlPipe,
  ],
})
export class PipesModule {}
