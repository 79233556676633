export interface TodayModel {
  name: string;
  icon?: string;
  image?: string;
  url?: string;
}

export const iconByDay = new Map<string, TodayModel>([
  ['02-02', { icon: '🎤🐿', name: 'Groundhog Day' }],
  ['03-02', { icon: '🥑', name: 'Abogado Day' }],
  [
    '13-02',
    {
      icon: '📻️',
      name: 'World Radio Day',
      url: 'https://www.un.org/en/observances/radio-day',
    },
  ],
  ['14-02', { icon: '❤️', name: "Saint Valentine's day" }],
  [
    '08-03',
    {
      icon: '👩‍🦱👩🏻‍💼👩🏼‍💼👩🏽‍💻👩🏾‍✈️👩🏿‍🔬️',
      name: "International Women's Day",
      url: 'https://en.unesco.org/commemorations/womenday',
    },
  ],
  [
    '22-03',
    {
      icon: '💧',
      name: 'World Water Day',
      url: 'https://en.unesco.org/commemorations/waterday',
    },
  ],
  [
    '31-03',
    { image: '/assets/project-icon/demo.png', name: 'Jarvis Anniversary' },
  ],
  ['23-04', { icon: '🌹️', name: "Saint George's day" }],
  [
    '09-05',
    { image: '/assets/project-icon/dragonball.png️', name: "Goku's day" },
  ],
  [
    '05-06',
    {
      icon: '🍩',
      name: 'Doughnut Day',
      url: 'https://www.daysoftheyear.com/days/doughnut-day/',
    },
  ],
  ['03-07', { icon: '🏳️‍🌈️', name: 'We are family' }],
  ['19-07', { icon: '🚀🌝', name: 'Anniversary of the Moon Landing' }],
  ['20-07', { icon: '👫👭', name: 'Friendship Day' }],
  ['21-07', { icon: '🐶', name: "Dog's day" }],
  ['26-07', { icon: '👵🏻👴🏻️', name: "Grandpa's day" }],
  ['02-08', { icon: '🍻', name: 'Beers day' }],
  ['13-09', { icon: '👩🏻‍💻👨🏼‍💻', name: "Programmers' Day" }],
  ['21-09', { icon: '🕊', name: 'Peace Day' }],
  ['22-09', { icon: '🚫🚘', name: 'Car-Free Days' }],
  ['29-09', { icon: '♥', name: 'World Heart Day' }],
  [
    '02-10',
    {
      icon: '☮️',
      name: 'International Day of Non-Violence',
      url: 'https://www.un.org/en/events/nonviolenceday/',
    },
  ],
  [
    '04-10',
    {
      icon: '🐶🐱🐭🐹🐰🦊🐻🐼🙊️',
      name: 'World Animal Day',
      url: 'https://www.worldanimalday.org.uk/',
    },
  ],
  [
    '05-10',
    {
      icon: '👩🏻‍🏫👨‍🏫',
      name: 'World Teachers Day',
      url: 'https://en.unesco.org/commemorations/worldteachersday',
    },
  ],
  [
    '11-10',
    {
      icon: '🧒',
      name: 'International Day of the Girl Child',
      url: 'https://www.un.org/en/events/girlchild/',
    },
  ],
  ['31-10', { icon: '👻🎃', name: 'Halloween' }],
  [
    '10-11',
    {
      icon: '🌍',
      name: 'World Usability Day',
      url: 'https://worldusabilityday.org/',
    },
  ],
  [
    '19-11',
    {
      icon: '🚽',
      name: 'World Toilet Day',
      url: 'https://www.worldtoiletday.info/',
    },
  ],
]);
