import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { Project, createProject } from './project.model';

interface MadIcons {
  [id: string]: string;
}

@Injectable()
export class ProjectService {
  project$ = new ReplaySubject<Project>(1);
  projects$ = new ReplaySubject<Project[]>(1);

  private project: Project;
  private projects: Project[];

  async setProjects(projects: Project[], icons: MadIcons): Promise<Project[]> {
    this.projects = projects
      .map(createProject)
      .map((p) => this.attachIcon(p, icons))
      .filter((p) => !p.config.disabled);

    this.projects$.next(this.projects);

    return this.projects;
  }

  getProjects(): Project[] {
    return this.projects;
  }

  setProject(project: Project): void {
    const selectedProject = createProject(project);
    const currentProject = this.project;
    this.project = selectedProject;
    if (JSON.stringify(currentProject) !== JSON.stringify(project)) {
      this.project$.next(selectedProject);
    }
  }

  getProject(filter?: string | number): Project | string | number {
    if (this.project && filter) {
      return this.project[filter];
    }
    return this.project;
  }

  getProjectById(projectId: number): Project {
    return this.projects.find(({ id }) => projectId === id);
  }

  private attachIcon(project: Project, icons: MadIcons): Project {
    if (icons[project.config.madId]) {
      project.icon = icons[project.config.madId];
    }
    return project;
  }
}
