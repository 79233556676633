import { Project } from '@app/providers/projects';

export class SearchNavigator {
  selected: Project;

  private groupIdx = 0;
  private projectIdx = 0;

  constructor(private results, private byRow = 4) {
    this.updateSelected();
  }

  moveLeft() {
    if (this.isFirstProject() && !this.isFirstGroup()) {
      this.groupIdx--;
      this.projectIdx = this.getLastProject();
    } else if (!this.isFirstProject()) {
      this.projectIdx--;
    }
    this.updateSelected();
  }

  moveRight() {
    if (this.isLastProject() && !this.isLastGroup()) {
      this.groupIdx++;
      this.projectIdx = 0;
    } else if (!this.isLastProject()) {
      this.projectIdx++;
    }
    this.updateSelected();
  }

  moveUp() {
    if (this.hasProjectAtDelta(-this.byRow)) {
      this.projectIdx -= this.byRow;
    } else if (!this.isFirstGroup()) {
      this.groupIdx--;
      let nextProjectIdx = this.getLastProject();
      const currentExceed = this.projectIdx % 4;
      const nextExceed = nextProjectIdx % this.byRow;
      if (currentExceed < nextExceed) {
        nextProjectIdx -= nextExceed - currentExceed;
      }
      this.projectIdx = nextProjectIdx;
    }
    this.updateSelected();
  }

  moveDown() {
    if (this.hasProjectAtDelta(+this.byRow)) {
      this.projectIdx += this.byRow;
    } else if (!this.isLastGroup()) {
      this.groupIdx++;
      const currentExceed = this.projectIdx % 4;
      this.projectIdx = Math.min(currentExceed, this.getLastProject());
    }
    this.updateSelected();
  }

  private updateSelected() {
    if (!this.results.length) return null;

    this.selected = this.results[this.groupIdx][1][this.projectIdx];
  }

  private isFirstGroup(): boolean {
    return this.groupIdx === 0;
  }

  private isLastGroup(): boolean {
    return this.groupIdx === this.results.length - 1;
  }

  private isFirstProject(): boolean {
    return this.projectIdx === 0;
  }

  private isLastProject(): boolean {
    return this.projectIdx === this.getLastProject();
  }

  private hasProjectAtDelta(delta: number): boolean {
    if (delta >= 0) {
      return this.projectIdx + delta <= this.getLastProject();
    } else {
      return this.projectIdx + delta >= 0;
    }
  }

  private getLastProject(): number {
    return this.results[this.groupIdx][1].length - 1;
  }
}
