import { Pipe, PipeTransform } from '@angular/core';

import { STATUS_ICON, STATUS_TEXT } from './data-tables.model';

@Pipe({
  name: 'statusIcon',
})
export class StatusIconPipe implements PipeTransform {
  /**
   * Case study (by priority):
   *  1. future -> cloud_done
   *  2. live -> cloud_done
   *  3. expired -> cloud_off
   *  4. active -> check
   *  5. error -> error
   */
  transform(status: string): string {
    return STATUS_ICON[status.toLowerCase()];
  }
}

@Pipe({
  name: 'statusLabel',
})
export class StatusLabelPipe implements PipeTransform {
  /**
   * Case study (by priority):
   *  1. live -> Live
   *  2. future -> Future
   *  3. expired -> Expired
   *  4. active -> Active
   */
  transform(status: string): string {
    return STATUS_TEXT[status.toLowerCase()];
  }
}
