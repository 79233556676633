import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sp-caret-handler',
  templateUrl: './caret-handler.component.html',
  styleUrls: ['./caret-handler.component.scss'],
})
export class CaretHandlerComponent {
  @Input() target: HTMLInputElement;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  display = false;

  handleSelection({ emoji }: { emoji: any }): void {
    const offset = this.target.selectionStart;
    const message = this.target.value;

    // update component value
    this.onChange.emit(
      message.slice(0, offset) + emoji.native + message.slice(offset),
    );
  }

  show() {
    this.display = true;
  }

  hide() {
    this.display = false;
  }
}
