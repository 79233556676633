import { AbstractControl } from '@angular/forms';

export type FieldErrorId = string;
export type FieldErrorMessage = string;
export type FieldErrorHandler = (
  error: any,
  control: AbstractControl,
) => string;
export type FieldErrorResolver = FieldErrorMessage | FieldErrorHandler;

export type FieldError = [FieldErrorId, FieldErrorResolver];
export type FieldErrors = FieldError[];

export const defaultFieldErrors: FieldErrors = [
  ['required', 'Value is required'],
  ['pattern', ({ requiredPattern }) => `Should match ${requiredPattern}`],
  ['minlength', ({ requiredLength }) => `Min length is ${requiredLength}`],
  ['maxlength', ({ requiredLength }) => `Max length is ${requiredLength}`],
];
