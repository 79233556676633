import { Component, Input, OnChanges } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { NavigationService } from '@app/shared/navigation/navigation.service';
import { Tool, Sidenav } from '@app/shared/navigation/navigation.model';
import {
  isVisibleInConfig,
  mainToolsConfig,
  ToolVisibility,
} from '@app/shared/sidenav-viewer/sidenav-viewer.model';
import { ProjectService } from '@app/providers/projects';
import { EmailPipe } from '@app/shared/data-tables/data-tables-email.pipe';
import { AuthService } from '@app/providers/auth/auth.service';

@Component({
  selector: 'sp-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.scss'],
})
export class NavigationComponent implements OnChanges {
  @Input('sidenav') sidenav: MatSidenav;
  @Input() sidenavData: Sidenav;

  private user: string;
  private project: string;
  private toolsConfig: { [key: string]: ToolVisibility };

  constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
    private emailPipe: EmailPipe,
    private projectService: ProjectService,
  ) {
    this.authService.profile$.subscribe(
      ({ email }: { email: string }) =>
        (this.user = this.emailPipe.transform(email)),
    );
    this.projectService.project$.subscribe(
      ({ short_name }: { short_name: string }) => (this.project = short_name),
    );
  }

  ngOnChanges() {
    const { maintool } = this.sidenavData;
    if (
      mainToolsConfig[maintool.title] &&
      mainToolsConfig[maintool.title].tools
    ) {
      this.toolsConfig = mainToolsConfig[maintool.title].tools;
    } else {
      this.toolsConfig = {};
    }
  }

  isActive(tool: Tool): boolean {
    return this.navigationService.isActiveTool(tool);
  }

  onlyVisibleTools(): Function {
    return ({ title }: Tool) =>
      isVisibleInConfig(this.toolsConfig[title], this.project, this.user);
  }
}
