import { Routes } from '@angular/router';

import { GithubCheckerPlugin } from '@app/providers/checker';
import { getProjectRepository } from '@app/providers/projects';

export const configManagerRoutes: Routes = [
  {
    path: 'config-manager',
    data: {
      mainTool: 'cm',
      title: 'Config Manager',
      checks: [
        GithubCheckerPlugin.Checks({
          repo: (params) => getProjectRepository(params),
          permissions: ['pull', 'push'],
        }),
      ],
    },
    children: [
      {
        path: '',
        redirectTo: 'editor',
        pathMatch: 'full',
      },
      // Lazy modules
      {
        path: 'editor',
        loadChildren: () =>
          import('@app/config-manager/editor/editor.module').then(
            (m) => m.EditorModule,
          ),
        data: {
          tool: 'editor',
          title: 'Editor',
        },
      },
      {
        path: 'environment',
        loadChildren: () =>
          import('@app/config-manager/environment/cm-environment.module').then(
            (m) => m.CMEnvironmentModule,
          ),
        data: {
          tool: 'environment',
          title: 'Environment',
        },
      },
      {
        path: 'branch',
        loadChildren: () =>
          import('@app/config-manager/branch/branch.module').then(
            (m) => m.BranchModule,
          ),
        data: {
          tool: 'branch',
          title: 'Branch Manager',
        },
      },
      {
        path: 'validator',
        loadChildren: () =>
          import('@app/config-manager/validator/validator.module').then(
            (m) => m.ValidatorModule,
          ),
        data: {
          tool: 'validator',
          title: 'Config Validator',
        },
      },
      {
        path: 'merge',
        loadChildren: () =>
          import('@app/config-manager/merge/merge.module').then(
            (m) => m.MergeModule,
          ),
        data: {
          tool: 'merge',
          title: 'Merge',
        },
      },
    ],
  },
];
