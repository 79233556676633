import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { NavigationService } from '@app/shared/navigation/navigation.service';
import { Tool, Sidenav } from '@app/shared/navigation/navigation.model';
import { AuthService } from '@app/providers/auth/auth.service';
import { EmailPipe } from '@app/shared/data-tables/data-tables-email.pipe';
import { ProjectService } from '@app/providers/projects';
import { isVisibleInConfig, mainToolsConfig } from './sidenav-viewer.model';

@Component({
  selector: 'sidenav-viewer',
  templateUrl: 'sidenav-viewer.component.html',
  styleUrls: ['sidenav-viewer.component.scss'],
  providers: [EmailPipe],
})
export class SidenavViewerComponent {
  @Input('tools') sidenavTools: Sidenav[];
  @Input('sidenav') sidenav: MatSidenav;

  private user: string;
  private project: string;

  constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
    private emailPipe: EmailPipe,
    private projectService: ProjectService,
  ) {
    this.authService.profile$.subscribe(
      ({ email }: { email: string }) =>
        (this.user = this.emailPipe.transform(email)),
    );
    this.projectService.project$.subscribe(
      ({ short_name }: { short_name: string }) => (this.project = short_name),
    );
  }

  onlyVisibleSidenavTools(): Function {
    return ({ maintool }: Sidenav) =>
      isVisibleInConfig(
        mainToolsConfig[maintool.title],
        this.project,
        this.user,
      );
  }

  onlyVisibleTools({ maintool }: Sidenav): Function {
    if (
      !mainToolsConfig[maintool.title] ||
      !mainToolsConfig[maintool.title].tools
    ) {
      return () => true;
    }

    return ({ title }: Tool) =>
      isVisibleInConfig(
        mainToolsConfig[maintool.title].tools[title],
        this.project,
        this.user,
      );
  }

  isActiveMainTool(sidenav: Sidenav): boolean {
    return this.navigationService.isMainActiveTool(sidenav);
  }

  isActive(tool: Tool): boolean {
    return this.navigationService.isActiveTool(tool);
  }
}
