<div>
  <div class="toolbar">
    <mat-form-field floatLabel="always">
      <mat-label>Environments</mat-label>
      <input
        #inputRef
        matInput
        placeholder="Search"
        (input)="onChangeFilter(inputRef.value)"
      />
    </mat-form-field>
    <button
      class="btn"
      mat-button
      color="primary"
      type="button"
      (click)="clearAll()"
    >
      Clear all
    </button>
  </div>
</div>
<mat-list>
  <mat-list-item *ngFor="let item of filteredItems | async">
    <mat-checkbox
      matListItemIcon
      [checked]="getIsChecked(item)"
      [disabled]="item.disabled"
      (change)="onChange($event, item)"
    ></mat-checkbox>
    <mat-label>{{ getOptionDisplay(item) }}</mat-label>
  </mat-list-item>
</mat-list>
<mat-error *ngIf="error">{{ error }}</mat-error>
