<div class="loader">
  <mat-progress-bar *ngIf="loader" mode="indeterminate"></mat-progress-bar>
</div>
<div class="content-toolbar">
  <div class="content-left">
    <h3>
      <ng-content select=".tool-title"></ng-content>
    </h3>
    <ng-content select="sp-tool-header-info"></ng-content>
    <ng-content select=".after-header-info"></ng-content>
    <div class="selectors">
      <ng-content select=".selector"></ng-content>
    </div>
    <div class="tool-btns">
      <ng-content select=".tool-btn"></ng-content>
    </div>
  </div>
  <div class="content-right">
    <div class="btns">
      <ng-content select=".btn"></ng-content>
      <ng-content select="sp-filter-btn"></ng-content>
      <div class="toggle-btn">
        <ng-content select="sp-toggle-column"></ng-content>
      </div>
    </div>
  </div>
</div>
