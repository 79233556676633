<div>
  <a [href]="service.getURL()" [matTooltip]="service.getName()" target="_blank"
    ><span *ngIf="service.getIcon() !== ''; else imageTmpl">{{
      service.getIcon()
    }}</span></a
  >
</div>
<ng-template #imageTmpl>
  &nbsp;<img
    *ngIf="service.getImage()"
    [src]="service.getImage()"
    width="auto"
    height="25"
  />
</ng-template>
