import {
  Input,
  Directive,
  ElementRef,
  Renderer2,
  Optional,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[spOverlayButton]',
})
export class OverlayButtonDirective implements OnChanges {
  @Input() spOverlayButton = false;
  @Input() overlay: HTMLElement;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    @Optional() private tooltip: MatTooltip,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.spOverlayButton.currentValue) {
      if (this.tooltip) {
        this.tooltip.show();
      }
      this.renderer.addClass(this.el.nativeElement, 'header-overlay-item');
      this.renderer.addClass(this.overlay, 'header-overlay');
    } else {
      if (this.tooltip) {
        this.tooltip.hide();
      }
      this.renderer.removeClass(this.el.nativeElement, 'header-overlay-item');
      this.renderer.removeClass(this.overlay, 'header-overlay');
    }
  }
}
