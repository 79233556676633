export class RegExpMorph {
  private static regExpSpecialChars = /[\\^$.*+?()[\]{}|]/g;

  static listOfDomains(list: string[] = [], fallback: RegExp): RegExp {
    if (!list.length) {
      return fallback;
    }
    const escapedList = list
      .map((d) => this.escapeRegExpChars(d))
      .map((d) => this.replaceMagicStrings(d));
    return new RegExp(`//(${escapedList.join('|')})`, 'i');
  }

  static escapeRegExpChars(str: string): string {
    return str.replace(this.regExpSpecialChars, '\\$&');
  }

  static replaceMagicStrings(str: string): string {
    return str.replace('%ANY_WORD%', '\\w+');
  }
}
