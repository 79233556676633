import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SectionLink } from '@app/config-manager/shared/api.model';
import {
  Cell,
  ConfigLegacy,
  GIT_ID_HEADER,
  MetadataCell,
  MetadataCellLegacy,
  MetadataExcludedLegacy,
  UNDEFINED_ULID_VALUE,
  ValidationType,
} from '@app/config-manager/editor/editor.model';
import {
  css2rgb,
  getColumnNames,
  getColumnsDiff,
  ProjectFeatures,
  serializeCell,
} from '@app/config-manager/shared/sync.model';
import { ConfigManagerService } from '@app/config-manager/shared/api.service';
import { GoogleSheetsService } from '@app/providers/google/sheets/google-sheets.service';
import {
  ReportSectionItem,
  ReportStatus,
  ReportType,
} from '@app/config-manager/shared/validation-check/validation-check.model';
import { ProjectService } from '@app/providers/projects';
import { EditorService } from '@app/config-manager/editor/editor.service';
import { GapiSheets } from '@app/providers/google/sheets/google-sheets.model';
import { ConfigDataHelper } from '@app/config-manager/shared/config-data-helper';

declare let kendo: any;

@Injectable()
export class ConfigManagerSyncService {
  static BSID_PROTECTED_RANGE_DESCRIPTION = 'Git Id header';
  private ignoredColumnsProjects = new Set(['ca']);
  private ignoredMetadataProjects = new Set(['mc']);
  private sectionLinks: Map<string, SectionLink> = new Map();

  /**
   * Merge local with remote data using the local validations
   *
   * @param {ConfigLegacy} local
   * @param {ConfigLegacy} remote
   * @param {boolean} ignoreColumns
   * @returns {object[]}
   */
  static getMergedData(
    local: ConfigLegacy,
    remote: ConfigLegacy,
    ignoreColumns = false,
  ): object[] {
    const maxRows = !ignoreColumns
      ? remote.data.length
      : Math.max(local.data.length, remote.data.length);
    const localColumns = Object.keys(local.data[0]);

    const validations: Map<string, ValidationType> = (
      local.metadata.columns || []
    )
      .map(({ name, position, validation }) => ({
        name: name || localColumns[position],
        validation,
      }))
      .filter((col) => col.name != null)
      .reduce((acc, col) => acc.set(col.name, col.validation), new Map());

    const dataTemplate = {};
    localColumns.forEach((col) => (dataTemplate[col] = null));

    const data = [];
    for (let row = 0; row < maxRows; row++) {
      data.push(Object.assign({}, dataTemplate));

      localColumns.forEach((col) => {
        if (remote.data[row] && col in remote.data[row]) {
          // If column in remote data parseValue with local validation type
          if (remote.data[row][col] != null) {
            data[row][col] = EditorService.parseValue(
              remote.data[row][col],
              validations.get(col),
            );
          }
        } else if (local.data[row] && col in local.data[row]) {
          // If column not in remote but in local, use it
          if (local.data[row][col] != null) {
            data[row][col] = local.data[row][col];
          }
        }
      });
    }

    return data;
  }

  /**
   * Merge local with remote metadata.cells, removing the local (color cells before)
   *
   * @param {ConfigLegacy} local
   * @param {ConfigLegacy} remote
   * @returns {MetadataCellLegacy[]}
   */
  static getMergedMetaCells(
    local: ConfigLegacy,
    remote: ConfigLegacy,
  ): MetadataCellLegacy[] {
    const metaCells = new Map<string, any>();

    // Keep Api metaCells but (color) field
    local.metadata.cells
      .map((cell) => delete cell.color && cell)
      .filter((cell) => Object.keys(cell).length > 2)
      .forEach((cell) => metaCells.set(`${cell.row}_${cell.col}`, cell));

    // Merge Drive metaCells containing (color) field
    remote.metadata.cells.forEach((cell) => {
      const key = `${cell.row}_${cell.col}`;
      metaCells.set(key, Object.assign({}, metaCells.get(key), cell));
    });

    return Array.from(metaCells.values());
  }

  /**
   * Merge local with remote metadata.excluded, parsing the data values with default behaviour
   *
   * @param {ConfigLegacy} local
   * @param {ConfigLegacy} remote
   * @returns {MetadataExcludedLegacy[]}
   */
  static getMergedMetaExcluded(
    local: ConfigLegacy,
    remote: ConfigLegacy,
  ): MetadataExcludedLegacy[] {
    const metaExcludedMap = new Map();
    const metaExcluded = local.metadata.excluded.map((item) => {
      const excluded = Object.assign({}, item);
      metaExcludedMap.set(item.name, excluded);
      return excluded;
    });

    remote.metadata.excluded
      .filter((item) => metaExcludedMap.has(item.name))
      .forEach((item) => {
        metaExcludedMap.get(item.name).data = item.data.map((value) =>
          EditorService.parseValue(value, null),
        );
      });

    return metaExcluded;
  }

  /**
   * Merge local with remote configs
   *
   * @param {ConfigLegacy} local
   * @param {ConfigLegacy} remote
   * @param {boolean} ignoreColumns
   * @returns {ConfigLegacy}
   */
  static mergeSheetData(
    local: ConfigLegacy,
    remote: ConfigLegacy,
    ignoreColumns = false,
  ): ConfigLegacy {
    if (!local.metadata) local.metadata = {};
    if (!local.metadata.cells) local.metadata.cells = [];

    if (!ignoreColumns) {
      // Check local columns found (only if not ignored columns in the current project)
      const apiColumns = getColumnNames(local);
      const driveColumns = getColumnNames(remote);

      const columnsDiff = getColumnsDiff(apiColumns, driveColumns);
      if (columnsDiff.length) {
        throw new Error(
          `Local columns (${columnsDiff.join(', ')}) not found in drive data`,
        );
      }
    }

    const mergedConfig: ConfigLegacy = {
      data: [],
      metadata: Object.assign({}, local.metadata),
    };

    mergedConfig.data = this.getMergedData(local, remote, ignoreColumns);
    mergedConfig.metadata.cells = this.getMergedMetaCells(local, remote);

    if (mergedConfig.metadata.excluded && local.metadata.excluded.length) {
      mergedConfig.metadata.excluded = this.getMergedMetaExcluded(
        local,
        remote,
      );
    }

    return mergedConfig;
  }

  /**
   * Map a Drive.Spreadsheet.RowData[] to Api.ConfigLegacy
   */
  static mapSheetToSection(
    sheet: GapiSheets.RowData[],
    ignoreMetadata = false,
  ): ConfigLegacy {
    const data: ConfigLegacy = {
      data: [],
      metadata: {
        cells: [],
      },
    };

    const configData = ConfigDataHelper.create();

    GoogleSheetsService.forEachCell(sheet, serializeCell, (row, col, cell) => {
      const dataRow = row - 1;

      // Store background metadata
      if (!ignoreMetadata && cell.background) {
        data.metadata.cells.push({
          row: dataRow,
          col,
          color: cell.background.substr(1),
        });
      }

      if (!row) {
        configData.addColumnHeader(col, cell.value);
      } else {
        configData.addRowColumnData(dataRow, col, cell.value);
      }
    });

    data.metadata.excluded = configData.excluded;
    data.data = configData.data;

    return data;
  }

  /**
   * Adds missing requirements for git projects:
   * - The git Id header
   * - Fill the missing Ids of the header
   *
   * @param {ConfigLegacy} data
   * @returns {ConfigLegacy}
   */
  static addMissingGitData(data: ConfigLegacy): ConfigLegacy {
    data.data = data.data.map((row) =>
      Object.assign({ [GIT_ID_HEADER]: UNDEFINED_ULID_VALUE }, row),
    );

    const values = EditorService.calculateMissingUlids(
      data.data.map((row) => row[GIT_ID_HEADER] || UNDEFINED_ULID_VALUE),
    );

    values.forEach((value: string, index: number) => {
      data.data[index][GIT_ID_HEADER] = value;
    });

    return data;
  }

  static getExtendedValue(value: any): GapiSheets.ExtendedValue {
    if (value instanceof Date) {
      // Export Dates as number for GDrive
      value = kendo.spreadsheet.dateToNumber(value);
    } else if (value === null) {
      // Export null value as empty string
      value = '';
    }

    switch (typeof value) {
      case 'number':
        return {
          numberValue: value,
        };
      case 'boolean':
        return {
          boolValue: value,
        };
      default:
        return {
          stringValue: value,
        };
    }
  }

  static getCellFormat(
    metaCell: Partial<MetadataCell>,
    validation?: ValidationType,
  ): GapiSheets.CellFormat {
    const format: GapiSheets.CellFormat = {};
    if (metaCell && metaCell.background) {
      format.backgroundColor = css2rgb(metaCell.background);
    }

    // Export Dates with the correct numberFormat and pattern
    if (validation === ValidationType.date) {
      format.numberFormat = {
        type: GapiSheets.NumberFormatType.date,
        pattern: 'yyyy-mm-dd',
      };
    }

    return format;
  }

  static mapSectionToSheet(
    section: string,
    data: ConfigLegacy,
  ): GapiSheets.Sheet {
    const metadata = EditorService.getSectionMetadata(data, section);
    const dataJSON = EditorService.parseSectionData(data, section);
    EditorService.prepareSectionColumnsValues(dataJSON, metadata);
    EditorService.appendSectionExcludedColumns(dataJSON, metadata);

    const headers = dataJSON.rows.length
      ? dataJSON.rows[0].cells.map((cell) => cell.value)
      : [];

    // Convert kendo.spreadsheet data into drive spreadsheet.sheet data
    const rowData: GapiSheets.RowData[] = dataJSON.rows.map(
      ({ cells }: { cells: Cell[] }, rowId: number) => ({
        values: cells.map(({ value }: Cell, colId: number) => {
          const metaCell = metadata.getCell(rowId, colId);
          const validation = rowId
            ? metadata.columns.get(headers[colId])
            : null;

          return {
            userEnteredValue: this.getExtendedValue(value),
            userEnteredFormat: this.getCellFormat(metaCell, validation),
          };
        }),
      }),
    );

    // Protect the gitIdHeader column always
    const protectedRanges: GapiSheets.ProtectedRange[] = [];
    if (headers.includes(GIT_ID_HEADER)) {
      const gitHeaderIndex = headers.findIndex(
        (header) => header === GIT_ID_HEADER,
      );
      protectedRanges.push({
        description: this.BSID_PROTECTED_RANGE_DESCRIPTION,
        warningOnly: true,
        range: {
          startColumnIndex: gitHeaderIndex,
          endColumnIndex: gitHeaderIndex + 1,
        },
      });
    }

    return {
      properties: {
        title: section,
      },
      protectedRanges,
      data: [{ rowData }],
    };
  }

  constructor(
    private projectService: ProjectService,
    private api: ConfigManagerService,
    private gapiSheetsService: GoogleSheetsService,
  ) {}

  getProjectFeatures(): ProjectFeatures {
    const project = this.projectService.getProject()['short_name'];

    return {
      ignoreColumns: this.ignoredColumnsProjects.has(project),
      ignoreMetadata: this.ignoredMetadataProjects.has(project),
    };
  }

  /**
   * Inner Method: Get config links
   *
   * @param {string} branch
   * @param {string} config
   * @returns {Observable<SectionLink[]>}
   */
  getConfigLinks(branch: string, config: string): Observable<SectionLink[]> {
    return this.api
      .getLinks(branch, config)
      .pipe(tap(this.storeConfigLinksPipe(branch, config)));
  }

  /**
   * Inner Method: Get section link
   *
   * @param {string} branch
   * @param {string} config
   * @param {string} section
   * @returns {Observable<SectionLink>}
   */
  getSectionLink(
    branch: string,
    config: string,
    section: string,
  ): Observable<SectionLink> {
    const sectionKey = this.cacheKey(branch, config, section);

    if (this.sectionLinks.has(sectionKey)) {
      return of(this.sectionLinks.get(sectionKey));
    }

    return this.getConfigLinks(branch, config).pipe(
      map((links: SectionLink[]) => {
        const link = links.find((l) => l.name === section);
        if (!link) throw Error('This section is not linked');

        return link;
      }),
    );
  }

  /**
   * Get sections list
   *
   * @param {string} branch
   * @param {string} config
   * @returns {Observable<string[]>}
   */
  getSections(branch: string, config: string): Observable<string[]> {
    return this.getConfigLinks(branch, config).pipe(
      map((links: SectionLink[]) => links.map((link) => link.name)),
    );
  }

  /**
   * Get section data
   *
   * @param {string} branch
   * @param {string} config
   * @param {string} section
   * @returns {Observable<ConfigLegacy>}
   */
  getSection(
    branch: string,
    config: string,
    section: string,
  ): Observable<ConfigLegacy> {
    const { ignoreColumns, ignoreMetadata } = this.getProjectFeatures();

    const apiSection$ = this.api.getSection(branch, config, section);
    const driveSection$ = this.getSectionLink(branch, config, section).pipe(
      switchMap((link: SectionLink) => this.fetchDriveSheet(link)),
      map((sheet: GapiSheets.RowData[]) =>
        ConfigManagerSyncService.mapSheetToSection(sheet, ignoreMetadata),
      ),
      map((data: ConfigLegacy) =>
        this.api.isGitProject
          ? ConfigManagerSyncService.addMissingGitData(data)
          : data,
      ),
    );

    return forkJoin([apiSection$, driveSection$]).pipe(
      map(([apiData, driveData]: [ConfigLegacy, ConfigLegacy]) =>
        ConfigManagerSyncService.mergeSheetData(
          apiData,
          driveData,
          ignoreColumns,
        ),
      ),
      catchError((error) =>
        throwError([
          new ReportSectionItem({
            type: ReportType.section,
            message: error.message,
            config,
            section,
            status: ReportStatus.error,
          }),
        ]),
      ),
    );
  }

  createSpreadsheet(
    branch: string,
    config: string,
  ): Observable<GapiSheets.Spreadsheet> {
    return this.api.getSections(branch, config).pipe(
      switchMap((sections: string[]) =>
        forkJoin(
          ...sections.map((section) =>
            this.getSectionAsSheet(branch, config, section),
          ),
        ),
      ),
      map(
        (sheets: GapiSheets.Sheet[]): GapiSheets.Spreadsheet => ({
          properties: {
            title: config,
          },
          sheets,
        }),
      ),
      switchMap((spreadsheet: GapiSheets.Spreadsheet) =>
        from(
          this.gapiSheetsService
            .createSpreadsheet(spreadsheet)
            .then((response: GapiSheets.Spreadsheet) => {
              const dataItems = response.sheets.map((sheet, index) => ({
                id: response.spreadsheetId,
                gid: sheet.properties.sheetId,
                sheet: spreadsheet.sheets[index],
              }));

              return Promise.all(
                dataItems.map((item) =>
                  this.gapiSheetsService.createOrUpdateSheetProtectionsRequest(
                    item.id,
                    item.gid,
                    item.sheet,
                    true,
                  ),
                ),
              ).then(() => response);
            }),
        ),
      ),
    );
  }

  getSectionAsSheet(
    branch: string,
    config: string,
    section: string,
  ): Observable<GapiSheets.Sheet> {
    return this.api
      .getSection(branch, config, section)
      .pipe(
        map((data) =>
          ConfigManagerSyncService.mapSectionToSheet(section, data),
        ),
      );
  }

  private cacheKey(branch: string, config: string, section: string) {
    return `${branch}/${config}/${section}`;
  }

  private storeConfigLinksPipe(branch: string, config: string) {
    return (links: SectionLink[]) =>
      links.forEach((link) =>
        this.sectionLinks.set(this.cacheKey(branch, config, link.name), link),
      );
  }

  private fetchDriveSheet(link: SectionLink): Observable<GapiSheets.RowData[]> {
    const fields =
      'formattedValue,effectiveValue,effectiveFormat.backgroundColor';
    return from(
      this.gapiSheetsService.getSheetValues(link.id, link.gid, fields),
    );
  }
}
