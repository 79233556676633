import { Project } from '@app/providers/projects';
import { mixedGroupFn, mixedOrder } from './plugin/mixed';

export enum ProjectGroupId {
  studios = 'studios',
  names = 'names',
  recent = 'recent',
  favorites = 'favorites',
  mixed = 'mixed',
}

export type ResultGroup = [string, Project[]];

export class ProjectGroup {
  constructor(
    public id: ProjectGroupId,
    public groupFn: (p: Project) => string,
    public sortFn?: (a: any, b: any) => number,
  ) {}

  groupProjects(projects: Project[]): ResultGroup[] {
    const projectsByGroupKey = projects.reduce((acc, project) => {
      const key = this.groupFn(project);

      if (acc.has(key)) {
        acc.get(key).push(project);
      } else {
        acc.set(key, [project]);
      }

      return acc;
    }, new Map());

    const results = Array.from(projectsByGroupKey.entries());

    return this.sortFn ? results.sort(this.sortFn) : results;
  }
}

export function sortWithList(list: string[]): (a, b) => number {
  return (a, b) => list.indexOf(a[0]) - list.indexOf(b[0]);
}

export const projectGroups: ProjectGroup[] = [
  new ProjectGroup(
    ProjectGroupId.mixed,
    mixedGroupFn,
    sortWithList(mixedOrder),
  ),
  // new ProjectGroup(ProjectGroupId.recent, recentGroupFn, sortWithList(recentOrder)),
  // new ProjectGroup(ProjectGroupId.favorites, favoritesGroupFn, sortWithList(favoriteOrder)),
  // new ProjectGroup(ProjectGroupId.studios, studioGroupFn, sortWithList(studioOrder)),
];
