import { Observable } from 'rxjs';
import { ConfigLegacy } from '@app/config-manager/editor/editor.model';

export enum ReportStatus {
  ok = 'ok',
  warn = 'warn',
  error = 'error',
}

export enum ReportType {
  global = 'global',
  config = 'config',
  section = 'section',
}

export enum DataOrigin {
  configManager = 'cm',
  googleDrive = 'sync',
}

export const reportStatusIcon = {
  [ReportStatus.ok]: 'check_circle',
  [ReportStatus.warn]: 'warning',
  [ReportStatus.error]: 'cancel',
};

export type AnyReportItem =
  | ReportGlobalItem
  | ReportConfigItem
  | ReportSectionItem;

export class ReportSummary {
  [ReportStatus.ok]: Set<string> = new Set();
  [ReportStatus.warn]: Set<string> = new Set();
  [ReportStatus.error]: Set<string> = new Set();
}

// -> Mixins

class Statusable {
  icon = '';
  status: ReportStatus = ReportStatus.ok;

  static calculateStatus(data: Statusable[], mapFn = (item) => item.status) {
    const statusBag = new Set(data.map(mapFn));

    if (statusBag.has(ReportStatus.error)) {
      return ReportStatus.error;
    } else if (statusBag.has(ReportStatus.warn)) {
      return ReportStatus.warn;
    }

    return ReportStatus.ok;
  }

  constructor(data: { status?: ReportStatus } = {}) {
    if (data.status) this.status = data.status;
  }

  protected updateIcon() {
    this.icon = reportStatusIcon[this.status];
  }
}

// -> Report classes for grouping report items & status

export class ReportGlobal extends Statusable {
  reports: ReportGlobalItem[] = [];
  configs: Map<string, ReportConfig> = new Map();

  configsAsArray?: ReportConfig[] = [];
  completed? = false;

  constructor() {
    super();
  }

  updateStatus() {
    this.status = Statusable.calculateStatus(this.reports);
    this.updateIcon();
  }

  complete() {
    this.updateStatus();
    this.configsAsArray = Array.from(this.configs.values());
    this.completed = true;
  }
}

export class ReportConfig extends Statusable {
  name: string;
  description: string;
  reports?: ReportConfigItem[] = [];
  sections?: Map<string, ReportSection> = new Map();

  sectionsAsArray?: ReportSection[] = [];
  onComplete?: Function[] = [];
  completed? = false;

  constructor(data: Config) {
    super();

    this.name = data.name;
    this.description = data.description;
  }

  updateStatus() {
    this.sections.forEach((item) => item.updateStatus());
    this.status = Statusable.calculateStatus(
      Array.from<Statusable>(this.sections.values()).concat(this.reports),
    );
    this.updateIcon();
  }

  complete() {
    this.updateStatus();
    this.sectionsAsArray = Array.from(this.sections.values());
    this.completed = true;
    this.onComplete.forEach((fn) => fn(this));
  }
}

export class ReportSection extends Statusable {
  name: string;
  reports?: ReportSectionItem[] = [];

  constructor(data: Section) {
    super();

    this.name = data.name;
  }

  updateStatus() {
    this.status = Statusable.calculateStatus(this.reports);
    this.updateIcon();
  }
}

export class ReportCollection {
  private _progress: Set<string> = new Set();

  summary = new ReportSummary();
  global: ReportGlobal = new ReportGlobal();
  isValidating = false;
  progress = 0;
  onComplete?: Function[] = [];

  private hasConfig(config: string): boolean {
    return this.global.configs.has(config);
  }

  private getConfigSection(config: string, section: string) {
    let reportSection = this.global.configs.get(config).sections.get(section);

    if (!reportSection) {
      reportSection = new ReportSection({ name: section });
      this.global.configs.get(config).sections.set(section, reportSection);
    }

    return reportSection;
  }

  private updateProgress() {
    this.progress = (this._progress.size / this.global.configs.size) * 100;
    this.isValidating = this.progress < 100;
    if (!this.isValidating) {
      this.onComplete.forEach((fn) => fn(this));
    }
  }

  setupConfig(config: Config) {
    this.isValidating = true;
    let reportConfig = this.global.configs.get(config.name);

    if (!reportConfig) {
      reportConfig = new ReportConfig(config);
      this.global.configs.set(config.name, reportConfig);

      reportConfig.onComplete.push((currentConfig) => {
        this._progress.add(currentConfig.name);
        this.summary[currentConfig.status].add(currentConfig.name);
        this.updateProgress();
      });
    }
  }

  addReports(...reports: AnyReportItem[]) {
    reports.forEach((report: any) => {
      /**
       * The order of the conditions is important because of the classes inheritance
       * .. ReportGlobalItem <- ReportConfigItem <- ReportSectionItem
       */
      if (report instanceof ReportSectionItem) {
        if (!this.hasConfig(report.config)) return;
        this.getConfigSection(report.config, report.section).reports.push(
          report,
        );
      } else if (report instanceof ReportConfigItem) {
        if (!this.hasConfig(report.config)) return;
        this.global.configs.get(report.config).reports.push(report);
      } else if (report instanceof ReportGlobalItem) {
        this.global.reports.push(report);
      }
    });
  }
}

// -> ReportItem classes for data & status
// @dynamic
export abstract class ReportItemFactory {
  static create(reportData: ReportItem) {
    switch (reportData.type) {
      case ReportType.global:
        return new ReportGlobalItem(reportData);
      case ReportType.config:
        return new ReportConfigItem(reportData);
      case ReportType.section:
        return new ReportSectionItem(reportData);
    }
  }

  static bulkCreate(reportArray: ReportItem[]) {
    return reportArray.map((report) => ReportItemFactory.create(report));
  }
}

export class ReportGlobalItem extends Statusable {
  type: ReportType;
  message: string;

  constructor(reportData: ReportItem) {
    super(reportData);

    this.type = reportData.type;
    this.message = reportData.message;
  }
}

export class ReportConfigItem extends ReportGlobalItem {
  config: string;

  constructor(reportData: ReportItem) {
    super(reportData);

    this.config = reportData.config;
  }
}

export class ReportSectionItem extends ReportConfigItem {
  section: string;
  column: string;
  range: string;

  constructor(reportData: ReportItem) {
    super(reportData);

    this.section = reportData.section;
    this.column = reportData.column || '';
    this.range = reportData.range || '';
  }
}

export interface ReportItem {
  type: ReportType;
  status: ReportStatus;
  message: string;
  config?: string;
  section?: string;
  column?: string;
  range?: string;
}

export interface Config {
  name: string;
  description: string;
}

export interface Section {
  name: string;
}

export interface ValidationItem {
  config: string;
  section: string;
  response: ConfigLegacy;
}

export interface SectionsApi {
  getSections(branch: string, config: string): Observable<string[]>;
  getSection(
    branch: string,
    config: string,
    section: string,
  ): Observable<ConfigLegacy>;
}
