import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Subject, Observable } from 'rxjs';
import { startWith, filter, map } from 'rxjs/operators';

interface DropdownSelect {
  value: any;
  isCustom: boolean;
}
/**
 * Dropdown Autocomplete Example
 * <sp-dropdown-autocomplete
 *   placeholder="Content"
 *   [disabled]="true"
 *   [displayFn]="displayContentFn"
 *   [options]="getOptionsArray()"
 *   [(selected)]="selectedOption"
 *   (select)="onSelectOption();"
 * ></sp-dropdown-autocomplete>
 */

@Component({
  selector: 'sp-dropdown-autocomplete',
  templateUrl: './dropdown-autocomplete.component.html',
  styleUrls: ['./dropdown-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownAutocompleteComponent),
      multi: true,
    },
  ],
})
export class DropdownAutocompleteComponent
  implements OnChanges, OnInit, ControlValueAccessor, AfterViewInit
{
  _selected: any;
  inputSubject: Subject<string> = new Subject();
  input: string;
  filteredOptions: Observable<any[]>;
  @ViewChild(MatAutocompleteTrigger, { static: true })
  menuTrigger: MatAutocompleteTrigger;

  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  @Input() customOptionFn: Function;
  @Input() emptyOnSelect = false;
  @Input() disabled = false;
  @Input() openOptions = false;
  @Input() options: Array<any>;
  @Input() placeholder: string;
  @Input() displayFn: Function = (val) => val;

  propagateChange: any = () => {};

  @Input()
  get selected() {
    return this._selected;
  }

  set selected(value) {
    if (this.customOptionFn || this.options.includes(value)) {
      const selectOptions: DropdownSelect = {
        value,
        isCustom: this.customOptionFn != null && !this.options.includes(value),
      };
      this.selectedChange.emit(value);
      this.select.emit(selectOptions);
      this._selected = value;
      this.propagateChange(value);
      this.updateResults();
      this.updateInput(value);
    }

    if (this.emptyOnSelect || !value) {
      this.updateInput();
      this._selected = null;
    }
  }

  constructor() {}

  ngOnInit() {
    this.filteredOptions = this.inputSubject.pipe(
      startWith(''),
      filter((val) => typeof val === 'string'),
      map((val) => (val ? this.filter(val) : this.options.slice())),
    );
  }

  ngAfterViewInit() {
    this.openPanel();
  }

  openPanel() {
    if (this.menuTrigger && this.openOptions) {
      this.menuTrigger.openPanel();
    }
  }

  updateOnBlur(event) {
    if (!this.customOptionFn) return;
    const value = event.currentTarget.value;
    this.update(value);
    this.writeValue(value);
  }

  update(value: string) {
    this.updateInput(value);
    this.updateResults(value);
  }

  updateInput(input: string = ''): void {
    this.input = input;
  }

  updateResults(input: string = ''): void {
    this.inputSubject.next(input);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.writeValue(changes.selected.currentValue);
    }
    if (changes.options) {
      this.options = changes.options.currentValue;
      this.updateResults();
    }
    if (changes.openOptions) {
      this.openPanel();
    }
  }

  setSelection(event: MatOptionSelectionChange, option: any): void {
    if (event.source.selected) {
      this.writeValue(option);
    }
  }

  filter(val: string): any[] {
    const value = val.toString().toLowerCase();
    return this.options.filter(
      (option) => this.displayFn(option)?.toLowerCase().indexOf(value) >= 0,
    );
  }

  writeValue(value: any) {
    if (!value) return;
    this.selected = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
