<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    #selectElement
    [multiple]="multiple"
    [required]="required"
    [ngModel]="selected"
    (selectionChange)="onChange($event)"
    (blur)="onBlur()"
    [disableOptionCentering]="disableOptionCentering"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="itemFilterCtrl"
        placeholderLabel="Search..."
        noEntriesFoundLabel="No options found"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-optgroup
      *ngFor="let groups of filteredItems | async | groupBy: groupKey"
      [label]="groups.key"
    >
      <mat-option
        *ngFor="let item of groups.values"
        [value]="getValueFromOption(item)"
        [disabled]="item.disabled"
        >{{ getOptionDisplay(item) }}</mat-option
      >
    </mat-optgroup>
  </mat-select>
  <mat-error *ngIf="error">{{ error }}</mat-error>
</mat-form-field>
