import { Component, OnInit, Input } from '@angular/core';

import { Message, tooltipMessages } from './tooltip-content.model';

@Component({
  selector: 'sp-tooltip-msg',
  templateUrl: 'tooltip-content.component.html',
  styleUrls: ['tooltip-content.component.scss'],
})
export class TooltipContentComponent implements OnInit {
  tooltipText: string;

  @Input() message: Message;
  @Input('position') position = 'below';
  @Input('showDelay') showDelay = 0;
  @Input('hideDelay') hideDelay = 0;
  @Input('disabled') disabled = false;

  constructor() {}

  ngOnInit() {
    this.tooltipText = tooltipMessages.get(this.message);
  }
}
