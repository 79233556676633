import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ProjectAllResolver, ProjectResolver } from '@app/providers/projects';
import { LoginModule, loginRoutes } from '@app/login/login.module';
import {
  MaintenanceModule,
  maintenanceRoutes,
} from '@app/maintenance/maintenance.module';
import { routerConfig } from '@env/environment';
import { AppComponent } from './app.component';
import { homeRoutes } from '@app/home/home-routing.module';
import { lomtRoutes } from '@app/lomt/lomt-routing.module';
import { ConfigManagerModule, configManagerRoutes } from '@app/config-manager';
import { LomtModule } from '@app/lomt/lomt.module';
import {
  CallbackModule,
  callbackRoutes,
} from '@app/shared/callback/callback.module';
import { AuthGuardService } from '@app/providers/auth/auth-guard.service';
import { SessionGuardService } from '@app/providers/session/session-guard.service';

const appRoutes: Routes = [
  ...loginRoutes,
  ...maintenanceRoutes,
  {
    path: 'projects',
    component: AppComponent,
    canActivate: [AuthGuardService, SessionGuardService],
    canActivateChild: [AuthGuardService],
    resolve: {
      projects: ProjectAllResolver,
    },
    children: [
      {
        path: ':alias',
        resolve: {
          project: ProjectResolver,
        },
        children: [
          ...homeRoutes,
          {
            path: 'tools',
            children: [
              ...lomtRoutes,
              ...configManagerRoutes,
              // Lazy modules
              {
                path: 'payment',
                loadChildren: () =>
                  import(
                    '@app/payment-validation/payment-validation.module'
                  ).then((m) => m.PaymentValidationModule),
              },
              {
                path: 'push-notification',
                loadChildren: () =>
                  import(
                    '@app/push-notification/push-notification.module'
                  ).then((m) => m.PushNotificationModule),
              },
              {
                path: 'dynamic-environment',
                loadChildren: () =>
                  import(
                    '@app/dynamic-environment/dynamic-environment.module'
                  ).then((m) => m.DynamicEnvironmentModule),
              },
              {
                path: 'deploy',
                loadChildren: () =>
                  import('@app/deploy/deploy.module').then(
                    (m) => m.DeployModule,
                  ),
              },
              {
                path: 'xpromo',
                loadChildren: () =>
                  import('@app/xpromo/xpromo.module').then(
                    (m) => m.XpromoModule,
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
  ...callbackRoutes,
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'projects',
  },
];

// @dynamic
@NgModule({
  imports: [
    LoginModule,
    MaintenanceModule,
    CallbackModule,
    LomtModule,
    ConfigManagerModule,
    RouterModule.forRoot(appRoutes, routerConfig),
  ],
  exports: [RouterModule],
  providers: [PreloadAllModules, ProjectResolver, ProjectAllResolver],
})
export class AppRoutingModule {}
