import { OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function componentDestroyed(component: OnDestroy): Observable<void> {
  const modifiedComponent = component as {
    __destroy$?: Observable<void>;
    ngOnDestroy(): void;
  };

  if (modifiedComponent.__destroy$) {
    return modifiedComponent.__destroy$;
  }

  const stop$ = new ReplaySubject<void>();
  const oldNgOnDestroy = component.ngOnDestroy;

  modifiedComponent.ngOnDestroy = function () {
    if (oldNgOnDestroy) {
      oldNgOnDestroy.apply(component);
    }
    stop$.next();
    stop$.complete();
  };

  return (modifiedComponent.__destroy$ = stop$.asObservable());
}

export function untilComponentDestroyed<T>(
  component: OnDestroy,
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) =>
    source.pipe(takeUntil(componentDestroyed(component)));
}
