<div class="form-field title-header">
  <mat-icon>warning</mat-icon>
  <div>
    <h2>
      Are you sure you want to {{ action | titlecase }} current
      {{ type | titlecase }}?
    </h2>
    <h3>{{ actionText.get(action) }}</h3>
  </div>
</div>

<mat-tab-group mat-stretch-tabs="false">
  <mat-tab *ngFor="let tab of tabData">
    <ng-template mat-tab-label>
      <div [matBadge]="tab.length" matBadgeColor="warn" matBadgeOverlap="false">
        {{ tab.label }}
      </div>
    </ng-template>
    <mat-table #table [dataSource]="tab.dataSource">
      <ng-container matColumnDef="id" *ngIf="tab.columns.includes('id')">
        <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <a
            *ngIf="tab.linkFn; else noLink"
            class="cell-link"
            [routerLink]="tab.linkFn(row.id, project)"
            target="_blank"
            >{{ row.id }}</a
          >
          <ng-template #noLink>{{ row.id }}</ng-template>
        </mat-cell>
      </ng-container>
      <ng-container
        matColumnDef="status"
        *ngIf="tab.columns.includes('status')"
      >
        <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon
            matTooltip="{{ row.status | statusLabel }}"
            matTooltipPosition="after"
            [class.active]="row.status === 'live'"
          >
            <span>{{ row.status | statusIcon }}</span>
          </mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container
        matColumnDef="created_by"
        *ngIf="tab.columns.includes('created_by')"
      >
        <mat-header-cell *matHeaderCellDef> Created By</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.created_by }}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="tab.columns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tab.columns"></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="5" [showFirstLastButtons]="true"></mat-paginator>
  </mat-tab>
</mat-tab-group>

<div
  *ngIf="action === actions.delete && hasLiveDependencies"
  class="warn-message"
>
  It is mandatory to unlink dependencies with following
  {{ type | titlecase }} to be allowed to Delete.
</div>

<nav class="form-field form-buttons">
  <button mat-button (click)="cancel()">Cancel</button>
  <button
    *ngIf="data.id && hasLiveDependencies"
    mat-raised-button
    color="accent"
    (click)="fetch()"
  >
    {{ updating ? '...Refreshing' : 'Refresh' }}
  </button>
  <div [ngSwitch]="action">
    <button
      *ngSwitchCase="actions.save"
      mat-raised-button
      color="primary"
      (click)="save()"
    >
      Save
    </button>
    <button
      *ngSwitchCase="actions.delete"
      [disabled]="hasLiveDependencies"
      mat-raised-button
      color="primary"
      (click)="delete()"
    >
      Delete
    </button>
  </div>
</nav>
