import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationMessageService } from '@app/shared/notification-message/notification-message.service';
import { Api } from '@app/providers/api';
import { ABService } from '@app/lomt/ab/manager/ab-manager.service';
import { Chronos } from '@app/shared/utils';
import { STATUS_TEXT } from '@app/shared/data-tables/data-tables.model';

@Injectable()
export class DependenciesDialogService {
  constructor(private api: Api, private message: NotificationMessageService) {}

  private error(res) {
    this.message.open({ ...Api.replaceError(res) });
    return throwError(res);
  }

  hasLiveDependencies(dependencies): boolean {
    for (const key of Object.keys(dependencies)) {
      if (
        dependencies[key]?.some((value) => value.is_live) ||
        dependencies[key]?.some(
          (value) =>
            ABService.getStatus(
              Chronos.createFromServer(value.start),
              Chronos.createFromServer(value.end),
            ) === STATUS_TEXT.live.toLowerCase(),
        )
      ) {
        return true;
      }
    }
    return false;
  }

  getContentDependencies(ID: number): Observable<any> {
    return this.api
      .dispatcher('GetContentDependencies', { ID }, 'liveops')
      .pipe(catchError((res) => this.error(res)));
  }
}
