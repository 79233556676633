import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'email',
})
export class EmailPipe implements PipeTransform {
  transform(email: string): string {
    if (email.includes('@')) {
      return email.split('@')[0].toLowerCase();
    } else if (email.startsWith('sp-')) {
      return email.replace('sp-', '').replace(/-/g, '.').toLowerCase();
    } else {
      return '-';
    }
  }
}
