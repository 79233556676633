import { Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceResolver } from './maintenance.resolver';
import { MaintenanceComponent } from './maintenance.component';

export const maintenanceRoutes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    resolve: {
      maintenance: MaintenanceResolver,
    },
  },
];

@NgModule({
  imports: [CommonModule],
  declarations: [MaintenanceComponent],
  exports: [MaintenanceComponent],
  providers: [MaintenanceResolver],
})
export class MaintenanceModule {}
