export interface Translation {
  message: string;
  message_title?: string;
  language: string;
}

export class TranslationEntity implements Translation {
  message = '';
  message_title? = '';
  language = '';
}

export interface SpreadSheetReference {
  id: string;
  gid: number;
  spreadsheetMap: Map<string, string[]>;
  defaultField: string;
}

export interface EmojiTextData {
  prefix: string;
  text: string;
  suffix: string;
}

export const createTranslationComponent = (
  languageObj: any = {},
): TranslationEntity => Object.assign(new TranslationEntity(), languageObj);

export const parseTranslationsEntities = (
  translationsObj: object,
): TranslationEntity[] => {
  const translations: TranslationEntity[] = [];
  Object.keys(translationsObj).forEach((language: string) => {
    translations.push(
      createTranslationComponent({
        language: languagesMap.get(language),
        message: translationsObj[language].message,
        message_title: translationsObj[language].message_title,
      }),
    );
  });
  // return if no translations (create mode)
  if (!translations.length) return [];
  // make sure english is first in array, if not exist, create one
  const englishLanguage = translations.splice(
    translations.findIndex((tranlation) => tranlation.language === 'English'),
    1,
  );
  if (englishLanguage.length) {
    translations.unshift(englishLanguage[0]);
  } else {
    translations.unshift(
      createTranslationComponent(specificTranslation('English')),
    );
  }
  return translations;
};

export const unparseTranslationsEntities = (
  translations: TranslationEntity[],
): object => {
  const getKeyFromValueMap = (
    map: Map<string, string>,
    value: string,
  ): string[] => Array.from(map.keys()).filter((key) => value === map.get(key));
  const first = (array: string[]) => array[0];

  return Object.assign(
    {},
    ...translations.map((translation: TranslationEntity) => ({
      [getKeyFromValueMap(languagesMap, translation.language)[0]]: {
        message: translation.message,
        message_title: translation.message_title,
      },
    })),
  );
};

export const specificTranslation = (language: string): Translation => ({
  language,
  message: '',
  message_title: '',
});

export const emptyTranslation = (): Translation => ({
  language: '',
  message: '',
  message_title: '',
});

// Translation Form
export const contentFormErrors = {
  language: {
    required: 'Language is required.',
  },
  message: {
    required: 'Message is required.',
    minlength: 'Message must be at least 2 characters long.',
    translationMissing: 'Translation not found.',
  },
  message_title: {
    minlength: 'Message title must be at least 2 characters long.',
  },
};

export const languagesMap: Map<string, string> = new Map([
  ['default', 'English'],
  ['es', 'Spanish'],
  ['latam', 'Latam'],
  ['fr', 'French'],
  ['it', 'Italian'],
  ['de', 'German'],
  ['pt', 'Portuguese'],
  ['ja', 'Japanese'],
  ['ko', 'Korean'],
  ['ru', 'Russian'],
  ['tr', 'Turkish'],
  ['zh', 'Chinese'],
  ['zh_hant', 'Chinese (Traditional)'],
  ['fi', 'Finnish'],
]);

// Automatic translations
const spreadsheetMap: Map<string, string[]> = new Map([
  ['dev_en', ['English']],
  ['ln_es', ['Spanish']],
  ['ln_fr', ['French']],
  ['ln_it', ['Italian']],
  ['ln_de', ['German']],
  ['ln_pt', ['Portuguese']],
  ['ln_es-MX', ['Latam']],
  ['ln_ja', ['Japanese']],
  ['ln_ko', ['Korean']],
  ['ln_ru', ['Russian']],
  ['ln_tr', ['Turkish']],
  ['ln_zh', ['Chinese']],
  ['ln_zh_hant', ['Chinese (Traditional)']],
  ['ln_fi', ['Finnish']],
]);

const newLanguagesMap: Map<string, string[]> = new Map([
  ['English', ['English']],
  ['Spanish', ['Spanish', 'Latam']],
  ['French', ['French']],
  ['Italian', ['Italian']],
  ['German', ['German']],
  ['Br- Portuguese', ['Portuguese']],
  ['Japanese', ['Japanese']],
  ['Korean', ['Korean']],
  ['Turkish', ['Turkish']],
  ['Russian', ['Russian']],
  ['Simplified Chinese', ['Chinese']],
  ['Traditional Chinese', ['Chinese (Traditional)']],
]);

export const miscID: Map<string, any> = new Map([
  [
    'dragoncity',
    {
      id: '1HwvKqO7njRBLdMUfFr3g_yugJciIbnMwWns5Y-xzASQ',
      gid: 7,
      spreadsheetMap: newLanguagesMap,
      defaultField: 'English',
    },
  ],
  [
    'monstercity',
    {
      id: '1HwvKqO7njRBLdMUfFr3g_yugJciIbnMwWns5Y-xzASQ',
      gid: 28,
      spreadsheetMap: newLanguagesMap,
      defaultField: 'English',
    },
  ],
  [
    'restaurantcity',
    {
      id: '194fy8SPq-imOM24Mk8ERfWQBb4_hziLMmbnj7csYmPA',
      gid: 1588986694,
      defaultField: 'dev_en',
      spreadsheetMap,
    },
  ],
  [
    'chefparadise',
    {
      id: '194fy8SPq-imOM24Mk8ERfWQBb4_hziLMmbnj7csYmPA',
      gid: 2141297670,
      defaultField: 'dev_en',
      spreadsheetMap,
    },
  ],
  [
    'wordlife',
    {
      id: '1J3oH6FJoadx1OutmQD5ysMzu-odY65pv6idE7uNPRrg',
      gid: 978318748,
      defaultField: 'dev_en',
      spreadsheetMap,
    },
  ],
  [
    'demo',
    {
      id: '1GKM5UWrIiok6-IveWQtcZimZx42oAl00FzfJ0PR0dSI',
      gid: 0,
      defaultField: 'dev_en',
      spreadsheetMap,
    },
  ],
]);
