import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';

import {
  AUTH_CONFIG,
  AUTH_INTERCEPTOR_SETTINGS,
  AuthParams,
} from './auth.model';
import { AuthGuardService } from './auth-guard.service';
import { AuthInterceptor } from './auth.interceptor';

@NgModule({
  imports: [HttpClientJsonpModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {
  static forRoot(params: AuthParams): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthGuardService,
        {
          provide: AUTH_CONFIG,
          useValue: params.config,
        },
        {
          provide: AUTH_INTERCEPTOR_SETTINGS,
          useValue: params.interceptor || {},
        },
        ...params.providers,
      ],
    };
  }
}
