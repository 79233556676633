import { Project } from '@app/providers/projects';
import { Studio, studioOrder } from './studio';
import { recentProjects } from './recent';
import { favoriteProjects } from './favorites';
import { FAVORITE_PROJECTS, RECENT_PROJECTS } from './plugin.model';

export const mixedOrder = [FAVORITE_PROJECTS, RECENT_PROJECTS, ...studioOrder];

export function mixedGroupFn(project: Project): string {
  if (favoriteProjects.isFavorite(project)) return FAVORITE_PROJECTS;
  if (recentProjects.isRecent(project)) return RECENT_PROJECTS;
  return project.config.studio ? project.config.studio : Studio.testing;
}
