<mat-nav-list *ngIf="sidenavData" role="list" class="list">
  <mat-list-item class="selected-tool" role="listitem" (click)="sidenav.open()">
    <div class="tool-line-wrapper">
      <mat-icon class="tool-icon">{{ sidenavData.maintool.icon }}</mat-icon>
      <div class="tool-name">{{ sidenavData.maintool.title }}</div>
    </div>
  </mat-list-item>
  <div
    class="tool-child-menu"
    role="listitem"
    *ngFor="let tool of sidenavData.tools | filter: onlyVisibleTools()"
  >
    <a
      mat-list-item
      class="tool-child-menu-title"
      [class.active]="isActive(tool)"
      [routerLink]="
        navigationService.getLink(navigationService.selectedMainTool, tool)
      "
    >
      <div class="tool-line-wrapper">
        <mat-icon class="tool-icon">{{ tool.icon }}</mat-icon>
        <div class="tool-name">{{ tool.title }}</div>
      </div>
    </a>
    <div class="tool-child-menu-ico" *ngIf="tool?.pages?.add">
      <a
        mat-icon-button
        [routerLink]="
          navigationService.getLink(
            navigationService.selectedMainTool,
            tool,
            tool.pages.add
          )
        "
      >
        <mat-icon>add</mat-icon>
      </a>
    </div>
  </div>
</mat-nav-list>
