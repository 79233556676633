import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { GithubService } from './github.service';
import { GITHUB_INTERCEPTOR_SETTINGS } from './github.model';
import { DomainChecker, DomainCheckerSettings } from '@app/shared/utils/regexp';

@Injectable()
export class GithubInterceptor implements HttpInterceptor {
  private service: GithubService;
  private domainChecker: DomainChecker;

  constructor(
    @Inject(GITHUB_INTERCEPTOR_SETTINGS) settings: DomainCheckerSettings,
    private injector: Injector,
  ) {
    this.domainChecker = new DomainChecker(settings);
  }

  private getService(): GithubService {
    if (!this.service) {
      this.service = this.injector.get(GithubService);
    }
    return this.service;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.domainChecker.isForbidden(request.url)) {
      return next.handle(request);
    }

    if (request.url.endsWith('/github/auth')) {
      return next.handle(request);
    }

    return from(this.getService().getToken()).pipe(
      switchMap((token) =>
        next.handle(
          request.clone({
            setHeaders: {
              'X-Github-Token': token,
            },
          }),
        ),
      ),
    );
  }
}
