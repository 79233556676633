<mat-nav-list role="list" class="sidenav-list">
  <mat-list-item
    class="sidenav-item"
    role="listitem"
    *ngFor="let sidenavTool of sidenavTools | filter: onlyVisibleSidenavTools()"
  >
    <div
      *ngIf="sidenavTool.tools.length > 1; else noMenu"
      class="tool-child"
      (click)="trigger.openMenu()"
    >
      <a class="tool-btn" [class.active]="isActiveMainTool(sidenavTool)">
        <mat-icon class="tool-icon">{{ sidenavTool.maintool.icon }}</mat-icon>
        <div class="tool-name">{{ sidenavTool.maintool.title }}</div>
      </a>
      <button
        mat-icon-button
        class="tool-arrow"
        [matMenuTriggerFor]="menu"
        #trigger="matMenuTrigger"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <ng-template #noMenu>
      <a
        class="tool-child"
        [class.active]="isActiveMainTool(sidenavTool)"
        [routerLink]="
          navigationService.getLink(
            sidenavTool.maintool,
            sidenavTool.maintool.redirect
          )
        "
        (click)="sidenav.close()"
      >
        <div class="tool-btn">
          <mat-icon class="tool-icon">{{ sidenavTool.maintool.icon }}</mat-icon>
          <div class="tool-name">{{ sidenavTool.maintool.title }}</div>
        </div>
      </a>
    </ng-template>
    <mat-menu #menu="matMenu" class="menu">
      <div
        class="tool-child-menu"
        *ngFor="
          let tool of sidenavTool.tools | filter: onlyVisibleTools(sidenavTool)
        "
      >
        <a
          mat-menu-item
          class="tool-child-menu-title"
          [class.active]="isActive(tool)"
          [routerLink]="navigationService.getLink(sidenavTool.maintool, tool)"
          (click)="sidenav.close()"
        >
          {{ tool.title }}
        </a>
        <div class="tool-child-menu-ico" *ngIf="tool?.pages?.add">
          <a
            mat-icon-button
            [routerLink]="
              navigationService.getLink(
                sidenavTool.maintool,
                tool,
                tool.pages.add
              )
            "
            (click)="sidenav.close()"
          >
            <mat-icon>add</mat-icon>
          </a>
        </div>
      </div>
    </mat-menu>
  </mat-list-item>
</mat-nav-list>
