import { Component } from '@angular/core';

import { TodayService } from '@app/today/today.service';

@Component({
  selector: 'app-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.scss'],
})
export class TodayComponent {
  constructor(public service: TodayService) {}
}
