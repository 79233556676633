import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { concat, from, Observable, timer } from 'rxjs';
import {
  concatMap,
  finalize,
  map,
  mapTo,
  pairwise,
  scan,
  switchMap,
} from 'rxjs/operators';

import { ProjectService } from '@app/providers/projects';
import { RPC } from './api.model';
import { ExpireMap } from '@app/shared/expire-map';
import { environment } from '@env/environment';
import { NotificationMessage } from '@app/shared/notification-message/notification-message.model';

@Injectable()
export class Api {
  static MAX_PAGINATION_VALUE = 9999;
  static DEFAULT_CACHE_TIMEOUT = 300;
  static DEFAULT_CACHE_REPOSITORIES = new Set([
    'contents',
    'contenttypes',
    'schedules',
    'scheduletimeranges',
    'segments',
    'abtests',
    'environments',
    'usersets',
    'xpromos',
  ]);
  static DEFAULT_CACHE_MODIFY_ACTIONS = new Set(['create', 'delete', 'update']);

  private cache: ExpireMap<any> = new ExpireMap<any>();
  rpcFinish: EventEmitter<any> = new EventEmitter();

  static replaceError(response: any = {}): Partial<NotificationMessage> {
    const data: Partial<NotificationMessage> = {
      type: 'error',
      text: 'Server not responding',
    };

    if (response.statusText) {
      data.text = response.statusText;
    }

    if (response._body) {
      const tmp: any =
        typeof response._body === 'string'
          ? JSON.parse(response._body)
          : response._body;
      if (tmp.message) {
        data.text = tmp.message;
      }
    }

    return data;
  }

  static getUrl(opts: RPC) {
    let url = `${environment.apiEndpoint}/${opts.repository}`;
    if (opts.handler != null) url += `/${opts.handler}`;

    return url;
  }

  private static getRequestCacheKey(opts: RPC, filters?: HttpParams): string {
    return `${opts.repository}_${opts.handler}_${filters}`;
  }

  constructor(private http: HttpClient, private project: ProjectService) {}

  dispatcher(
    action: string,
    body: object = {},
    prefix?: string,
  ): Observable<any> {
    const url = `${environment.apiEndpoint}/${
      prefix ? prefix + '/' : ''
    }dispatcher`;
    let params = new HttpParams().set('action', action);

    params = this.getParamsWithProject(params);

    return this.http.post(url, body, { params });
  }

  rpcWithPagination<T = any>(
    initialPageSize: number,
    opts: RPC,
    data?: any,
  ): Observable<T> {
    const preloadOpts = Object.assign({}, opts);
    preloadOpts.filterBy = Object.assign({}, preloadOpts.filterBy, {
      limit: 0,
    });

    const pages$ = this.rpc(preloadOpts, data).pipe(
      map(({ total }) => Math.min(total, Api.MAX_PAGINATION_VALUE)),
      switchMap((total) => {
        let lastOffset = 0;
        let lastLimit = 0;
        const limitAndOffset = [];

        for (let i = 0; lastOffset + lastLimit < total; i++) {
          lastOffset += lastLimit;
          const maxLimit = total - lastOffset;
          const limit = 2 ** i * initialPageSize;
          lastLimit = Math.min(limit, maxLimit);

          limitAndOffset.push([lastLimit, lastOffset]);
          // CONTENT OF ARRAY: [20,0] , [40,20] , [80,60] , [160,120] , ...
          // TOTAL RETRIEVED :  20       60        120       280
        }

        return from(limitAndOffset);
      }),
    );
    return pages$.pipe(
      concatMap(([limit, offset]) =>
        this.rpc(
          { ...opts, filterBy: { ...opts.filterBy, offset, limit } },
          data,
        ),
      ),
      scan(
        (acc, curr) => ({
          total: curr.total,
          items: acc.items.concat(curr.items),
        }),
        { total: 0, items: [] } as any,
      ),
      finalize(() => this.rpcFinish.emit()),
    );
  }

  rpcWithPreload(limit: number, opts: RPC, data?: any): Observable<any> {
    const preloadOpts = Object.assign({}, opts);
    preloadOpts.filterBy = Object.assign({}, preloadOpts.filterBy, { limit });

    const preloadRPC$ = this.rpc(preloadOpts, data);
    const finalRPC$ = this.rpc(opts, data);

    return concat(preloadRPC$, finalRPC$).pipe(
      finalize(() => this.rpcFinish.emit()),
    );
  }

  rpc(opts: RPC, data?: any): Observable<any> {
    let params = new HttpParams();

    if (opts.filterBy) {
      for (const key of Object.keys(opts.filterBy)) {
        params = params.set(key, opts.filterBy[key]);
      }
    }

    // Set project automatically in body
    if (
      (!opts.filterBy ||
        (opts.filterBy && !opts.filterBy.project && !opts.filterBy.alias)) &&
      this.project.getProject('id')
    ) {
      if (
        opts.handler !== 'get' &&
        opts.handler !== 'all' &&
        opts.handler !== 'search'
      ) {
        data.project = this.project.getProject('id');
      }
    }

    params = this.getParamsWithProject(params);

    // Setup the cache for the default cached repositories when listing all
    if (
      Api.DEFAULT_CACHE_REPOSITORIES.has(opts.repository) &&
      opts.handler === 'all'
    ) {
      opts.cache = Api.DEFAULT_CACHE_TIMEOUT;
    } else if (
      Api.DEFAULT_CACHE_REPOSITORIES.has(opts.repository) &&
      Api.DEFAULT_CACHE_MODIFY_ACTIONS.has(opts.handler)
    ) {
      // Auto clear cache for the default cached repositories when modifying the model
      this.removeCache(opts.repository);
    }

    // If there is cache avoid to process the request
    const requestCache = this.getRequestCache(opts, params);
    if (requestCache) return timer(0).pipe(mapTo(requestCache));

    let http$: Observable<object>;
    const url = Api.getUrl(opts);
    if (opts.method === 'get') {
      http$ = this.http.get(url, { params, observe: opts.observe });
    } else {
      http$ = this.http.post(url, data, { params, observe: opts.observe });
    }

    return http$.pipe(
      map((response: Response) =>
        this.setRequestCache(opts, params, response || {}),
      ),
    );
  }

  uploadForm(opts: RPC, data: FormData, paramsData = {}): Observable<any> {
    let params = new HttpParams({ fromObject: paramsData });

    if (!params.has('project')) {
      params = params.set('project', this.project.getProject('id').toString());
    }

    const url = Api.getUrl(opts);
    return this.http.post(url, data, { params });
  }

  getParamsWithProject(params: HttpParams): HttpParams {
    if (params.has('project') || !this.project.getProject('id')) {
      return params;
    }

    return params.set('project', this.project.getProject('id').toString());
  }

  removeCache(key: string): void {
    this.cache.removeByNamespace(`${key}_all_`);
  }

  private setRequestCache(
    opts: RPC,
    filters: HttpParams,
    requestData: any,
  ): any {
    if (opts.cache)
      this.cache.set(
        Api.getRequestCacheKey(opts, filters),
        requestData,
        opts.cache,
      );

    return requestData;
  }

  private getRequestCache(opts: RPC, filters: HttpParams): any | void {
    if (!opts.cache) return;

    return this.cache.get(Api.getRequestCacheKey(opts, filters));
  }
}
