import * as moment from 'moment';

export class ExpireMap<V> {
  private cache: Map<string, V>;
  private expire: Map<string, number>;

  constructor() {
    this.cache = new Map();
    this.expire = new Map();
  }

  set(key: string, value: V, ttl?: number): void {
    this.expire.delete(key);
    this.cache.set(key, value);
    if (ttl) this.expire.set(key, moment().unix() + ttl);
  }

  get(key: string): V | void {
    const timeOfDeath = this.expire.get(key);
    if (!timeOfDeath || moment().unix() < timeOfDeath) {
      return this.cache.get(key);
    }
    this.remove(key);
  }

  remove(key: string): void {
    this.expire.delete(key);
    this.cache.delete(key);
  }

  removeByNamespace(namespace: string): void {
    Array.from(this.cache.keys())
      .filter((key) => key.indexOf(namespace) === 0)
      .forEach((key) => this.remove(key));
  }
}
