import * as jQuery from 'jquery';
(window as any).$ = jQuery;
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
} else {
  window['ga-disable-UA-74212025-2'] = true;
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    throw err;
  });
