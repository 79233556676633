import { Cron } from '../cron';
import { CronPreset } from './index';

export const presetCustom: CronPreset = {
  id: 'custom',
  default: '0 0 * * *',

  detect: (cron: Cron): boolean => true,

  set: (
    cron: Cron,
    params: { value: string; minute: number; hour: number },
  ): Cron => {
    const { value, minute, hour } = params;

    if (value != null) {
      cron.parseAndUpdate(value);
    }
    cron.minute = minute != null ? minute.toString() : cron.minute;
    cron.hour = hour != null ? hour.toString() : cron.hour;

    return cron;
  },

  get: (cron: Cron): { value: any } => ({
    value: cron.unparse(),
  }),
};
