import { Cron } from '../cron';
import { presetDaily } from './daily';
import { presetWeekly } from './weekly';
import { presetMonthly } from './monthly';
import { presetCustom } from './custom';

export interface CronPreset {
  id: string;
  default: string;
  detect: (cron: Cron) => boolean;
  set: (cron: Cron, params: { [key: string]: any }) => Cron;
  get: (cron: Cron) => { [key: string]: any };
}

export const cronPresets: CronPreset[] = [
  presetDaily,
  presetWeekly,
  presetMonthly,
  presetCustom,
];
