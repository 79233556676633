import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { decodeTime, ulid } from 'ulid';
import * as moment from 'moment';

import {
  Cell,
  ConfigLegacy,
  ConfigMetadataLegacy,
  disabledValidationType,
  editorCellStyle,
  GIT_ID_HEADER,
  HEADER_ROW_POSITION,
  MetadataCell,
  MetadataCellLegacy,
  MetadataColumnLegacy,
  MetadataSheet,
  Sheet,
  UNDEFINED_ULID_VALUE,
  UndefinedUlidSlices,
  uniqueRowValidationType,
  Validation,
  ValidationType,
  ZERO_ULID_VALUE,
  getValidationTypeForArray,
} from '@app/config-manager/editor/editor.model';
import { ConfigManagerService } from '@app/config-manager/shared/api.service';
import { KendoService } from '@app/config-manager/shared/kendo/kendo.service';
import { ConfigSections } from '@app/config-manager/shared/api.model';
import {
  ConfigDataEvent,
  ConfigDataHelper,
} from '@app/config-manager/shared/config-data-helper';

declare let kendo: any;

const getTextSize = (() => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const offset = 10;
  ctx.font = `${editorCellStyle.fontSize}px ${editorCellStyle.fontFamily}`;
  return (text) => ctx.measureText(text).width + offset;
})();

// @dynamic
@Injectable()
export class EditorService {
  static readonly CLEAR_VALIDATION = 'Clear validation';
  static readonly minColumnWidth = 64;
  static readonly maxColumnWidth = 1000;
  static readonly maxColumnOffset = 50;
  static readonly maxRowOffset = 200;
  static readonly minUlidOffset = 2 ** 20;

  /**
   * Parse data from Config Manager to Kendo UI Spreadsheet
   *
   * @param {Map<string, ConfigLegacy>} response data from Config Manager
   * @returns {Sheet[]} observable of all of data sheets
   */
  static parseData(response: Map<string, ConfigLegacy>): { sheets: Sheet[] } {
    const sheets = [];

    response.forEach((section: ConfigLegacy, key: string) =>
      sheets.push(EditorService.parseSectionData(section, key)),
    );

    return { sheets };
  }

  static parseSectionData(section: ConfigLegacy, key: string): Sheet {
    const data = section.data || [{}];
    const [headerRow = {}] = data;
    const headerKeys = Object.keys(headerRow);

    return new Sheet(key, [
      // Header row
      EditorService.convertArrayToRow(headerKeys),
      // Data rows
      ...data.map((row) =>
        EditorService.convertArrayToRow(
          headerKeys.map((headerKey) =>
            headerKey in row ? row[headerKey] : null,
          ),
        ),
      ),
    ]);
  }

  static getMetadata(
    response: Map<string, ConfigLegacy>,
  ): Map<string, MetadataSheet> {
    const metaEditor: Map<string, MetadataSheet> = new Map();

    response.forEach((section: ConfigLegacy, key: string) =>
      metaEditor.set(key, EditorService.getSectionMetadata(section, key)),
    );

    return metaEditor;
  }

  static getSectionMetadata(section: ConfigLegacy, key: string): MetadataSheet {
    const { data, metadata } = section;
    const colKeys = data && data.length ? Object.keys(data[0]) : [];
    const isGitProject = colKeys.includes(GIT_ID_HEADER);

    const metaSheet = new MetadataSheet();

    // set column metadata
    if (metadata && 'columns' in metadata) {
      metadata.columns
        // Ward against invalid column validations
        .filter((colLegacyData) => {
          if ('position' in colLegacyData)
            return colLegacyData.position < colKeys.length;
          if ('name' in colLegacyData)
            return colKeys.includes(colLegacyData.name);
        })
        .filter((colLegacyData) => colLegacyData.validation !== 'undefined')
        .forEach((colLegacyData) => {
          if ('position' in colLegacyData) {
            metaSheet.columns.set(
              colKeys[colLegacyData.position],
              colLegacyData.validation as ValidationType,
            );
          } else if ('name' in colLegacyData) {
            metaSheet.columns.set(
              colLegacyData.name,
              colLegacyData.validation as ValidationType,
            );
          }
        });
    }

    // Map all the "__bsid" values to their #row
    const gitRowId = new Map<string, number>();
    if (isGitProject) {
      gitRowId.set(ZERO_ULID_VALUE, -1);
      data.forEach((dataRow, row) => {
        gitRowId.set(dataRow[GIT_ID_HEADER], row);
      });

      // Also ensure the git column validation is set
      if (!metaSheet.columns.has(GIT_ID_HEADER)) {
        metaSheet.columns.set(GIT_ID_HEADER, ValidationType.ulid);
      }
    }

    // set cell metadata (colors, ...)
    if (metadata && 'cells' in metadata) {
      metadata.cells.forEach((cell: MetadataCellLegacy) => {
        const currentCell: Partial<MetadataCell> = {};
        if ('color' in cell) {
          // When loaded from server, add headers row to colors row
          currentCell.background = `#${cell.color}`;
          currentCell.color = cell.color === '000' ? '#fff' : null;
        }
        if ('comment' in cell) {
          currentCell.comment = cell.comment;
        }
        if (Object.keys(currentCell).length) {
          if (GIT_ID_HEADER in cell) {
            // Restore #row from the "__bsid" value
            metaSheet.setCell(
              gitRowId.get(cell[GIT_ID_HEADER]) + 1,
              cell.col,
              currentCell,
            );
          } else {
            metaSheet.setCell(cell.row + 1, cell.col, currentCell);
          }
        }
      });
    }

    // set sort metadata
    if (metadata && 'sort' in metadata) {
      metaSheet.sort = metadata.sort;
    }

    // set excluded columns metadata
    if (metadata && 'excluded' in metadata) {
      metadata.excluded.forEach((excludedColumn) =>
        metaSheet.excluded.set(excludedColumn.name, excludedColumn),
      );
    }

    metaSheet.columns.forEach((value, key) => {
      if (value === ValidationType.boolean) {
        metaSheet.columns.set(key, ValidationType.boolNotNull);
      }
    });

    return metaSheet;
  }

  static appendExcludedColumns(
    data: { sheets: Sheet[] },
    metadata: Map<string, MetadataSheet>,
  ): void {
    metadata.forEach((metaSheet: MetadataSheet, key: string) => {
      if (!metaSheet.excluded.size) return;

      const sheet = data.sheets.find((s) => s.name === key);
      EditorService.appendSectionExcludedColumns(sheet, metaSheet);
    });
  }

  static appendSectionExcludedColumns(
    sheet: Sheet,
    metaSheet: MetadataSheet,
  ): void {
    if (metaSheet.excluded.size) {
      Array.from(metaSheet.excluded.values())
        .reverse()
        .forEach((excludedColumn) => {
          const maxRows = Math.max(
            sheet.rows.length,
            excludedColumn.data.length + 1,
          );
          for (let idx = 0; idx < maxRows; idx++) {
            // Add missing rows
            while (sheet.rows.length <= idx) {
              sheet.rows.push({ cells: [] });
            }
            // Add missing row cells
            while (sheet.rows[idx].length <= excludedColumn.position) {
              sheet.rows[idx].cells.push({});
            }
            // If header row => add header name
            if (!idx) {
              sheet.rows[idx].cells.splice(
                excludedColumn.position,
                0,
                new Cell(excludedColumn.name),
              );
            } else {
              sheet.rows[idx].cells.splice(
                excludedColumn.position,
                0,
                new Cell(excludedColumn.data[idx - 1]),
              );
            }
          }
        });
    }
  }

  static appendEmptyRow(data: { sheets: Sheet[] }): void {
    data.sheets.forEach((sheet) => {
      sheet.rows.push({ cells: [] });
    });
  }

  static disableColumnHeader(data: { sheets: Sheet[] }, header: string): void {
    data.sheets.forEach((sheet) =>
      EditorService.disableSectionColumnHeader(sheet, header),
    );
  }

  static disableSectionColumnHeader(sheet: Sheet, header: string): void {
    const maxRows = sheet.rows.length;

    if (maxRows) {
      const target = sheet.rows[0].cells.find(
        (cell: Cell) => cell.value === header,
      );
      if (target != null) target.enable = false;
    }
  }

  static prepareColumnsValues(
    data: { sheets: Sheet[] },
    metadata: Map<string, MetadataSheet>,
  ): void {
    metadata.forEach((metaSheet: MetadataSheet, key: string) => {
      if (!metaSheet.columns.size) return;

      const sheet = data.sheets.find((s) => s.name === key);
      EditorService.prepareSectionColumnsValues(sheet, metaSheet);
    });
  }

  static prepareSectionColumnsValues(
    sheet: Sheet,
    metaSheet: MetadataSheet,
  ): void {
    const maxRows = sheet.rows.length;
    const validations = sheet.rows[0].cells.map((cell) =>
      metaSheet.columns.get(cell.value),
    );

    for (let rowId = 1; rowId < maxRows; rowId++) {
      sheet.rows[rowId].cells.forEach((cell: Cell, cellId: number) => {
        if (cell.value == null) return;

        if (validations[cellId] === ValidationType.date) {
          // Convert timestamp to UTC date string
          cell.value = moment.unix(cell.value as number).toDate();
        } else if (
          validations[cellId] === ValidationType.boolean &&
          typeof cell.value === 'string'
        ) {
          // Convert "TRUE" & "FALSE" string to boolean
          const strValue = cell.value.toLowerCase();
          if (strValue === 'true') {
            cell.value = true;
          } else if (strValue === 'false') {
            cell.value = false;
          }
        }
      });
    }
  }

  static applyMetadata(
    metadata: Map<string, MetadataSheet>,
    spreadsheet: kendo.ui.Spreadsheet,
    skipCellMetadata = false,
  ): void {
    metadata.forEach((metaSheet: MetadataSheet, key: string) =>
      EditorService.applySectionMetadata(
        metaSheet,
        spreadsheet.sheetByName(key),
        skipCellMetadata,
      ),
    );
  }

  static applySectionMetadata(
    metaSheet: MetadataSheet,
    sheet: any,
    skipCellMetadata = false,
  ): void {
    sheet.batch(
      () => {
        // Apply metadata validations
        metaSheet.columns.forEach((validation, header) =>
          EditorService.setValidationColumn(sheet, header, validation),
        );

        // Apply metadata by cell (colors, ...)
        if (!skipCellMetadata) {
          metaSheet.cells.forEach((cell) => {
            const cellRange = sheet.range(
              KendoService.getRangeRef(cell.row, cell.col),
            );
            if (cell.background) cellRange.background(cell.background);
            if (cell.color) cellRange.color(cell.color);
          });
        }

        // Apply metadata sort
        if (metaSheet.sort.key) {
          EditorService.sortColumns(sheet, metaSheet.sort);
        }
      },
      { layout: true },
    );
  }

  static applyUniqueHeadersValidation(sheets: any[]) {
    // TODO: restore kendo.spreadsheet.Sheet[] when Kendo fix the Typescript definition
    sheets.forEach((sheet) => {
      sheet.batch(() => {
        const lastColumn = KendoService.getVisibleBoundaryColumn(sheet);
        const startCell = new kendo.spreadsheet.CellRef(HEADER_ROW_POSITION, 0); // aR, aC
        const endCell = new kendo.spreadsheet.CellRef(
          HEADER_ROW_POSITION,
          lastColumn,
        ); // bR, bC
        const range = sheet.range(
          new kendo.spreadsheet.RangeRef(startCell, endCell),
        );
        range.validation(Validation.create(uniqueRowValidationType, startCell));
      });
    });
  }

  static setupSheetFixtures(sheet: any, row = 2, column = 1) {
    // Frozen & hide rows and columns layout
    sheet.batch(
      () => {
        sheet.frozenRows(1);

        // Show the cells inside the boundary (starting from the boundary instead of 0)
        for (let r = KendoService.getVisibleBoundaryRow(sheet); r < row; r++) {
          sheet.unhideRow(r);
        }
        for (
          let c = KendoService.getVisibleBoundaryColumn(sheet);
          c < column;
          c++
        ) {
          sheet.unhideColumn(c);
        }

        // Hide the cells outside the boundary
        for (let r = row, lastRow = sheet._rows._count; r < lastRow; r++) {
          sheet.hideRow(r);
        }
        for (
          let c = column, lastColumn = sheet._columns._count;
          c < lastColumn;
          c++
        ) {
          sheet.hideColumn(c);
        }
      },
      { layout: true },
    );
  }

  static setupSpreadsheetFixtures(
    data: { sheets: Sheet[] },
    spreadsheet: kendo.ui.Spreadsheet,
  ) {
    data.sheets.forEach((sheetData: Sheet) => {
      const sheet = spreadsheet.sheetByName(sheetData.name);
      const firstRow = Math.max(sheetData.rows.length, 2);
      const firstColumn = Math.max(
        ...sheetData.rows.map((r) => r.cells.length),
        1,
      );

      EditorService.setupSheetFixtures(sheet, firstRow, firstColumn);
    });

    /**
     * TODO: Remove this workaround when fixed the related issue
     * Issue with missing wiew property (Clipboard)
     * https://github.com/telerik/kendo-ui-core/issues/3302
     */
    (<any>spreadsheet)._workbook._view = (<any>spreadsheet)._view;
  }

  static reApplyValidations(
    metadata: Map<string, MetadataSheet>,
    sheet: kendo.spreadsheet.Sheet,
  ) {
    const metaSheet = metadata.get((<any>sheet).name());

    // Apply metadata validations
    metaSheet.columns.forEach((validation, header) => {
      EditorService.setValidationColumn(sheet, header, validation);
    });
  }

  static convertArrayToRow(values: (string | number | object | boolean)[]): {
    cells: Cell[];
  } {
    return {
      cells: values.map((value) => new Cell(value)),
    };
  }

  static getRowByHeaderValue(sheet: any, header: string, value: string): any {
    const rows = KendoService.getVisibleBoundaryRow(sheet);
    const cols = KendoService.getVisibleBoundaryColumn(sheet);

    const column = KendoService.getColumnByRowValue(
      sheet,
      HEADER_ROW_POSITION,
      header,
    );
    const range = sheet.range(1, column, rows - 1, 1);

    const row = kendo.util.withExit((exit) => {
      range.forEachCell((r, c, cell) => {
        if (value.localeCompare(cell.value) === 0) exit(r);
      });
      return 0;
    });

    return sheet.range(row, 0, 1, cols);
  }

  /**
   * Parse data from Kendo UI Spreadsheet to Config Manager Backend
   *
   * @param {array} rows Kendo UI Spreadsheet rows
   * @param {MetadataSheet} metaSheet Metadata with column validations and cell comments
   * @param {boolean} isGitProject
   *
   * @returns {ConfigLegacy} data and metadata
   */
  static parseDataToServer(
    { rows = [] }: { rows: any[] },
    metaSheet: MetadataSheet,
    isGitProject = false,
  ): ConfigLegacy {
    const metadataColumns: MetadataColumnLegacy[] = [];
    const metadataCells: MetadataCellLegacy[] = [];

    const configData = ConfigDataHelper.create()
      .withSerializer((header, value) =>
        EditorService.parseValue(
          value,
          metaSheet.columns.get(header) || disabledValidationType,
        ),
      )
      .onEvent(ConfigDataEvent.addColumn, (name, position) => {
        if (!metaSheet.columns.has(name)) return;
        // Store column validations with name instead of position
        if (isGitProject) {
          metadataColumns.push({
            name,
            validation: metaSheet.columns.get(name),
          });
        } else {
          metadataColumns.push({
            position,
            validation: metaSheet.columns.get(name),
          });
        }
      });

    const addMetadataCell = (
      row: number,
      col: number,
      cellData?: Partial<MetadataCell>,
    ) => {
      const cellComment = metaSheet.getCellProp(row + 1, col, 'comment');
      if (cellComment || cellData) {
        metadataCells.push(Object.assign({ row, col }, cellComment, cellData));
      }
    };

    const filteredRows = rows.filter(
      ({ cells }: { cells: any[] }) =>
        Array.isArray(cells) && cells.some((cell: object) => 'value' in cell),
    );

    if (isGitProject) {
      // Sort rows by "__bsid" value and use the new position as "row.index" as well
      const gitColumnIndex = filteredRows[0].cells.findIndex(
        ({ value }) => value === GIT_ID_HEADER,
      );
      filteredRows
        .sort((row1, row2) => {
          if (row1.index === 0) return -1;
          if (row2.index === 0) return +1;
          return row1.cells[gitColumnIndex].value.localeCompare(
            row2.cells[gitColumnIndex].value,
          );
        })
        .forEach((row, index) => {
          Object.assign(row, { index });
        });
    }

    filteredRows.forEach(
      ({ index: row, cells }: { index: number; cells: any[] }) => {
        cells.forEach(
          ({
            value,
            index: column,
            background,
          }: {
            value: string;
            index: number;
            background: string;
          }) => {
            if (typeof value === 'string' && value.length > 1) {
              value = value.trim();
            }

            if (row === 0) {
              // first row
              configData.addColumnHeader(column, value);
            } else {
              configData.addRowColumnData(row - 1, column, value);
            }

            // Add cell color
            addMetadataCell(
              row - 1,
              column,
              background && { color: background.substr(1) },
            );
          },
        );
      },
    );

    // Add metadada
    const metadata: ConfigMetadataLegacy = {};
    if (metadataColumns.length) {
      metadata.columns = metadataColumns;
    }

    if (metadataCells.length) {
      // If Git Project: Use cells metadata "__bsid" instead of "row"
      metadata.cells = !isGitProject
        ? metadataCells
        : metadataCells.map(({ row, col, color, comment }) => {
            const cell: MetadataCellLegacy = { col };
            cell[GIT_ID_HEADER] =
              row === -1 ? ZERO_ULID_VALUE : configData.getRowGitHeaderId(row);
            if (color != null) cell.color = color;
            if (comment != null) cell.comment = comment;
            return cell;
          });
    }

    if (metaSheet.sort && metaSheet.sort.key) {
      metadata.sort = metaSheet.sort;
    }

    if (configData.getExcludedCount()) {
      metadata['excluded'] = configData.excluded;
    }

    return { data: configData.data, metadata };
  }

  static setValidationColumn(
    sheet: any,
    header: string,
    validationType: string,
  ): kendo.spreadsheet.Range {
    const endRow = KendoService.getVisibleBoundaryRow(sheet);
    const column = KendoService.getColumnByRowValue(
      sheet,
      HEADER_ROW_POSITION,
      header,
    );
    const startCell = new kendo.spreadsheet.CellRef(1, column); // aR, aC
    const endCell = new kendo.spreadsheet.CellRef(endRow, column); // bR, bC
    const range = sheet.range(
      new kendo.spreadsheet.RangeRef(startCell, endCell),
    );

    range.format(Validation.getFormat(validationType));

    /** Exception for gitIdHeader ULID header */
    if (header === GIT_ID_HEADER) {
      range.validation(
        Validation.create(validationType, startCell).setType('warning'),
      );
      range.editor(null);
    } else {
      range.validation(Validation.create(validationType, startCell));
      range.editor(Validation.getEditor(validationType));
    }

    if (
      validationType === ValidationType.boolNotNull ||
      validationType === ValidationType.bool
    ) {
      range.forEachCell((row, col, cell) => {
        if (cell.value == null || typeof cell.value === 'boolean') return;
        const value = String(cell.value).toLowerCase();
        if (value === 'true') {
          sheet.range(row, col).value(true);
        } else if (value === 'false') {
          sheet.range(row, col).value(false);
        } else {
          sheet.range(row, col).value(null);
        }
      });
    } else if (validationType === ValidationType.ulid) {
      KendoService.suspendChanges(false, sheet, () => {
        range.forEachCell((row, col, cell) => {
          if (cell.value == null && row < endRow) {
            sheet
              .range(row, col)
              .value(header === GIT_ID_HEADER ? UNDEFINED_ULID_VALUE : ulid());
          }
        });
      });
    }

    return range;
  }

  /**
   * Generate N ULIDs between prev (ULID) and next (ULID), spread uniformly
   * Note: If prev && next are equal, the new ULIDs are not guaranteed to be in between.
   *
   * @param {number} count Number of ULIDs
   * @param {string} prev  Previous ULID
   * @param {string} next  Next ULID
   * @returns {string[]}
   */
  static generateUlidsBetween(
    count: number,
    prev?: string,
    next?: string,
  ): string[] {
    const start = prev != null ? decodeTime(prev) : 0;
    const end =
      next != null
        ? decodeTime(next)
        : Math.max(
            Date.now(),
            start + (count + 1) * EditorService.minUlidOffset,
          );
    const distance = end - start;

    const isAfterPrev = (id: string) => (prev ? prev < id : true);
    const isBeforeNext = (id: string) => (next ? next > id : true);

    const width = distance / (count + 1);
    const slices = new Array(count).fill(width).map((val, pos) => {
      const timestamp = Math.floor(start + val * (pos + 1));

      let nextId = ulid(timestamp);
      let retries = 1000;
      while (retries-- && (!isAfterPrev(nextId) || !isBeforeNext(nextId)))
        nextId = ulid(timestamp);

      return nextId;
    });

    // In case the slices are so short distance that share timestamp
    // this will ensure they are in the correct order
    return slices.sort();
  }

  /**
   * Get from the list of "undefined" ULIDs slices from a ULIDs list
   *
   * @param {string[]} values ULIDs list
   * @returns {UndefinedUlidSlices[]}
   */
  static getUndefinedSlices(values: string[]): UndefinedUlidSlices[] {
    const slices = [];
    const lastIdx = values.length - 1;

    let prev = null;
    let slice: UndefinedUlidSlices = null;

    values.forEach((value: string, idx: number) => {
      if (prev == null && value === UNDEFINED_ULID_VALUE) {
        // The first element is [Undefined] => start "slice" with "null"
        slice = { start: idx, end: null, prev: null, next: null };
      } else if (
        prev !== UNDEFINED_ULID_VALUE &&
        value === UNDEFINED_ULID_VALUE
      ) {
        // From [Defined] -> [Undefined] => start "slice" with "prev"
        slice = { start: idx, end: null, prev, next: null };
      } else if (
        prev === UNDEFINED_ULID_VALUE &&
        value !== UNDEFINED_ULID_VALUE
      ) {
        // From [Undefined] -> [Defined] => close "slice"
        slice.next = value;
        slice.end = idx - 1;
        slices.push(slice);
        slice = null;
      }

      // The last element with unsaved slices => end "slice"
      if (idx === lastIdx && slice != null) {
        slice.end = idx;
        slices.push(slice);
        slice = null;
      }
      prev = value;
    });

    return slices;
  }

  /**
   * Detect the "undefined" ULIDs inside the section and replace them with valid & ordered ULIDs
   *
   * @param sheet
   * @param useTimestamp
   */
  static replaceUndefinedUlids(sheet: any, useTimestamp = false): void {
    const endRow = KendoService.getVisibleBoundaryRow(sheet);
    const column = KendoService.getColumnByRowValue(
      sheet,
      HEADER_ROW_POSITION,
      GIT_ID_HEADER,
    );
    const range = sheet.range(
      KendoService.getRangeRef(1, column, endRow, column),
    );

    // Calculate the missing Ulids
    let values: string[] = range.values().map((v) => v[0]);
    if (useTimestamp) {
      values = values.map((v) => (v === UNDEFINED_ULID_VALUE ? ulid() : v));
    } else {
      values = this.calculateMissingUlids(values);
    }

    sheet.batch(() => {
      sheet
        .range(KendoService.getRangeRef(1, column, values.length, column))
        .values(values.map((val) => [val]));
    }, {});
  }

  static calculateMissingUlids(data: string[]): string[] {
    // trim null values from the data range
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i] != null) break;
      data.pop();
    }

    // if no values in the list, finish
    if (!data.length) return;

    const slices = this.getUndefinedSlices(data);
    slices.forEach((slice) => {
      const count = slice.end - slice.start + 1;
      const ulids = this.generateUlidsBetween(count, slice.prev, slice.next);
      data.splice(slice.start, count, ...ulids);
    });

    return data;
  }

  static parseBoolean(value: string | boolean): string | boolean {
    if (typeof value === 'string') {
      const lowerValue = value.trim().toLowerCase();
      if (lowerValue === 'true') return true;
      if (lowerValue === 'false') return false;
    }
    return value;
  }

  /**
   * Parse values to send to the server
   *
   * @param {string} value
   * @param {string} validation
   * @returns {number|string|object|boolean} value parsed
   */
  static parseValue(
    value: any,
    validation: string,
  ): number | string | object | boolean {
    switch (validation) {
      case ValidationType.string:
      case ValidationType.ulid:
        return String(value);
      case ValidationType.numeric:
      case ValidationType.decimal:
      case ValidationType.numericNotNull:
      case ValidationType.decimalNotNull:
        return parseFloat(value);
      case ValidationType.integer:
      case ValidationType.integerNotNull:
        return parseInt(value, 10);
      case ValidationType.date:
        return moment(kendo.spreadsheet.numberToDate(value)).unix();
      case ValidationType.object:
      case ValidationType.objectNotNull:
        return JSON.parse(value);
      case ValidationType.boolean:
      case ValidationType.bool:
      case ValidationType.boolNotNull:
        return this.parseBoolean(value);
      default:
        // Auto-detect type (when not captured: unique, null, others...)
        try {
          return JSON.parse(value);
        } catch {
          return value;
        }
    }
  }

  static reApplyHeaderValidationsUI(
    metadata: Map<string, MetadataSheet>,
    spreadsheet: kendo.ui.Spreadsheet,
    offset?: number,
  ): void {
    if (offset === undefined) {
      offset = this.getColumnsOffset(spreadsheet);
    }

    const headers = kendo.jQuery('.k-spreadsheet-column-header > div > div');
    const sheet: any = spreadsheet.activeSheet();

    if (!metadata.has(sheet.name())) return;
    const metaSheet = metadata.get(sheet.name());
    const headerValidations = new Map<number, string>();
    const columnsMap = KendoService.getColumnsMapByRow(
      sheet,
      HEADER_ROW_POSITION,
    );

    metaSheet.columns.forEach(
      (validation, key) =>
        columnsMap.has(key) &&
        headerValidations.set(columnsMap.get(key)[0] - offset, validation),
    );

    headers.each((column, element) =>
      kendo
        .jQuery(element)
        .attr('validation', headerValidations.get(column) || null),
    );
  }

  /**
   * Calculate each column width using the column cell text width
   *
   * @param sheet
   * @param auto
   * @param manual
   */
  static autoAdjustColumns(sheet: any, auto = true, manual = false): void {
    const range = KendoService.getVisibleRange(sheet);
    const widthList = Array(range._ref.width()).fill(
      EditorService.minColumnWidth,
    );
    const widthCurrent: number[] = [];

    sheet.batch(
      () => {
        widthList.forEach((width, index) =>
          widthCurrent.push(sheet.columnWidth(index)),
        );
      },
      { layout: true },
    );

    const equals = widthCurrent.every((v) => v === widthCurrent[0]);
    if (equals || (auto && !manual)) {
      range.forEachCell((row, col, cell) => {
        if (!cell.value) return;
        if (widthList[col] === EditorService.maxColumnWidth) return;

        const text = JSON.stringify(cell.value);
        const textWidth = getTextSize(text);

        if (textWidth > widthList[col]) {
          widthList[col] = Math.min(textWidth, EditorService.maxColumnWidth);
        }
      });
    }

    sheet.batch(
      () => {
        widthList.forEach((width, index) => sheet.columnWidth(index, width));
      },
      { layout: true },
    );
  }

  /**
   * Sort the sheet columns by the provided sort config
   *
   * @param sheet
   * @param {string} key
   * @param {boolean} ascending
   */
  static sortColumns(
    sheet: any,
    { key, ascending }: { key?: string; ascending?: boolean },
  ): void {
    if (key == null) return;
    const column = KendoService.getColumnByRowValue(
      sheet,
      HEADER_ROW_POSITION,
      key,
    );

    KendoService.getDataRange(sheet)
      .resize({ top: +1 })
      .sort([{ column, ascending }]);
  }

  /**
   * Checks if sorted by gitIdColumn
   *
   * @param {{key?: string}} sort
   * @returns {boolean}
   */
  static isSortedByGitIdColumn(sort: { key?: string }): boolean {
    return !sort.key || sort.key === GIT_ID_HEADER;
  }

  static getColumnsOffset(spreadsheet: any): number {
    try {
      return spreadsheet._view.panes[0]._currentView.columns.values.start;
    } catch {
      return 0;
    }
  }

  /**
   * Get the number of columns and rows with a plus of extra amount
   *
   * @param {Map<string, ConfigLegacy>} sections - All data sections
   * @returns {{columns: number, rows: number} number of columns and rows
   */
  static getTotalColumnsAndRows(sections: Map<string, ConfigLegacy>): {
    columns: number;
    rows: number;
  } {
    let columns = 0;
    let rows = 0;

    sections.forEach(({ data }) => {
      if (!data.length) return;

      const [col] = data;
      if (columns < Object.keys(col).length) {
        columns = Object.keys(col).length;
      }

      if (rows < data.length) {
        rows = data.length;
      }
    });

    columns = columns + EditorService.maxColumnOffset;
    rows = rows + EditorService.maxRowOffset;

    return { columns, rows };
  }

  static getRecommendedColumnValidation(
    column: number,
    sheet: any,
  ): ValidationType | null {
    const values = KendoService.getColumnVisibleValues(sheet, column);
    return getValidationTypeForArray(values);
  }

  constructor(private api: ConfigManagerService) {}

  /**
   * Get data
   *
   * @param {string} branch Config Manager Branch
   * @param {string} config Config Manager File
   * @returns {Observable<Map<string, ConfigLegacy>>} observable of all of data configs
   */
  getDataSingleRequest(
    branch: string,
    config: string,
  ): Observable<Map<string, ConfigLegacy>> {
    return this.api.getConfigSections(branch, config).pipe(
      map(({ data, metadata }: ConfigSections) => {
        const output = new Map();
        const sections = Object.keys(data);

        sections.forEach((section: string, position: number) => {
          output.set(section, {
            data: data[section],
            metadata: metadata[position],
          });
        });

        return output;
      }),
    );
  }

  /**
   * Get data
   *
   * @param {string} branch Config Manager Branch
   * @param {string} config Config Manager File
   * @returns {Observable<Map<string, ConfigLegacy>>} observable of all of data configs
   */
  getData(
    branch: string,
    config: string,
  ): Observable<Map<string, ConfigLegacy>> {
    return this.api.getSections(branch, config).pipe(
      concatMap((sections: string[]) => {
        const sheets = sections.map((section: string) =>
          this.api.getSection(branch, config, section),
        );

        return forkJoin([of(sections), ...sheets]);
      }),
      map(
        ([sections, ...response]: [string[], ConfigLegacy]): Map<
          string,
          ConfigLegacy
        > => {
          const data = new Map();
          sections.forEach((section: string, position: number) => {
            data.set(section, response[position]);
          });

          return data;
        },
      ),
    );
  }
}
