import { ModuleWithProviders, NgModule } from '@angular/core';

import { CHECKER_PLUGINS, CheckerPluginChecks } from './checker.model';
import { CheckerService } from './checker.service';
import { GithubCheckerPlugin } from './plugin';

@NgModule({})
export class CheckerModule {
  static forRoot(
    ...plugins: CheckerPluginChecks[]
  ): ModuleWithProviders<CheckerModule> {
    return {
      ngModule: CheckerModule,
      providers: [
        CheckerService,
        {
          provide: CHECKER_PLUGINS,
          useValue: plugins,
        },
        // Plugins
        GithubCheckerPlugin,
      ],
    };
  }
}
