import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
  defaultFieldErrors,
  FieldErrorResolver,
  FieldErrors,
} from './field-errors.model';

@Component({
  selector: 'mat-error[spErrors]',
  templateUrl: 'field-errors.component.html',
})
export class FieldErrorsComponent implements OnInit, OnChanges, OnDestroy {
  errorMessages: string[] = [];

  @Input('spErrors') private control: AbstractControl;
  @Input('custom') private customFieldErrors?: FieldErrors = [];
  private subscription: Subscription;
  private fieldErrors = defaultFieldErrors;

  ngOnInit() {
    if (this.control) {
      this.recalculateErrors(this.control.status);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.control) {
      this.subscription?.unsubscribe();
      this.subscription = this.control.statusChanges.subscribe(
        this.recalculateErrors.bind(this),
      );
    }
    if (changes.customFieldErrors) {
      this.fieldErrors = [...this.customFieldErrors, ...defaultFieldErrors];
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private recalculateErrors(status: string) {
    if (status === 'VALID' || status === 'DISABLED') {
      this.errorMessages = [];
    } else if (status === 'INVALID') {
      this.errorMessages = this.getErrorMessages();
      this.control.markAsTouched();
    }
  }

  private getErrorMessages(): string[] {
    const messages = new Map();

    // First handle custom field errors
    for (const [id, handler] of this.fieldErrors) {
      if (messages.has(id)) continue;

      const error = this.control.getError(id);
      if (error) {
        messages.set(id, this.handleError(handler, error));
      }
    }

    // Last handle untreated errors generically
    Object.keys(this.control.errors)
      .filter((id) => !messages.has(id))
      .forEach((id) => messages.set(id, `Field error: ${id}`));

    return Array.from(messages.values());
  }

  private handleError(handler: FieldErrorResolver, error: any): string {
    if (typeof handler === 'string') {
      return handler;
    }
    return handler(error, this.control);
  }
}
