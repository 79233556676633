import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthProfile } from './auth.model';

@Injectable()
export abstract class AuthService {
  profile: AuthProfile;
  profile$: Observable<AuthProfile>;
  authenticated$: Observable<boolean>;

  abstract login(): Promise<void>;
  abstract logout(): Promise<void>;
  abstract isAuthenticated(): Promise<boolean>;
  abstract getIdToken(): Promise<string>;
  abstract getAccessToken(): Promise<string>;
  abstract getAuthErrors(): string[];
  abstract hasAuthErrors(): boolean;
  abstract hasGroup(group: string): Promise<boolean>;
}
