import * as moment from 'moment';

export enum ValidationType {
  numeric = 'numeric',
  decimal = 'decimal',
  numericNotNull = 'numericNotNull',
  decimalNotNull = 'decimalNotNull',
  integer = 'integer',
  integerNotNull = 'integerNotNull',
  date = 'date',
  object = 'object',
  objectNotNull = 'objectNotNull',
  string = 'string',
  boolNotNull = 'boolNotNull',
  bool = 'bool',
  unique = 'unique',
  ulid = 'ulid',
  /**
   * @deprecated Use ValidationType.boolNotNull instead.
   */
  boolean = 'boolean',
}

export const ValidationNames: Record<ValidationType, string> = {
  [ValidationType.numeric]: 'Numeric',
  [ValidationType.decimal]: 'Decimal',
  [ValidationType.numericNotNull]: 'Numeric (Not NULL)',
  [ValidationType.decimalNotNull]: 'Decimal (Not NULL)',
  [ValidationType.integer]: 'Integer',
  [ValidationType.integerNotNull]: 'Integer (Not NULL)',
  [ValidationType.date]: 'Date',
  [ValidationType.object]: 'JSON',
  [ValidationType.objectNotNull]: 'JSON (Not NULL)',
  [ValidationType.string]: 'String',
  [ValidationType.boolNotNull]: 'Boolean (Not NULL)',
  [ValidationType.bool]: 'Boolean',
  [ValidationType.unique]: 'Unique',
  [ValidationType.ulid]: 'Unique ID',
  [ValidationType.boolean]: 'Boolean (Deprecated)',
};

export const getValidationTypeFromName = (
  name: string,
): ValidationType | undefined => {
  for (const validationType in ValidationNames) {
    if (
      ValidationNames.hasOwnProperty(validationType) &&
      ValidationNames[validationType] === name
    ) {
      return validationType as ValidationType;
    }
  }
};

export const ValidatorRegex = {
  integer: /^-?\d+$|^null$/,
  integerNotNull: /^-?\d+$/,
  numeric: /^-?\d+(\.\d+)?$|^null$/,
  numericNotNull: /^-?\d+(\.\d+)?$/,
  decimal: /^-?\d+\.\d+$|^null$/,
  decimalNotNull: /^-?\d+\.\d+$/,
  bool: /^true$|^false$|^null$/,
  boolNotNull: /^true$|^false$/,
  date: /^\d{4}[./-]\d{2}[./-]\d{2}/, // /TODO (YYYY-MM-DD)
  ulid: /^[0-9A-Z]{26}$/,
};

export const JsonValidator = (str: string, allowNull: boolean = true) => {
  try {
    const parsedString = JSON.parse(str);
    if (!allowNull && parsedString === null) return false;

    return true;
  } catch (e) {
    return false;
  }
};

// TODO extract the regex generation outside
const Validators: Record<ValidationType, (string) => boolean> = {
  decimalNotNull: (v: string) => ValidatorRegex.decimalNotNull.test(v),
  decimal: (v: string) => ValidatorRegex.decimal.test(v),
  integerNotNull: (v: string) => ValidatorRegex.integerNotNull.test(v),
  integer: (v: string) => ValidatorRegex.integer.test(v),
  numericNotNull: (v: string) => ValidatorRegex.numericNotNull.test(v),
  numeric: (v: string) => ValidatorRegex.numeric.test(v),
  boolNotNull: (v: string) => ValidatorRegex.boolNotNull.test(v),
  bool: (v: string) => ValidatorRegex.bool.test(v),
  date: (v: string) => ValidatorRegex.date.test(v),
  objectNotNull: (v: string) => JsonValidator(v, false),
  object: (v: string) => JsonValidator(v, true),
  boolean: (v: string) => false,
  unique: (v: string) => false,
  ulid: (v: string) => ValidatorRegex.ulid.test(v),
  string: () => true,
};

export const excludedValidations = new Set<string>([ValidationType.boolean]);
export const ValidationValues = Object.values(ValidationType).filter(
  (value) => !excludedValidations.has(value),
);
export function getValidationTypeForArray(
  array: (string | number)[],
): ValidationType | null {
  for (const [validationType, validator] of Object.entries(Validators)) {
    if (array.every(validator)) {
      return validationType as ValidationType;
    }
  }
  return null;
}

export const HEADER_ROW_POSITION = 0;
export const GIT_ID_HEADER = '__bsid';
export const ZERO_ULID_VALUE = '00000000000000000000000000';
export const UNDEFINED_ULID_VALUE = 'ULIDXXXXXXXXXXXXXXXXXXXXXX';
export const uniqueRowValidationType = 'uniqueRowValidation';
export const disabledValidationType = null;
export const editorCellStyle = {
  fontFamily: 'Arial',
  fontSize: '12',
  color: '#000000',
};
export const STRUCTURED_CONFIG = 'structured-';

export interface EditorQueryParams {
  branch: string;
  config: string;
  section: string;
  selection: string;
  kv: string;
}

export interface MetadataColumnLegacy {
  name?: string;
  position?: number;
  validation: string;
}

export interface MetadataCellLegacy {
  [GIT_ID_HEADER]?: string;
  row?: number;
  col: number;
  color?: string;
  comment?: {
    value: string;
  };
}

export interface MetadataExcludedLegacy {
  name: string;
  position: number;
  data: (number | string)[];
}

export interface ConfigMetadataLegacy {
  cells?: MetadataCellLegacy[];
  columns?: MetadataColumnLegacy[];
  excluded?: MetadataExcludedLegacy[];
  sort?: {
    key?: string;
    ascending?: boolean;
  };
}

export interface ConfigLockLegacy {
  user?: string;
  timestamp?: number;
  section_id?: string;
  branch?: string;
}

export interface ConfigLegacyLink {
  id: string;
  gid: number;
}

export interface ConfigLegacy {
  data: object[];
  metadata: ConfigMetadataLegacy;
  lock?: ConfigLockLegacy;
  link?: ConfigLegacyLink;
}

export interface MetadataCell {
  row: number;
  col: number;
  color?: string;
  background?: string;
  comment?: {
    value: string;
  };
}

export interface UndefinedUlidSlices {
  start: number;
  end: number;
  prev: string;
  next: string;
}

export class Validation {
  allowNulls = false;
  showButton = false;
  type = 'reject';
  comparerType = '';
  titleTemplate = 'Validation Error';
  messageTemplate = 'Error';

  static create(
    validationType: ValidationType | string,
    cellRef: object,
  ): Validation {
    switch (validationType) {
      case ValidationType.numeric:
      case ValidationType.decimal:
        return new Validation(
          'custom',
          `VALIDATE_NUMBER(${cellRef.toString()})`,
        )
          .setMessageTemplate(`Invalid value for '${validationType}' cell`)
          .setAllowNulls(true);
      case ValidationType.numericNotNull:
      case ValidationType.decimalNotNull:
        return new Validation(
          'custom',
          `VALIDATE_NUMBER(${cellRef.toString()})`,
        ).setMessageTemplate(`Invalid value for '${validationType}' cell`);
      case ValidationType.integer:
        return new Validation(
          'custom',
          `VALIDATE_INTEGER(${cellRef.toString()})`,
        )
          .setMessageTemplate(`Invalid value for '${validationType}' cell`)
          .setAllowNulls(true);
      case ValidationType.integerNotNull:
        return new Validation(
          'custom',
          `VALIDATE_INTEGER(${cellRef.toString()})`,
        ).setMessageTemplate(`Invalid value for '${validationType}' cell`);
      case ValidationType.date:
        return new Validation('date');
      case ValidationType.object:
        return new Validation(
          'custom',
          `VALIDATE_JSON(${cellRef.toString()}, true)`,
        )
          .setMessageTemplate(`Invalid value for '${validationType}' cell`)
          .setAllowNulls(true);
      case ValidationType.objectNotNull:
        return new Validation(
          'custom',
          `VALIDATE_JSON(${cellRef.toString()}, false)`,
        ).setMessageTemplate(`Invalid value for '${validationType}' cell`);
      case ValidationType.string:
        return new Validation(
          'custom',
          `VALIDATE_STRING(${cellRef.toString()})`,
        )
          .setMessageTemplate(`Invalid value for '${validationType}' cell`)
          .setAllowNulls(true);
      case ValidationType.boolean:
      case ValidationType.boolNotNull:
        return new Validation('list', `{true,false}`).setMessageTemplate(
          `Invalid value for '${validationType}' cell`,
        );
      case ValidationType.bool:
        return new Validation('list', `{true,false}`)
          .setMessageTemplate(`Invalid value for '${validationType}' cell`)
          .setAllowNulls(true);
      case ValidationType.unique:
        return new Validation(
          'custom',
          `VALIDATE_UNIQUE(${cellRef.toString()})`,
        )
          .setMessageTemplate(`Invalid value for '${validationType}' cell`)
          .setAllowNulls(true);
      case ValidationType.ulid:
        return new Validation(
          'custom',
          `VALIDATE_ULID(${cellRef.toString()})`,
        ).setMessageTemplate(`Invalid value for '${validationType}' cell`);

      // Special Validation type cases
      case uniqueRowValidationType:
        return new Validation(
          'custom',
          `VALIDATE_UNIQUE(${cellRef.toString()}, true)`,
        ).setMessageTemplate('Value must be unique in this row');
      case disabledValidationType:
        return null;
    }
  }

  static getEditor(validationType: ValidationType | string): string {
    switch (validationType) {
      case ValidationType.ulid:
        return '_validation_ulid';
      default:
        return null;
    }
  }

  static getFormat(validationType: ValidationType | string): string {
    switch (validationType) {
      case ValidationType.date:
        return 'yyyy-mm-dd';
      default:
        return null;
    }
  }

  constructor(public dataType: string, public from?: string) {
    switch (dataType) {
      case 'list':
        this.comparerType = 'list';
        this.showButton = true;
        break;
      case 'date':
        this.comparerType = 'greaterThan';
        this.from = 'DATEVALUE("1/1/1900")';
        this.showButton = true;
        break;
    }
  }

  setAllowNulls(allowNulls: boolean) {
    this.allowNulls = allowNulls;
    return this;
  }

  setMessageTemplate(messageTemplate: string) {
    this.messageTemplate = messageTemplate;
    return this;
  }

  setType(type: string) {
    this.type = type;
    return this;
  }
}

export class Sheet {
  constructor(public name: string, public rows: any[] = []) {}
}

export class Cell {
  public enable?: boolean;

  constructor(public value: string | number | object | boolean) {
    if (value instanceof Object) {
      this.value = JSON.stringify(value);
    }
  }
}

export class MetadataSheet {
  excluded: Map<string, Partial<MetadataExcludedLegacy>> = new Map();
  columns: Map<string, ValidationType> = new Map();
  cells: Map<string, MetadataCell> = new Map();
  sort: { key?: string; ascending?: boolean } = {};

  private getCellKey(row: number, col: number): string {
    return `R${row}C${col}`;
  }

  setCell(row: number, col: number, value: Partial<MetadataCell>) {
    this.cells.set(
      this.getCellKey(row, col),
      Object.assign({ row, col }, value) as MetadataCell,
    );
  }

  getCell(row: number, col: number): MetadataCell {
    return this.cells.get(this.getCellKey(row, col));
  }

  getCellProp(row: number, col: number, prop: string): any {
    const cell = this.cells.get(this.getCellKey(row, col));
    if (cell && cell[prop]) {
      return { [prop]: cell[prop] };
    }
  }
}

export class ConfigLockStatus {
  user: string;
  datetime: number;
  isLockedByMe: boolean;
  lockedInDB = false;

  constructor(
    public branch: string,
    public config: string,
    public section: string,
    lock: ConfigLockLegacy,
    currentUser: string,
  ) {
    if (lock) {
      this.user = lock.user;
      this.datetime = lock.timestamp;
      this.isLockedByMe = this.user === currentUser;
      this.lockedInDB = true;
    } else {
      this.lockedByMe(currentUser, false);
    }
  }

  lockedByMe(user: string, lockedInDB: boolean) {
    this.user = user;
    this.datetime = moment().unix();
    this.isLockedByMe = true;
    this.lockedInDB = lockedInDB;
  }
}
