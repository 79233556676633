import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TodayComponent } from './today.component';
import { SharedModule } from '@app/shared/shared';

@NgModule({
  declarations: [TodayComponent],
  exports: [TodayComponent],
  imports: [CommonModule, SharedModule],
})
export class TodayModule {}
