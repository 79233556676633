import { Injectable } from '@angular/core';
import { first, tap } from 'rxjs/operators';

import { AuthProfile } from '@app/providers/auth/auth.model';
import { AuthService } from '@app/providers/auth/auth.service';
import { firstValueFrom } from 'rxjs';

const codeOwners = [
  'eric.pinto@socialpoint.es',
  'miguel.arregui@socialpoint.es',
  'jordi.cabezudo@socialpoint.es',
  'pau.tena@socialpoint.es',
];

@Injectable()
export class SessionService {
  profilePromise: Promise<AuthProfile>;
  private profile: AuthProfile;

  private beta = {
    dragoncity: {
      deploy: new Set(['albert.juhe@socialpoint.es', ...codeOwners]),
    },
  };

  constructor(private authService: AuthService) {
    this.profilePromise = firstValueFrom(
      this.authService.profile$.pipe(
        first((profile) => !!profile && !!profile.email),
        tap((profile) => (this.profile = profile)),
      ),
    );
  }

  isDragonCityDeployBeta(): boolean {
    return this.beta.dragoncity.deploy.has(this.profile.email);
  }
}
