export class NotificationMessage {
  type: string;
  text: string;
  icon: string;
  button: boolean;
  html?: string;
}

export const notificationPosition = {
  verticalPosition: 'top',
  horizontalPosition: 'right',
};

export const notificationText = {
  error: 'Server not responding',
  success: 'Success',
};

export const notificationIcon = {
  error: 'error',
  warn: 'warning',
  info: 'info',
  success: 'check',
};
