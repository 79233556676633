import { ulid } from 'ulid';

declare let kendo: any;

export class UlidEditor {
  static register(registerEditor = kendo.spreadsheet.registerEditor) {
    registerEditor('_validation_ulid', () => ({
      edit: (options) => options.callback(ulid()),
      icon: 'k-i-reset',
    }));
  }
}
