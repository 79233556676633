import { PipeTransform, Pipe } from '@angular/core';
import * as numeral from 'numeral';

@Pipe({ name: 'numeralFormat' })
export class NumeralFormat implements PipeTransform {
  transform(value: number): string {
    return numeral(value).format('0.0 a');
  }
}

@Pipe({ name: 'numeralFormatInteger' })
export class NumeralFormatInteger implements PipeTransform {
  transform(value: number): string {
    return numeral(value).format('0 a');
  }
}

@Pipe({ name: 'percentageFormat' })
export class PercentageFormat implements PipeTransform {
  transform(value: number): string {
    return numeral(value).format('0[.]00');
  }
}
