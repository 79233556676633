import {
  CheckerFinding,
  CheckerFindings,
  CheckerStatus,
} from '@app/providers/checker';

export const styleByStatus: Record<CheckerStatus, FindingStyle> = {
  [CheckerStatus.error]: {
    icon: 'gpp_bad',
    class: 'check-error',
  },
  [CheckerStatus.warn]: {
    icon: 'gpp_bad',
    class: 'check-warn',
  },
  [CheckerStatus.ok]: {
    icon: 'verified_user',
    class: 'check-ok',
  },
};

export interface FindingStyle {
  icon: string;
  class: string;
}

export interface StyledFinding extends CheckerFinding, FindingStyle {}

export interface StyledStatus extends FindingStyle {
  status: CheckerStatus;
  badge?: number;
}

export function getStyledFinding(finding: CheckerFinding): StyledFinding {
  return { ...finding, ...styleByStatus[finding.status] };
}

export function getStyledStatus(findings: CheckerFindings): StyledStatus {
  if (findings[CheckerStatus.error].length) {
    return {
      status: CheckerStatus.error,
      badge: findings[CheckerStatus.error].length,
      ...styleByStatus[CheckerStatus.error],
    };
  }
  if (findings[CheckerStatus.warn].length) {
    return {
      status: CheckerStatus.warn,
      badge: findings[CheckerStatus.warn].length,
      ...styleByStatus[CheckerStatus.warn],
    };
  }
  if (findings[CheckerStatus.ok].length) {
    return {
      status: CheckerStatus.ok,
      ...styleByStatus[CheckerStatus.ok],
    };
  }
  return null;
}
