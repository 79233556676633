import { Injectable, Pipe, PipeTransform } from '@angular/core';

export type FilterFunction = (x: any) => boolean;

@Pipe({
  name: 'filter',
})
@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: any[] = [], filterParam: string | FilterFunction): any[] {
    if (typeof filterParam === 'function') {
      return items.filter((item) => filterParam(item));
    }
    const filterRegexp = new RegExp(filterParam, 'gi');
    return items.filter((item) => filterRegexp.test(item));
  }
}
