import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { ActionCountdownComponent } from './action-countdown.component';

@NgModule({
  imports: [MatButtonModule],
  exports: [ActionCountdownComponent],
  declarations: [ActionCountdownComponent],
})
export class ActionCountdownModule {}
