<button
  mat-button
  [type]="options.type"
  [color]="options.buttonColor"
  [class.active]="options.active"
  [class.fullWidth]="options.fullWidth"
  [class.mat-mdc-raised-button]="options.raised"
  [class.mat-mdc-stroked-button]="options.stroked"
  [class.mat-mdc-flat-button]="options.flat"
  [ngClass]="options.customClass"
  [disabled]="options.active || options.disabled"
>
  <ng-container *ngIf="options.buttonIcon">
    <mat-icon
      class="mat-button-icon"
      [class.is-mat-icon]="!options.buttonIcon.fontSet"
      [class.active]="options.active && !options.disabled"
      [ngClass]="options.buttonIcon.customClass"
      [fontSet]="options.buttonIcon.fontSet"
      [fontIcon]="options.buttonIcon.fontIcon"
      [color]="options.buttonIcon.color"
      [svgIcon]="options.buttonIcon.svgIcon"
      [inline]="options.buttonIcon.inline"
    >
      {{ options.buttonIcon.fontSet ? '' : options.buttonIcon.fontIcon }}
    </mat-icon>
  </ng-container>

  <span
    class="button-text"
    [class.active]="options.active && !options.disabled"
  >
    {{ options.text }}
  </span>

  <mat-progress-spinner
    class="spinner"
    *ngIf="options.active && !options.disabled"
    [diameter]="options.spinnerSize"
    [color]="options.spinnerColor"
    [mode]="options.mode"
    [value]="options.value"
    [class.active]="options.active && !options.disabled"
  >
  </mat-progress-spinner>
</button>
