// Api Models
export interface PaymentEventModel {
  Name: string;
  Status: string;
  StatusText: string;
  Ts: number;
}

export interface PaymentModel {
  ID: string;
  UserID: string;
  Project: string;
  Provider: string;
  Payload: string;
  Product: string;
  Options: string;
  HTTPCallback: string;
  TransactionTs: number;
  LatestEventTs: number;
  Events: Array<PaymentEventModel>;
}

// Jarvis Models
export interface PaymentEvent {
  name: string;
  status: string;
  status_text: string;
  ts: number;
}

export interface Payment {
  id: string;
  user_id: string;
  project: string;
  provider: string;
  payload: string;
  product: string;
  transaction_ts: number;
  latestEvent?: PaymentEvent;
  events: Array<PaymentEvent>;
}

// create Payment Objects for view consumption
export const paymentFactory = (payment: PaymentModel): Payment => {
  const eventsArray: PaymentEventModel[] = payment.Events ? payment.Events : [];
  const [latestEvent, ...events]: Array<PaymentEvent> = eventsArray.map(
    (event: PaymentEventModel): PaymentEvent => ({
      name: event.Name,
      status: event.Status,
      status_text: event.StatusText,
      ts: event.Ts,
    }),
  );

  return Object.assign(
    {},
    {
      latestEvent,
      events,
      id: payment.ID,
      user_id: payment.UserID,
      project: payment.Project,
      provider: payment.Provider,
      payload: payment.Payload,
      product: payment.Product,
      transaction_ts: payment.TransactionTs,
    },
  );
};

// Payment form
export class PaymentForm {
  id: number = null;
}

export const paymentFormValidation = {
  id: {
    required: 'User ID is required.',
    pattern: 'User ID only accepts numbers.',
  },
};
