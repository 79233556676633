<button
  *ngIf="findings?.length"
  mat-icon-button
  matTooltip="Checks"
  [matMenuTriggerFor]="findingsMenu"
>
  <div [matBadge]="status.badge" matBadgeColor="warn" matBadgeSize="small">
    <mat-icon fontSet="material-icons-two-tone" [class]="status.class"
      >{{ status.icon }}
    </mat-icon>
  </div>
</button>
<mat-menu
  #findingsMenu="matMenu"
  [overlapTrigger]="false"
  xPosition="before"
  class="findings-menu"
>
  <mat-list dense class="checks-list">
    <div mat-subheader>Checker Findings</div>
    <mat-list-item
      class="check-item"
      *ngFor="let finding of findings"
      [class.linked]="!!finding.link"
      (click)="followLink(finding.link)"
    >
      <mat-icon
        fontSet="material-icons-two-tone"
        matListItemIcon
        [class]="finding.class"
        >{{ finding.icon }}
      </mat-icon>
      <span matListItemTitle>{{ finding.title }}</span>
      <span matListItemLine *ngIf="finding.message">{{ finding.message }}</span>
    </mat-list-item>
  </mat-list>
</mat-menu>
