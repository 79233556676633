import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Api } from '@app/providers/api/api';

@Injectable()
export class MaintenanceResolver implements Resolve<Observable<Response>> {
  constructor(private api: Api) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Response> {
    return this.api
      .rpc({
        repository: 'projects',
        handler: 'all',
      })
      .pipe(catchError((error: Response) => of(error)));
  }
}
