import { ValidatorRegex } from '@app/config-manager/editor/editor.model';

declare let kendo: any;

export class ValidateNumberFunction {
  static register(registerFunc = kendo.spreadsheet.defineFunction) {
    registerFunc('VALIDATE_NUMBER', function (str) {
      return ValidatorRegex.numericNotNull.test(str);
    }).args([['str', 'string']]);
  }
}
