export enum Beta {
  CmUseFastApi = 'jarvis:beta:cm_use_fast_api',
}

export const configurableBetas = [Beta.CmUseFastApi];

export interface BetaInfo {
  title: string;
  image: string;
  description: string;
}

export const betaInfo: Record<Beta, BetaInfo> = {
  [Beta.CmUseFastApi]: {
    title: 'Config Manager Fast API',
    image: './assets/beta/cm-use-fast-api.jpg',
    description:
      'This is a refactor of the core functionalities of Config Manager ' +
      'developed with Node.js, aims to be a more performant and ' +
      'maintainable project.',
  },
};
