import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError, from, of } from 'rxjs';
import { catchError, tap, concatMap, map } from 'rxjs/operators';

import { Api } from '@app/providers/api';
import {
  Notification,
  NotificationComponent,
  notificationComponentToNotification,
} from './push-notification.model';
import { ProjectService } from '@app/providers/projects';
import { NotificationMessageService } from '@app/shared/notification-message/notification-message.service';
import { ReportModel, AnalyticsModel } from './report/report.model';

@Injectable()
export class PushNotificationApiService {
  constructor(
    private projectService: ProjectService,
    private api: Api,
    private message: NotificationMessageService,
  ) {}

  private error(res) {
    this.message.open({ ...Api.replaceError(res) });
    return throwError(res);
  }

  getNotifications(): Observable<Notification[]> {
    const game = this.projectService.getProject()['short_name'];
    return this.api
      .dispatcher('ListNotification', {
        game,
        orderBy: 'DESC',
      })
      .pipe(catchError((res) => this.error(res)));
  }

  getNotification(id: string): Observable<NotificationComponent> {
    return this.api.dispatcher('GetNotification', { ID: id }).pipe(
      catchError(() => EMPTY),
      catchError((res) => this.error(res)),
    );
  }

  getDeliveryStatusOperation(id: string): Observable<ReportModel> {
    return this.api.dispatcher('GetDeliveryStatusOperation', { ID: id }).pipe(
      catchError(() => EMPTY),
      catchError((res) => this.error(res)),
    );
  }

  getAnalyticsOperation(id: string): Observable<AnalyticsModel> {
    return this.api.dispatcher('GetAnalyticsOperation', { ID: id }).pipe(
      catchError(() => EMPTY),
      catchError((res) => this.error(res)),
    );
  }

  updateNotification(
    notification: NotificationComponent,
  ): Observable<NotificationComponent> {
    return this.api
      .dispatcher(
        'UpdateNotification',
        notificationComponentToNotification(notification),
      )
      .pipe(
        tap(() => this.message.success()),
        catchError((res) => this.error(res)),
      );
  }

  deliverNotification(
    notification: NotificationComponent,
  ): Observable<NotificationComponent> {
    return this.api
      .dispatcher('DeliverNotification', {
        ID: notification.id,
      })
      .pipe(catchError((res) => this.error(res)));
  }

  deleteNotification(
    notification: NotificationComponent,
  ): Observable<NotificationComponent> {
    return this.api
      .dispatcher('DeleteNotification', {
        ID: notification.id,
      })
      .pipe(
        tap((res: any) => this.message.success()),
        catchError((res) => this.error(res)),
      );
  }

  stopNotification(
    notification: NotificationComponent,
  ): Observable<NotificationComponent> {
    return this.api
      .dispatcher('StopNotification', {
        ID: notification.id,
      })
      .pipe(
        tap((res: any) => this.message.success()),
        catchError((res) => this.error(res)),
      );
  }

  // TEMPLATE NOTIFICATIONS
  getTemplates(): Observable<Notification[]> {
    const game = this.projectService.getProject()['short_name'];
    return this.api
      .dispatcher('ListTemplate', {
        game,
        orderBy: 'DESC',
      })
      .pipe(catchError((res) => this.error(res)));
  }

  getTemplate(id: string): Observable<NotificationComponent> {
    return this.api.dispatcher('GetTemplate', { id }).pipe(
      catchError(() => EMPTY),
      catchError((res) => this.error(res)),
    );
  }

  updateTemplate(
    notification: NotificationComponent,
  ): Observable<NotificationComponent> {
    return this.api
      .dispatcher(
        'UpdateTemplate',
        notificationComponentToNotification(notification),
      )
      .pipe(
        tap((res: any) => this.message.success()),
        catchError((res) => this.error(res)),
      );
  }

  scheduleTemplate(
    notification: NotificationComponent,
  ): Observable<NotificationComponent> {
    return this.api
      .dispatcher('ScheduleTemplate', {
        ID: notification.id,
      })
      .pipe(catchError((res) => this.error(res)));
  }

  stopTemplate(
    notification: NotificationComponent,
  ): Observable<NotificationComponent> {
    return this.api
      .dispatcher('StopTemplate', {
        ID: notification.id,
      })
      .pipe(
        tap((res: any) => this.message.success()),
        catchError((res) => this.error(res)),
      );
  }

  deleteTemplate(
    notification: NotificationComponent,
  ): Observable<NotificationComponent> {
    return this.api
      .dispatcher('DeleteTemplate', {
        ID: notification.id,
      })
      .pipe(
        tap((res: any) => this.message.success()),
        catchError((res) => this.error(res)),
      );
  }

  deleteTokens(ids: string[]): Observable<{ id: string; status: string }> {
    let env = 'pro';
    const game = this.projectService.getProject()['short_name'];

    switch (game) {
      case 'rc':
      case 'cp':
        env = 'prod';
        break;
    }

    return from(ids).pipe(
      concatMap(
        (id) =>
          <Observable<{ id: string; status: string }>>(
            this.api.dispatcher('DeleteToken', { user_id: id, game, env }).pipe(
              map(() => ({ id, status: 'done' })),
              catchError(() => of({ id, status: 'error' })),
            )
          ),
      ),
    );
  }
}
