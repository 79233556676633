import { ModuleWithProviders, NgModule } from '@angular/core';

import { SessionGuardService } from './session-guard.service';
import { SessionService } from './session.service';

@NgModule({})
export class SessionModule {
  static forRoot(): ModuleWithProviders<SessionModule> {
    return {
      ngModule: SessionModule,
      providers: [SessionService, SessionGuardService],
    };
  }
}
