import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';

import { NavigationService } from '@app/shared/navigation/navigation.service';
import { TodayService } from '@app/today/today.service';
import { CheckerService } from '@app/providers/checker';

// Bootstrap component
@Component({
  selector: 'app-init',
  template: '<router-outlet></router-outlet>',
})
export class InitComponent implements OnInit {
  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private todayService: TodayService,
    private checkerService: CheckerService,
  ) {
    navigationService.saveDesiredUserNavigation();
    checkerService.doGlobalCheck().catch(console.error);
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return {
            outlet: route.outlet,
            url: event.urlAfterRedirects,
            params: route.snapshot.params,
            data: route.snapshot.data,
          };
        }),
        filter((route) => route.outlet === 'primary'),
      )
      .subscribe(({ url, params, data }) => {
        this.checkerService
          .doPageCheck(url, params, data.checks)
          .catch(console.error);
        this.titleService.setTitle(
          `${
            data['title'] ? data['title'] + ' - ' : ''
          }Jarvis Suite${this.todayService.getIcon(' ')}`,
        );
      });
  }
}
