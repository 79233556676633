import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { MatButtonLoaderOptions } from './button-loader.model';

@Component({
  selector: 'sp-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
})
export class ButtonLoaderComponent implements OnChanges {
  @Input() text: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() active: boolean;

  // Button Options
  options: MatButtonLoaderOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 19,
    raised: true,
    stroked: false,
    flat: false,
    type: 'submit',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'buttonLoader',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.options.disabled = changes.disabled.currentValue;
    }

    if (changes.type) {
      this.options.type = changes.type.currentValue;
    }

    if (changes.active) {
      this.options.active = changes.active.currentValue;
    }

    if (changes.text) {
      this.options.text = changes.text.currentValue;
    }
  }
}
