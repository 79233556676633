import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sp-tool-header-info',
  templateUrl: './tool-header-info.component.html',
  styleUrls: ['./tool-header-info.component.scss'],
})
export class ToolHeaderInfoComponent implements OnInit {
  @Input() id: number | string;
  @Input() icon: string = null;
  @Input() name = 'id';

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const routerId = this.route.snapshot.params
      ? this.route.snapshot.params.id
      : null;
    this.id = this.id ? this.id : routerId;
  }
}
