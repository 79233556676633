import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy',
})
@Injectable()
export class GroupByPipe implements PipeTransform {
  transform(items: any[] = [], field: string): any[] {
    const groupedObj = items.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});
    return Object.keys(groupedObj).map((key) => ({
      key,
      values: groupedObj[key],
    }));
  }
}
