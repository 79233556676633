import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  CheckerFindings,
  CheckerService,
  CheckerStatus,
} from '@app/providers/checker';
import { WindowRefService } from '@app/providers/window/window-ref.service';
import {
  getStyledFinding,
  getStyledStatus,
  StyledFinding,
  StyledStatus,
} from '@app/shared/checker-findings/checker-findings.model';

@Component({
  selector: 'sp-checker-findings',
  templateUrl: './checker-findings.component.html',
  styleUrls: ['./checker-findings.component.scss'],
})
export class CheckerFindingsComponent implements OnDestroy {
  findings: StyledFinding[];
  status: StyledStatus;

  private readonly subscription: Subscription;

  constructor(
    private service: CheckerService,
    private windowRefService: WindowRefService,
  ) {
    this.subscription = service.findings$.subscribe((findings) => {
      this.findings = [
        ...findings.error.map(getStyledFinding),
        ...findings.warn.map(getStyledFinding),
        ...findings.ok.map(getStyledFinding),
      ];
      this.status = getStyledStatus(findings);
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  followLink(link: string): void {
    if (link != null && link.length) {
      this.windowRefService.nativeWindow.open(link, '_blank');
    }
  }
}
