import { UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import cronstrue from 'cronstrue';

import { Cron } from '@app/shared/utils/cron';

export class RepeatComponentModel {
  every_times = 1;
  every_period = RepeatEvery.week;
  on_day = [];
  advanced_expression = '';
}

export const formToRepeatComponentModel = (
  form: UntypedFormGroup,
): RepeatComponentModel =>
  Object.assign(new RepeatComponentModel(), form.getRawValue());

export const createFormControls = (repeatModel: RepeatComponentModel) => ({
  every_times: [repeatModel.every_times],
  every_period: [repeatModel.every_period],
  on_day: [repeatModel.on_day],
  advanced_expression: [repeatModel.advanced_expression],
  advanced_expression_time: [],
  advanced_expression_day_month: [],
  advanced_expression_month: [],
  advanced_expression_day_week: [],
});

export const updateFormFromAdvancedCronExpression = (
  form: UntypedFormGroup,
  cron: Cron,
): void => {
  const { dayOfWeek } = cron
    .getPresetHandler(repeatTypeToCronType(RepeatEvery.week))
    .get();
  form.patchValue(
    {
      advanced_expression_time: `${cron.minute} ${cron.hour}`,
      advanced_expression_day_month: cron.dayOfMonth,
      advanced_expression_month: cron.month,
      advanced_expression_day_week: cron.dayOfWeek,
      on_day: dayOfWeek,
    },
    { emitEvent: false },
  );
};

export const updateCronExpressionFromForm = (
  form: UntypedFormGroup,
  cron: Cron,
): Cron => {
  const cronString = `${cron.minute} ${cron.hour} ${form
    .get('advanced_expression_day_month')
    .value.replace(/ /g, '')} ${form
    .get('advanced_expression_month')
    .value.replace(/ /g, '')} ${form
    .get('advanced_expression_day_week')
    .value.replace(/ /g, '')}`;
  return Cron.parse(cronString);
};

export const resetRepeatFormValidators = (form: UntypedFormGroup) => {
  if (form.get('every_period').value === RepeatEvery.expression) {
    form.get('advanced_expression').setValidators([validateCronExpression]);
    form
      .get('advanced_expression_day_month')
      .setValidators([Validators.required]);
    form.get('advanced_expression_month').setValidators([Validators.required]);
    form
      .get('advanced_expression_day_week')
      .setValidators([Validators.required]);
  } else {
    form.get('advanced_expression').setValidators(null);
    form.get('advanced_expression_day_month').setValidators(null);
    form.get('advanced_expression_month').setValidators(null);
    form.get('advanced_expression_day_week').setValidators(null);
  }
  if (form.get('every_period').value === RepeatEvery.week) {
    form.get('on_day').setValidators(validateWeekDaySelection);
  } else {
    form.get('on_day').setValidators(null);
  }
};

export const validateCronExpression = (control: AbstractControl) => {
  const isValidExpression = parseTextCronExpression(control.value);
  return isValidExpression ? null : { isWrongExpression: !isValidExpression };
};

export const validateWeekDaySelection = (control: AbstractControl) => {
  const isValid = control.value.length;
  return isValid ? null : { chooseDay: !isValid };
};

export const parseTextCronExpression = (cronExpression: string): string => {
  try {
    return cronstrue.toString(cronExpression);
  } catch (error) {
    return '';
  }
};

export const repeatTypeToCronType = (repeatType: RepeatEvery): string => {
  let cronType: string;
  switch (repeatType) {
    case RepeatEvery.expression:
      cronType = 'custom';
      break;
    case RepeatEvery.week:
      cronType = 'weekly';
      break;
    case RepeatEvery.month:
      cronType = 'monthly';
      break;
    default:
      cronType = 'daily';
      break;
  }
  return cronType;
};

export const cronTypeToRepeatType = (cronType: string): RepeatEvery => {
  let everyPeriod: RepeatEvery;
  switch (cronType) {
    case 'custom':
      everyPeriod = RepeatEvery.expression;
      break;
    case 'weekly':
      everyPeriod = RepeatEvery.week;
      break;
    case 'monthly':
      everyPeriod = RepeatEvery.month;
      break;
    default:
      everyPeriod = RepeatEvery.day;
      break;
  }
  return everyPeriod;
};

export enum RepeatEvery {
  'day' = 'day',
  'week' = 'week',
  'month' = 'month',
  'expression' = 'expression',
}

export enum RepeatOn {
  'monday' = 1,
  'tuesday' = 2,
  'wednesday' = 3,
  'thursday' = 4,
  'friday' = 5,
  'saturday' = 6,
  'sunday' = 0,
}

export const repeatOnMap: Map<number, string> = new Map([
  [1, 'M'],
  [2, 'T'],
  [3, 'W'],
  [4, 'T'],
  [5, 'F'],
  [6, 'S'],
  [0, 'S'],
]);

export const repeatFormErrors: any = {
  advanced_expression: {
    required: 'Advanced Cron expression is required',
    isWrongExpression: 'Wrong expression format.',
  },
  on_day: {
    chooseDay: 'A day selection is required.',
  },
};
