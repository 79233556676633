import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { iconByDay, TodayModel } from './today.model';

@Injectable({
  providedIn: 'root',
})
export class TodayService {
  getIcon(prefix = '', suffix = ''): string {
    const icon = this.get().icon;

    return icon ? `${prefix}${icon}${suffix}` : '';
  }

  getName(): string {
    return this.get().name;
  }

  getURL(): string {
    return this.get().url || '';
  }

  getImage(): string {
    return this.get().image || '';
  }

  get(): Partial<TodayModel> {
    const key = moment().format('DD-MM');

    return iconByDay.get(key) || {};
  }
}
