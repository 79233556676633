import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  RoutesRecognized,
  NavigationEnd,
} from '@angular/router';
import { Location } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';

import {
  MainTool,
  Tool,
  Sidenav,
  sidenavData,
  tools,
  mainTools,
} from '@app/shared/navigation/navigation.model';
import { Project, ProjectService } from '@app/providers/projects';
import { SelectedTool } from '@app/shared/navigation/navigation.model';
import { environment } from '@env/environment';

@Injectable()
export class NavigationService {
  selectedMainTool: MainTool;
  selectedTool: Tool;
  selectedTool$: ReplaySubject<SelectedTool> = new ReplaySubject();
  tools: Sidenav;
  tools$: ReplaySubject<Sidenav> = new ReplaySubject();
  isProjectPage$: ReplaySubject<boolean> = new ReplaySubject();
  project: Project;
  isFirstNavigatedPage = true;

  private mainToolReverseMap: Map<MainTool, string>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private projectService: ProjectService,
  ) {
    this.projectService.project$.subscribe(
      (project: Project) => (this.project = project),
    );

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => {
          let activeRoute = this.route;

          while (activeRoute.firstChild) {
            activeRoute = activeRoute.firstChild;
          }

          return activeRoute;
        }),
        mergeMap((activeRoute) => activeRoute.data),
      )
      .subscribe((data) => {
        this.setNavigation(data);

        this.isProjectPage$.next(this.isProjectPage());
        setTimeout(() => {
          this.isFirstNavigatedPage = false;
        }, 200);
      });

    // Store last base_href for staging env only
    if (environment.name === 'staging') {
      sessionStorage.setItem(
        'lastBaseHref',
        document.querySelector('base').getAttribute('href'),
      );
    }
  }

  includes(path: string, fullPath: boolean = false): boolean {
    return this.router.isActive(path, fullPath);
  }

  setNavigation(data: any): void {
    const { mainTool, tool } = data;

    if (!mainTool || !tool) {
      this.setMainTool(null);
      this.setTool(null);
    } else {
      const mainToolObj = mainTools.get(mainTool);
      const toolObj = tools.get(mainTool).get(tool);
      const sidenavTools = sidenavData.find(
        (sidenav) =>
          JSON.stringify(sidenav.maintool) === JSON.stringify(mainToolObj),
      );
      this.setMainTool(sidenavTools);
      this.setTool(toolObj);
      this.selectedTool$.next({
        mainTool: sidenavTools.maintool,
        tool: toolObj,
      });
    }
  }

  saveDesiredUserNavigation(): void {
    const ignoredUrlsRegExp = /^\/(login|maintenance|callback)/i;

    this.router.events
      .pipe(
        first((e: any) => e instanceof RoutesRecognized),
        filter((e: RoutesRecognized) => !ignoredUrlsRegExp.test(e.url)),
      )
      .subscribe((e: RoutesRecognized) => {
        sessionStorage.setItem('page', e.urlAfterRedirects);
      });
  }

  restoreDesiredUserNavigation(): void {
    const url = sessionStorage.getItem('page') || '/projects';

    if (!this.location.isCurrentPathEqualTo(url)) {
      this.router.navigateByUrl(url);
    }
  }

  isProjectPage(): boolean {
    return this.router.isActive('/projects', false);
  }

  private resetMainTool(): void {
    this.tools$.next(null);
    this.selectedMainTool = null;
    this.selectedTool = null;
  }

  setMainTool(sidenav: Sidenav): void {
    if (!sidenav) return this.resetMainTool();
    this.tools = sidenav;
    this.tools$.next(this.tools);
    this.selectedMainTool = sidenav.maintool;
  }

  setTool(tool: Tool): void {
    this.selectedTool = tool;
  }

  getLink(mainTool: MainTool, tool: Tool, relativeTool?: Tool): string {
    let link;
    if (mainTool && (relativeTool || tool)) {
      if (relativeTool) {
        link = mainTool.link + relativeTool.link;
      } else if (tool) {
        link = mainTool.link + tool.link;
      }
    } else {
      link = '/projects';
    }
    return this.replaceProjectPath(link);
  }

  replaceProjectPath = (
    link: string,
    project: Project = this.project,
  ): string => {
    if (!project) return '';
    return link.replace(/%\w+%/g, project.alias);
  };

  switchProject(project: Project): string {
    const toolLink = this.selectedTool ? this.selectedTool.link : '';
    const mainlink = this.selectedMainTool
      ? this.selectedMainTool.link
      : `/projects/${project.alias}`;
    return this.replaceProjectPath(`${mainlink + toolLink}`, project);
  }

  isActiveTool(tool: Tool): boolean {
    if (!this.selectedTool) return false;
    return tool.link === this.selectedTool.link;
  }

  isMainActiveTool(sidenav: Sidenav): boolean {
    if (!this.selectedMainTool) return false;
    return sidenav.maintool.link === this.selectedMainTool.link;
  }

  getSelectedMainToolId(): string {
    if (!this.selectedMainTool) return null;

    // Warmup on demand mainToolReverseMap cache
    if (!this.mainToolReverseMap) {
      this.mainToolReverseMap = new Map();
      mainTools.forEach((value, key) =>
        this.mainToolReverseMap.set(value, key),
      );
    }

    return this.mainToolReverseMap.get(this.selectedMainTool);
  }
}
