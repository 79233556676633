const OPERATOR_AND = 'AND';
export const OPERATOR_OR = 'OR';

export interface ListViewSegmentInterface {
  id: number;
  name: string;
  expression: string;
}

export class ListViewSegment implements ListViewSegmentInterface {
  constructor(
    public id: number = null,
    public name: string = null,
    public expression: string = null,
  ) {}
}

export interface SegmentInterface {
  id: number;
  expression: string;
}

export class Segment implements SegmentInterface {
  constructor(public id = null, public expression = null) {}
}

export interface SegmentMetaData {
  segments: Array<Segment>;
  nodes: Array<SegmentNode>;
  operator: string;
}

export interface SegmentNodeInterface extends SegmentMetaData {
  removeSegment(id: number): void;
  removeNode(id: number): void;
  addSegment(): void;
  addNode(): void;
  toggleOperator(operator: string): void;
  parse(map?: object): string;
}

export function createSegmentNodeWithOperatorAndSegment(
  operator: string,
  segments: Array<Segment>,
) {
  const node = new SegmentNode();
  node.operator = operator;
  node.segments = segments;

  return node;
}

export class SegmentNode implements SegmentNodeInterface {
  constructor(
    public operator = OPERATOR_AND,
    public segments = [],
    public nodes = [],
  ) {}

  removeSegment(id: number): void {
    this.segments.splice(id, 1);
  }

  removeNode(id: number): void {
    this.nodes.splice(id, 1);
  }

  addSegment(): void {
    if (!this.segments) this.segments = [];
    this.segments.push(new Segment());
  }

  addNode(): void {
    this.nodes.push(new SegmentNode());
  }

  toggleOperator(operator: string): void {
    if (operator !== OPERATOR_AND && operator !== OPERATOR_OR) return;
    this.operator = operator;
  }

  parse(segmentsList: any = null): string {
    const buffer = [];
    let expression = '';

    buffer.push('(');

    if (Array.isArray(this.segments)) {
      const totalSegments: number = this.segments.length;
      for (let i = 0; i < totalSegments; i++) {
        if (!this.segments.hasOwnProperty(i)) continue;
        const segment = this.segments[i];
        if (segment.id === null) return;
        if (segmentsList !== null && segmentsList.hasOwnProperty(segment.id)) {
          buffer.push(segmentsList[segment.id].expression);
        } else if (segment.expression === null) {
          return;
        } else {
          buffer.push(segment.expression);
        }
      }
    }

    if (Array.isArray(this.nodes)) {
      const totalNodes: number = this.nodes.length;
      for (let i = 0; i < totalNodes; i++) {
        if (!this.nodes.hasOwnProperty(i)) continue;
        const bLua = this.nodes[i].parse();
        if (bLua === null) return;
        buffer.push(bLua);
      }
    }

    buffer.push(')');

    const lastElement: number = buffer.length - 1;
    for (const index in buffer) {
      if (buffer.hasOwnProperty(index)) {
        expression += buffer[index];

        if (parseInt(index, 10) > 0 && parseInt(index, 10) < lastElement - 1) {
          expression += `) ${this.operator.toLowerCase()} (`;
        }
      }
    }

    return expression;
  }
}
