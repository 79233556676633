import {
  GIT_ID_HEADER,
  MetadataColumnLegacy,
  MetadataExcludedLegacy,
  UNDEFINED_ULID_VALUE,
  ValidationType,
} from '@app/config-manager/editor/editor.model';

export enum ConfigDataEvent {
  addColumn,
}

/**
 * Helper to merge rows data into config data
 */
// @dynamic
export class ConfigDataHelper {
  static readonly excludePrefix = 'sp_';

  private _data = [];
  private _template = {};
  private _columns = new Map<number, string>();
  private _excluded = new Map<number, MetadataExcludedLegacy>();
  private events = {
    [ConfigDataEvent.addColumn]: [],
  };
  private completed = false;

  /**
   * Creates a new instance
   *
   * @returns {ConfigDataHelper}
   */
  static create(): ConfigDataHelper {
    return new this();
  }

  /**
   * Checks if value is empty
   *
   * @param {string} value
   * @returns {boolean}
   */
  static isEmptyValue(value: string): boolean {
    return value == null || value === '';
  }

  /**
   * Checks if column is excluded
   *
   * @param {string} columnId
   * @returns {boolean}
   */
  static isExcludedColumn(columnId: string): boolean {
    return columnId && columnId.toString().startsWith(this.excludePrefix);
  }

  /**
   * Create an empty excluded column
   *
   * @param {string} name
   * @param {number} position
   * @param {any[]} data
   * @returns {MetadataExcludedLegacy}
   */
  static createExcludedColumn(
    name: string,
    position: number,
    data: any[] = [],
  ): MetadataExcludedLegacy {
    return { name, position, data };
  }

  /**
   * Default serializer, replaced with .withSerializer()
   *
   * @param k
   * @param v
   * @returns {any}
   */
  private serializer = (k, v) => v;

  /**
   * Add serializer
   *
   * @param {(key: string, value: string) => any} serializer
   * @returns {ConfigDataHelper}
   */
  withSerializer(
    serializer: (key: string, value: string) => any,
  ): ConfigDataHelper {
    this.serializer = serializer;

    return this;
  }

  /**
   * Add event listener by name
   *
   * @param {ConfigDataEvent} eventName
   * @param {Function} eventFn
   * @returns {ConfigDataHelper}
   */
  onEvent(eventName: ConfigDataEvent, eventFn: Function): ConfigDataHelper {
    this.events[eventName].push(eventFn);

    return this;
  }

  /**
   * Trigger event by name
   *
   * @param {ConfigDataEvent} eventName
   * @param args
   * @returns {ConfigDataHelper}
   */
  triggerEvent(eventName: ConfigDataEvent, ...args): ConfigDataHelper {
    this.events[eventName].forEach((fn) => fn(...args));

    return this;
  }

  /**
   * Returns the count of columns (non-excluded)
   *
   * @returns {number}
   */
  getColumnsCount(): number {
    return Object.keys(this._template).length;
  }

  /**
   * Returns the count of excluded columns
   *
   * @returns {number}
   */
  getExcludedCount(): number {
    return this._excluded.size;
  }

  /**
   * Adds a column header to the data
   *
   * @param {number} column
   * @param {string} value
   */
  addColumnHeader(column: number, value: string) {
    // Ignore empty headers
    if (ConfigDataHelper.isEmptyValue(value)) return;

    // If first row (headers row)
    this._columns.set(column, value);
    if (ConfigDataHelper.isExcludedColumn(value)) {
      this._excluded.set(
        column,
        ConfigDataHelper.createExcludedColumn(value, this.getColumnsCount()),
      );
    } else {
      this.triggerEvent(
        ConfigDataEvent.addColumn,
        value,
        this.getColumnsCount(),
      );
      this._template[value] = null;
    }
  }

  /**
   * Adds row to the data
   */
  addRow() {
    // Append an empty row to dataCols & excludedCols
    const newRow = Object.assign({}, this._template);
    // If the git Id header is found fill it with an undefined "ulid"
    if (GIT_ID_HEADER in newRow) {
      newRow[GIT_ID_HEADER] = UNDEFINED_ULID_VALUE;
    }
    this._data.push(newRow);
    this._excluded.forEach((excludedValue) => excludedValue.data.push(null));
  }

  /**
   * Adds a row column data
   *
   * @param {number} row
   * @param {number} column
   * @param {string} value
   */
  addRowColumnData(row: number, column: number, value: string) {
    while (this._data.length <= row) this.addRow();

    // Ignore empty data
    if (ConfigDataHelper.isEmptyValue(value)) return;

    // Ignore data when empty or column header is not defined
    if (!this._columns.has(column)) return;

    if (ConfigDataHelper.isExcludedColumn(this._columns.get(column))) {
      this._excluded.get(column).data[row] = value;
    } else {
      const key = this._columns.get(column);
      this._data[row][key] = this.serializer(key, value);
    }
  }

  getRowGitHeaderId(row: number): string {
    return this._data[row][GIT_ID_HEADER];
  }

  /**
   * Deletes provided row number
   *
   * @param {number} row
   */
  deleteRow(row: number) {
    this._data.splice(row, 1);
    this._excluded.forEach((excHeader) => excHeader.data.splice(row, 1));
  }

  /**
   * Detects empty rows and delete them
   */
  deleteEmptyRows() {
    const columns = Array.from(this._columns.entries()).filter(
      ([_, key]) => key !== GIT_ID_HEADER,
    );

    for (let row = this._data.length - 1; row >= 0; row--) {
      // Checks if the row has a value some value
      const hasValue = columns.some(([column, key]: [number, string]) => {
        if (ConfigDataHelper.isExcludedColumn(key)) {
          return this._excluded.get(column).data[row] != null;
        } else {
          return this._data[row][key] != null;
        }
      });

      // Only delete row if is not the last row
      if (!hasValue && this._data.length > 1) this.deleteRow(row);
    }
  }

  /**
   * Complete the data with final details
   */
  complete() {
    if (this.completed) return;

    this.deleteEmptyRows();

    // Protect against no data rows but headers
    if (!this._data.length) this.addRow();

    this.completed = true;
  }

  /**
   * Returns the inner stored data
   *
   * @returns {any[]}
   */
  get data(): any[] {
    this.complete();
    return this._data;
  }

  /**
   * Returns the inner stored excluded data
   *
   * @returns {MetadataExcludedLegacy[]}
   */
  get excluded(): any[] {
    this.complete();
    return Array.from(this._excluded.values());
  }
}
