import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared';
import { DependenciesDialogComponent } from './shared/dependencies-dialog/dependencies-dialog.component';
import { DependenciesDialogService } from './shared/dependencies-dialog/dependencies-dialog.service';
import { DataTablesModule } from '@app/shared/data-tables';

@NgModule({
  imports: [
    SharedModule,
    //TODO: Check if the module should be here too
    DataTablesModule,
  ],
  declarations: [DependenciesDialogComponent],
  providers: [DependenciesDialogService],
})
export class LomtModule {}
