import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { timer } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { untilComponentDestroyed } from '@app/shared/utils/component/helpers';
import { ActionCountdownData } from './action-countdown.model';

@Component({
  selector: 'sp-action-countdown',
  templateUrl: './action-countdown.component.html',
  styleUrls: ['./action-countdown.component.scss'],
})
export class ActionCountdownComponent implements OnDestroy {
  remainingTime = '';
  callingBack = false;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ActionCountdownData) {
    if (data.timeout) {
      timer(0, 1000)
        .pipe(
          untilComponentDestroyed(this),
          take(data.timeout + 1),
          finalize(() => this.callback()),
        )
        .subscribe((current) => (this.remaining = data.timeout - current));
    }
  }

  ngOnDestroy() {}

  private set remaining(value: number) {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    this.remainingTime = ` (${`00${minutes}`.slice(-2)}:${`00${seconds}`.slice(
      -2,
    )})`;
  }

  private callback() {
    this.callingBack = true;
    this.data.callback().finally(() => (this.callingBack = false));
  }
}
