import { Component, Input } from '@angular/core';

import { MetadataInfo } from './metadata-info.interface';

@Component({
  selector: 'bs-metadata-info',
  templateUrl: 'metadata-info.component.html',
  styleUrls: ['metadata-info.component.scss'],
})
export class MetadataInfoComponent {
  @Input() data: MetadataInfo;

  constructor() {}
}
