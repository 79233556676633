import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  forwardRef,
  ViewChild,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { Subject, ReplaySubject } from 'rxjs';

export const SELECT_SEARCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectListComponent),
  multi: true,
};

@Component({
  selector: 'sp-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  providers: [SELECT_SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class SelectListComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() placeholder: string;
  @Input() valueKey: string;
  @Input() options: any[];
  @Input() multiple: boolean;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() disableOptionCentering: boolean;
  @Input() error: string;
  @Output() change = new EventEmitter<any>();
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  private search = '';
  filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private _maxRows = 300;
  private _onDestroy = new Subject<void>();
  private selected = [];
  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  constructor(private _renderer: Renderer2) {}

  ngOnInit() {
    this.filterItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filterItems();
    }

    if (changes.selected) {
      this.writeValue(changes.selected.currentValue);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  writeValue(obj: any): void {
    this.selected = obj;
    this.filterItems();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  getValueFromOption(op: any) {
    if (!this.valueKey) return op;
    return op[this.valueKey];
  }

  getOptionFromValue(value: any) {
    if (!this.valueKey) return value;
    return this.options.find((op) => op[this.valueKey] === value);
  }

  getIsChecked(value: any) {
    return this.selected.some((id) => value.id === id);
  }

  getOptionDisplay(op: any) {
    return op.name || op;
  }

  private emitSelected() {
    this._onChange(this.selected);
    this.change.emit(this.selected);
  }

  onChange(event: MatCheckboxChange, item: any): void {
    this.updateSelectedFromChange(event, item);
    this.emitSelected();
  }

  onChangeFilter(value: string) {
    this.search = value.toLowerCase();
    this.filterItems();
  }

  clearAll() {
    this.selected = [];
    this.emitSelected();
  }

  private updateSelectedFromChange(event: MatCheckboxChange, { id }: any) {
    const exists = this.selected.some((itemId) => itemId === id);
    if (event.checked && !exists) {
      this.selected.push(id);
      return;
    }
    if (!event.checked && exists) {
      this.selected = this.selected.filter((itemId) => itemId !== id);
      return;
    }
  }

  onBlur(): void {
    this._onTouched();
  }

  private filterItems(): void {
    let result = this.options;
    if (this.search) {
      result = this.options.filter(
        (item) =>
          this.getOptionDisplay(item).toLowerCase().indexOf(this.search) > -1,
      );
    }

    const compareOptions = (option1: any, option2: any) => {
      const option1Checked = this.getIsChecked(option1);
      const option2Checked = this.getIsChecked(option2);

      if (option1Checked !== option2Checked) {
        return option1Checked && !option2Checked ? -1 : 1;
      }

      return (
        this.getOptionDisplay(option1).length -
        this.getOptionDisplay(option2).length
      );
    };

    result = result.sort(compareOptions).slice(0, this._maxRows);

    this.filteredItems.next(result);
  }
}
