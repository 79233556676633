<div class="header">
  <div class="search">
    <mat-form-field appearance="fill" floatLabel="never">
      <input
        matInput
        placeholder="Search project"
        #searchInput
        ngModel
        (ngModelChange)="search($event)"
      />
      <mat-icon matPrefix>search</mat-icon>
      <mat-icon matSuffix *ngIf="searchInput.value" (click)="clean(searchInput)"
        >close
      </mat-icon>
    </mat-form-field>
  </div>
  <div class="group" *ngIf="projectGroups.length > 1">
    <mat-button-toggle-group
      [value]="selectedGroup"
      (change)="selectGroup($event.value)"
    >
      <mat-button-toggle *ngFor="let group of projectGroups" [value]="group">{{
        group.id | titlecase
      }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<div class="results groups" #resultsRef>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="group-item" *ngFor="let resultGroup of resultGroups">
    <h3 class="title">{{ resultGroup[0] }}</h3>
    <div class="projects-wrapper">
      <div
        *ngFor="let project of resultGroup[1]"
        id="project-{{ project.short_name }}"
        class="project-item-wrapper"
        (click)="selectProject(project)"
      >
        <div
          class="favorite-action"
          [class.active]="resultGroup[0].startsWith('Favorite')"
          (click)="toggleFavorite(project)"
        >
          <mat-icon class="favorite-on">star</mat-icon>
          <mat-icon class="favorite-hover" fontSet="material-icons-two-tone"
            >star
          </mat-icon>
          <mat-icon class="favorite-off">star_border</mat-icon>
        </div>
        <sp-project-item [project]="project"></sp-project-item>
      </div>
    </div>
  </div>
  <div class="no-results" *ngIf="!loading && !resultGroups?.length">
    <em
      >No projects found for the search "<strong>{{ searchInput.value }}</strong
      >"</em
    >
  </div>
</div>
