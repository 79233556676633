import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { map, filter } from 'rxjs/operators';

import { AuthService } from '@app/providers/auth/auth.service';
import { environment } from '@env/environment';

declare let window: any;

@Injectable()
export class GoogleAnalyticsService {
  constructor(private router: Router, private auth: AuthService) {
    window.gtag('config', 'UA-74212025-2', {
      custom_map: {
        dimension1: 'user_email',
        dimension2: 'app_build',
      },
    });

    this.auth.profile$.subscribe(({ email }) => {
      if (!email) return;
      window.gtag('set', {
        user_id: email,
        user_email: email,
        app_build: environment.build,
      });
      window.gtag('event', 'screen_view', {
        screen_name: 'login',
        app_name: 'jarvis-console',
        app_version: environment.build,
      });
    });
  }

  trackLocation(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(({ urlAfterRedirects }: NavigationEnd) => ({
          url: urlAfterRedirects,
        })),
      )
      .subscribe(({ url }) =>
        window.gtag('config', 'UA-74212025-2', { page_path: url }),
      );
  }
}
