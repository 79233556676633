export interface ProjectRemoteConfig {
  studio: string;
  disabled: boolean;
}

export function parseProjectRemoteConfig(
  remoteJson: string,
): ProjectRemoteConfig {
  let studio = null;
  let disabled = false;
  try {
    const dataObj = JSON.parse(remoteJson);
    if ('studio' in dataObj) studio = dataObj.studio;
    if ('disabled' in dataObj) disabled = dataObj.disabled;
  } catch {}

  return {
    studio,
    disabled,
  };
}
