<div *ngIf="data" class="metadata-info">
  <mat-form-field>
    <mat-label>Created by</mat-label>
    <input
      matInput
      type="text"
      [value]="data.createdBy"
      name="createdBy"
      disabled
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Created at</mat-label>
    <input
      matInput
      type="text"
      [value]="data.createdAt | date: 'y-MM-dd HH:mm'"
      name="createdAt"
      disabled
    />
  </mat-form-field>
  <mat-form-field *ngIf="data.updatedBy">
    <mat-label>Updated by</mat-label>
    <input
      matInput
      type="text"
      [value]="data.updatedBy"
      name="updatedBy"
      disabled
    />
  </mat-form-field>
  <mat-form-field *ngIf="data.updatedBy">
    <mat-label>Updated at</mat-label>
    <input
      matInput
      type="text"
      [value]="data.updatedAt | date: 'y-MM-dd HH:mm'"
      name="updatedAt"
      disabled
    />
  </mat-form-field>
</div>
