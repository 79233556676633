import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';

import { AuthModule } from '@app/providers/auth/auth.module';
import { LayoutModule } from '@app/shared/layout/layout.module';
import { MaterialDesignModule } from '../md';
import { PipesModule } from '../pipes';
import { AtomsModule } from '@app/atoms/atoms.module';
import { NotificationMessageModule } from '../notification-message/notification-message.module';
import { ButtonLoaderModule } from '@app/shared/button-loader/button-loader.module';

const imports: any = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  HttpClientModule,
  ClipboardModule,

  // Jarvis
  AuthModule,
  LayoutModule,
  MaterialDesignModule,
  PipesModule,
  AtomsModule,
  ButtonLoaderModule,
  NotificationMessageModule,
];

@NgModule({
  imports: [...imports],
  exports: [...imports],
})
export class SharedModule {}
