import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared';
import { NavigationComponent } from './navigation.component';
import { NavigationService } from './navigation.service';
import { EmailPipe } from '@app/shared/data-tables/data-tables-email.pipe';

@NgModule({
  imports: [
    RouterModule,
    // Jarvis
    SharedModule,
  ],
  declarations: [NavigationComponent],
  providers: [NavigationService, EmailPipe],
  exports: [NavigationComponent],
})
export class NavigationModule {}
