<div class="login-page">
  <div class="form">
    <form class="login-form" autocomplete="off">
      <img src="assets/logo.png" alt="Social Point" width="40%" />
      <button
        mat-raised-button
        (click)="login()"
        [disabled]="loading || isAuthenticated || autoLogin"
        color="primary"
        data-protractor="login"
      >
        {{ autoLogin ? 'AUTO LOGIN...' : 'LOGIN' }}
      </button>
    </form>
  </div>
  <mat-card appearance="outlined" class="errors" *ngIf="authErrors">
    <ul class="login-errors">
      <li *ngFor="let error of authErrors">{{ error }}</li>
      <li>
        <a
          href="https://app.kissflow.com/?continue=/She8cbdbb1_210a_11ec_8a59_06a2f9ffd45f/create"
          target="_blank"
        >
          → Request access in our Kissflow form.
        </a>
        <br />
        <a
          href="https://socialpoint.atlassian.net/wiki/spaces/~205963462/pages/3638853653/User+Permissions+Critical+Apps+Kissflow"
          target="_blank"
        >
          → Documentation about how to send a request access in Kissflow.
        </a>
      </li>
    </ul>
  </mat-card>
</div>
