/**
 * RoundList - inspired by https://gist.github.com/astronom/9806c7d517f340e2abc1
 */
export class RoundList {
  /**
   * Get the factor of the values
   *
   * @returns {Number}
   */
  private static errorFactor(oldNum: number, newNum: number): number {
    return Math.abs(oldNum - newNum) / oldNum;
  }

  constructor(public orig: Array<number>, public target: number = 100) {}

  /**
   * Calculate the rounded percentages that add up to 100%
   *
   * @returns {Number|Array}
   */
  calculate(): Array<number> {
    if (!Array.isArray(this.orig)) return [];
    let i = this.orig.length;
    let j = 0;
    let total = 0;
    let next;
    let factor1;
    let factor2;
    const newVals = [];
    const len = this.orig.length;
    const marginOfErrors = [];

    // map original values to new array
    while (i--) {
      total += newVals[i] = Math.round(this.orig[i]);
    }

    const change = total < this.target ? 1 : -1;

    while (total !== this.target) {
      // select number that will be less affected by change determined
      // in terms of itself e.g. Incrementing 10 by 1 would mean
      // an error of 10% in relation to itself.
      for (i = 0; i < len; i++) {
        next = i === len - 1 ? 0 : i + 1;

        factor2 = RoundList.errorFactor(
          this.orig[next],
          newVals[next] + change,
        );
        factor1 = RoundList.errorFactor(this.orig[i], newVals[i] + change);

        if (factor1 > factor2) {
          j = next;
        }
      }

      newVals[j] += change;
      total += change;
    }

    for (i = 0; i < len; i++) {
      marginOfErrors[i] =
        newVals[i] && Math.abs(this.orig[i] - newVals[i]) / this.orig[i];
    }

    for (i = 0; i < len; i++) {
      for (j = 0; j < len; j++) {
        if (j === i) continue;

        const roundUpFactor =
          RoundList.errorFactor(this.orig[i], newVals[i] + 1) +
          RoundList.errorFactor(this.orig[j], newVals[j] - 1);
        const roundDownFactor =
          RoundList.errorFactor(this.orig[i], newVals[i] - 1) +
          RoundList.errorFactor(this.orig[j], newVals[j] + 1);
        const sumMargin = marginOfErrors[i] + marginOfErrors[j];

        if (roundUpFactor < sumMargin) {
          newVals[i] = newVals[i] + 1;
          newVals[j] = newVals[j] - 1;
          marginOfErrors[i] =
            newVals[i] && Math.abs(this.orig[i] - newVals[i]) / this.orig[i];
          marginOfErrors[j] =
            newVals[j] && Math.abs(this.orig[j] - newVals[j]) / this.orig[j];
        }

        if (roundDownFactor < sumMargin) {
          newVals[i] = newVals[i] - 1;
          newVals[j] = newVals[j] + 1;
          marginOfErrors[i] =
            newVals[i] && Math.abs(this.orig[i] - newVals[i]) / this.orig[i];
          marginOfErrors[j] =
            newVals[j] && Math.abs(this.orig[j] - newVals[j]) / this.orig[j];
        }
      }
    }

    return newVals;
  }
}
