import { Cron } from '../cron';
import { CronPreset } from './index';

export const presetDaily: CronPreset = {
  id: 'daily',
  default: '0 0 * * *',

  detect: (cron: Cron): boolean =>
    cron.minute !== '*' &&
    cron.hour !== '*' &&
    cron.dayOfMonth === '*' &&
    cron.month === '*' &&
    cron.dayOfWeek === '*',

  set: (cron: Cron, params: { minute: number; hour: number }): Cron => {
    const { minute, hour } = params;

    cron.minute = minute != null ? minute.toString() : cron.minute;
    cron.hour = hour != null ? hour.toString() : cron.hour;
    cron.dayOfMonth = '*';
    cron.month = '*';
    cron.dayOfWeek = '*';

    return cron;
  },

  get: (cron: Cron): { minute: number; hour: number } => ({
    minute: parseInt(cron.minute, 10),
    hour: parseInt(cron.hour, 10),
  }),
};
