import { JsonValidator } from '@app/config-manager/editor/editor.model';

declare let kendo: any;

export class ValidateJsonFunction {
  static register(registerFunc = kendo.spreadsheet.defineFunction) {
    registerFunc('VALIDATE_JSON', JsonValidator).args([
      ['str', 'string'],
      ['allowNull', 'boolean'],
    ]);
  }
}
