import {
  Component,
  OnInit,
  Inject,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { ProjectService } from '@app/providers/projects';
import {
  DependenciesDataSource,
  dependenciesMap,
  Actions,
  actionHeaderText,
  Types,
} from './dependencies-dialog.model';
import { DependenciesDialogService } from './dependencies-dialog.service';

@Component({
  selector: 'app-dependencies-dialog',
  templateUrl: './dependencies-dialog.component.html',
  styleUrls: ['./dependencies-dialog.component.scss'],
})
export class DependenciesDialogComponent implements OnInit, AfterViewInit {
  @ViewChildren(MatPaginator) paginator: QueryList<MatPaginator>;

  type: Types;
  types = Types;
  action: Actions;
  actions = Actions;
  actionText = actionHeaderText;
  tabData: any[] = [];
  project: string;
  hasLiveDependencies: boolean;
  updating = false;

  constructor(
    private projectService: ProjectService,
    private dialogService: DependenciesDialogService,
    private dialogRef: MatDialogRef<DependenciesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.project = this.projectService.getProject()['alias'];
    this.type = this.data.type;
    this.action = this.data.action;
    if (this.data.dependencies) {
      this.update(this.data.dependencies);
    } else {
      this.fetch();
    }
  }

  ngAfterViewInit() {
    const paginator = this.paginator.toArray();
    this.tabData.forEach((tab: any, index: number) => {
      (tab.dataSource as MatTableDataSource<any>).paginator = paginator[index];
    });
  }

  fetch(): void {
    if (!this.data.id) return;
    this.updating = true;
    if (this.type === Types.content) {
      this.dialogService
        .getContentDependencies(this.data.id)
        .subscribe((dependencies) => {
          this.update(dependencies);
          this.updating = false;
        });
    }
  }

  update(data): void {
    this.hasLiveDependencies = this.dialogService.hasLiveDependencies(data);
    if (this.hasLiveDependencies) {
      this.tabData = Object.keys(data)
        .filter((dependency) => data[dependency] !== null)
        .map((dependency) => {
          const dataSource = new DependenciesDataSource(
            dependenciesMap.get(dependency).createFn(data[dependency]),
          );
          return {
            dataSource,
            label: dependenciesMap.get(dependency).name,
            length: dataSource.data.length,
            columns: dependenciesMap.get(dependency).columns,
            linkFn: dependenciesMap.get(dependency).link,
          };
        });
    } else {
      this.tabData = [];
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.dialogRef.close(true);
  }

  delete(): void {
    this.dialogRef.close(true);
  }
}
