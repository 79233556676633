import { Cron } from '../cron';
import { CronPreset } from './index';
import { monthParse, monthUnparse } from './helpers';

const monthRegex = /^(\*|\d+\/\d+)$/;

export const presetMonthly: CronPreset = {
  id: 'monthly',
  default: '0 0 1 * *',

  detect: (cron: Cron): boolean =>
    cron.minute !== '*' &&
    cron.hour !== '*' &&
    cron.dayOfMonth !== '*' &&
    monthRegex.test(cron.month) &&
    cron.dayOfWeek === '*',

  set: (
    cron: Cron,
    params: {
      minute: number;
      hour: number;
      dayOfMonth: number;
      every: number;
      month: number;
    },
  ): Cron => {
    const { minute, hour, dayOfMonth, every, month } = params;

    cron.minute = minute != null ? minute.toString() : cron.minute;
    cron.hour = hour != null ? hour.toString() : cron.hour;
    cron.dayOfMonth =
      dayOfMonth != null ? dayOfMonth.toString() : cron.dayOfMonth;
    cron.dayOfWeek = '*';
    cron.month = every != null ? monthUnparse(every, month) : cron.month;

    return cron;
  },

  get: (
    cron: Cron,
  ): { minute: number; hour: number; dayOfMonth: number; every: number } => ({
    minute: parseInt(cron.minute, 10),
    hour: parseInt(cron.hour, 10),
    dayOfMonth: parseInt(cron.dayOfMonth, 10),
    every: monthParse(cron.month),
  }),
};
