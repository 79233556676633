declare let kendo: any;

// @dynamic
export class FormatByColumnValidationDecorator {
  private static targetFunc;

  static register(
    getColumnValidation: (col: number) => string,
    targetFunc = kendo.spreadsheet.calc.parse,
    replaceFunc = (decoratedFunc) =>
      (kendo.spreadsheet.calc.parse = decoratedFunc),
  ) {
    this.targetFunc = targetFunc;

    replaceFunc((sheet, row, col, input, format) => {
      // Code before target function
      if (row === 0 || getColumnValidation(col) === 'string') {
        return { type: 'string', value: input };
      }

      // Execute target function
      return targetFunc(sheet, row, col, input, format);
    });
  }

  static unregister(
    replaceFunc = (decoratedFunc) =>
      (kendo.spreadsheet.calc.parse = decoratedFunc),
  ) {
    if (this.targetFunc != null) {
      replaceFunc(this.targetFunc);
    }
  }
}
