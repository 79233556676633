<sp-header-bar [sidenav]="sidenav"></sp-header-bar>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav>
    <sidenav-viewer
      *ngIf="!!project"
      [tools]="sidenavTools"
      [sidenav]="sidenav"
    >
    </sidenav-viewer>
  </mat-sidenav>
  <mat-sidenav-content #sidenavContent>
    <div class="app-nav-content">
      <sp-navigation
        *ngIf="sidenavTool"
        [sidenavData]="sidenavTool"
        [sidenav]="sidenav"
      ></sp-navigation>
      <div class="app-content layout-center">
        <router-outlet></router-outlet>
      </div>
      <div
        *ngIf="!sidenavTool && isProjectPage && !project"
        class="app-background"
      >
        <img src="./assets/logo_white.png" />
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
