import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Project } from '@app/providers/projects';
import { projectGroups, ProjectGroup } from './project-selector.model';
import { recentProjects } from './plugin/recent';
import { favoriteProjects } from './plugin/favorites';
import { SearchNavigator } from './plugin/search-navigator';

@Component({
  selector: 'sp-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss'],
})
export class ProjectSelectorComponent implements OnInit {
  @ViewChild('resultsRef', { read: ElementRef, static: true })
  resultsRef: ElementRef;
  @ViewChild('searchInput', { read: ElementRef, static: true })
  searchInputRef: ElementRef;
  @Input() projects: Project[];
  @Output() select: EventEmitter<Project> = new EventEmitter();

  selectedGroup: ProjectGroup;
  projectGroups = projectGroups;
  resultGroups: any[];
  loading = true;
  searchNavigator: SearchNavigator;

  private onSearch = new Subject<string>();
  private onGroup = new Subject<ProjectGroup>();
  private onRefresh = new Subject<void>();

  constructor(private cd: ChangeDetectorRef) {
    combineLatest([
      this.onGroup,
      this.onSearch.pipe(debounceTime(200), distinctUntilChanged()),
      this.onRefresh,
    ]).subscribe(([group, search]) => {
      this.filterResults(group, search);
      this.loading = false;
    });

    this.selectGroup(projectGroups[0]);
    this.search('');
    this.onRefresh.next();
  }

  ngOnInit() {
    this.projects = this.projects.sort((a, b) =>
      a.alias.localeCompare(b.alias),
    );
  }

  search(text: string) {
    this.onSearch.next(text);
  }

  clean(input: HTMLInputElement) {
    input.value = '';
    this.search('');
  }

  selectGroup(group: ProjectGroup) {
    this.selectedGroup = group;
    this.onGroup.next(group);
  }

  selectProject(project: Project) {
    recentProjects.add(project);
    this.select.emit(project);
  }

  toggleFavorite(project: Project) {
    favoriteProjects.toggle(project);
    this.onRefresh.next();
  }

  private filterResults(group: ProjectGroup, search: string) {
    const searchRE = new RegExp(search.split('').join('.*'), 'i');
    this.resultGroups = group.groupProjects(
      this.projects.filter((p) => searchRE.test(p.name)),
    );
    this.searchNavigator = new SearchNavigator(this.resultGroups);

    if (search) {
      this.cd.detectChanges();
      this.highlightProject(this.searchNavigator.selected);
    }
  }

  private highlightProject(project: Project) {
    if (!project) return;

    const selected =
      this.resultsRef.nativeElement.querySelector(`.js-selected`);
    if (selected) {
      selected.classList.remove('js-selected');
    }

    const target = this.resultsRef.nativeElement.querySelector(
      `#project-${project.short_name}`,
    );
    if (target) {
      target.classList.add('js-selected');
      target.scrollIntoView({ block: 'center' });
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown($event: KeyboardEvent) {
    // Shortcut "Left arrow"
    if ($event.code === 'ArrowLeft') {
      $event.preventDefault();
      this.searchNavigator.moveLeft();
      this.highlightProject(this.searchNavigator.selected);

      // Shortcut "Right arrow"
    } else if ($event.code === 'ArrowRight') {
      $event.preventDefault();
      this.searchNavigator.moveRight();
      this.highlightProject(this.searchNavigator.selected);

      // Shortcut "Up arrow"
    } else if ($event.code === 'ArrowUp') {
      $event.preventDefault();
      this.searchNavigator.moveUp();
      this.highlightProject(this.searchNavigator.selected);

      // Shortcut "Down arrow"
    } else if ($event.code === 'ArrowDown') {
      $event.preventDefault();
      this.searchNavigator.moveDown();
      this.highlightProject(this.searchNavigator.selected);

      // Shortcut "Enter"
    } else if ($event.code === 'Enter') {
      $event.preventDefault();
      this.selectProject(this.searchNavigator.selected);

      // Shortcut "Any Key Character" will focus
    } else if ($event.code && $event.code.startsWith('Key')) {
      if ($event.target !== this.searchInputRef.nativeElement) {
        this.searchInputRef.nativeElement.focus();
      }
    }
  }
}
