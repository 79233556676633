import { CronPreset, cronPresets } from './preset';

export interface CronPresetHandler {
  set: (params: { [key: string]: any }) => Cron;
  get: () => { [key: string]: any };
}

export class Cron {
  /**
   * Create an instance from a cron string
   *
   * @returns {Cron}
   */
  static parse(cronString: string): Cron {
    return new Cron(...cronString.split(' '));
  }

  /**
   * Create an instance from a preset
   *
   * @param {string} presetId
   * @param {object} params
   * @returns {Cron}
   */
  static fromPreset(presetId: string, params?: { [key: string]: any }): Cron {
    const preset = Cron.getPreset(presetId);
    const cron = Cron.parse(preset.default);
    if (params) {
      preset.set(cron, params);
    }

    return cron;
  }

  /**
   * Fetch a cron preset
   *
   * @param {string} presetId
   * @returns {CronPreset}
   */
  static getPreset(presetId: string): CronPreset {
    const preset = cronPresets.filter((p) => p.id === presetId).pop();
    if (!preset) {
      throw new Error(`Preset not found "${presetId}"`);
    }

    return preset;
  }

  constructor(
    public minute = '0',
    public hour = '0',
    public dayOfMonth = '*',
    public month = '*',
    public dayOfWeek = '*',
  ) {}

  detectPreset(): string {
    for (const preset of cronPresets) {
      if (preset.detect(this)) return preset.id;
    }

    return null;
  }

  getPresetHandler(presetId: string): CronPresetHandler {
    const preset = Cron.getPreset(presetId);

    return {
      set: (params) => preset.set(this, params),
      get: () => preset.get(this),
    };
  }

  parseAndUpdate(cronString: string): Cron {
    return Object.assign(this, Cron.parse(cronString));
  }

  unparse(): string {
    return `${this.minute} ${this.hour} ${this.dayOfMonth} ${this.month} ${this.dayOfWeek}`;
  }
}
