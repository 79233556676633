import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'jarvis-maintenance',
  templateUrl: 'maintenance.component.html',
  styleUrls: ['maintenance.component.scss'],
})
export class MaintenanceComponent {
  constructor(private router: Router, private route: ActivatedRoute) {
    if (!Array.isArray(this.route.snapshot.data['maintenance'])) return;

    this.router.navigate(['/login']);
  }
}
