import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SidenavViewerComponent } from './sidenav-viewer.component';

import { SharedModule } from '@app/shared/shared';

@NgModule({
  imports: [
    // Jarvis,
    SharedModule,
  ],
  declarations: [SidenavViewerComponent],
  exports: [SidenavViewerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SidenavViewerModule {}
