import { Injectable } from '@angular/core';

import {
  SegmentNode,
  createSegmentNodeWithOperatorAndSegment,
} from './segments.model';

@Injectable()
export class SegmentsService {
  static compileNodes(segmentation: SegmentNode): SegmentNode {
    const node = new SegmentNode(segmentation.operator, segmentation.segments);

    const parseNode = (nodes: Array<SegmentNode>, parent: SegmentNode) => {
      for (const n in nodes) {
        if (nodes.hasOwnProperty(n)) {
          const bNode = createSegmentNodeWithOperatorAndSegment(
            nodes[n].operator,
            nodes[n].segments,
          );

          if (Array.isArray(nodes[n].nodes) && nodes[n].nodes.length) {
            parseNode(nodes[n].nodes, bNode);
          }

          parent.nodes.push(bNode);
        }
      }
    };

    if (Array.isArray(segmentation.nodes) && segmentation.nodes.length) {
      parseNode(segmentation.nodes, node);
    }

    return node;
  }
}
