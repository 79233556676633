import { Component, Input } from '@angular/core';

@Component({
  selector: 'sp-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent {
  @Input('maxWidth') maxWidth: number = null;
  @Input('noGap') noGap = false;
}
