import { Component, Input } from '@angular/core';

@Component({
  selector: 'sp-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input('title') title: string;
  @Input('tooltip') tooltip = '';
  @Input('padding') padding = true;
}
