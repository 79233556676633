import { Inject, Injectable, InjectionToken } from '@angular/core';
import { GoogleApiConfig, NgGapiClientConfig } from './google-api.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  GoogleUser,
  GSITokenCallback,
  GSITokenClient,
  TokenResponse,
} from '@app/providers/google/auth/google-auth.models';

declare let gapi: any;

export const NG_GAPI_CONFIG: InjectionToken<NgGapiClientConfig> =
  new InjectionToken<NgGapiClientConfig>('google.api.config');

@Injectable()
export class GoogleApiService {
  private readonly gisUrl: string = 'https://accounts.google.com/gsi/client';
  private readonly gapiUrl: string = 'https://apis.google.com/js/api.js';
  private config: GoogleApiConfig;
  private gapiLoaded: Promise<void>;
  private gisLoaded: Promise<void>;
  private gapiAuthLoaded: Promise<any>;
  private gapiSheetsLoaded: Promise<any>;
  private gapiDriveLoaded: Promise<any>;

  constructor(
    @Inject(NG_GAPI_CONFIG) config: NgGapiClientConfig,
    private http: HttpClient,
  ) {
    this.config = new GoogleApiConfig(config);
    this.loadGapi();
  }

  onAuthLoaded(): Promise<any> {
    if (!this.gapiAuthLoaded) {
      this.gapiAuthLoaded = this.loadGIS();
    }

    return this.gapiAuthLoaded;
  }

  initGISClient(callback: GSITokenCallback): GSITokenClient {
    // @ts-ignore
    return google.accounts.oauth2.initTokenClient({
      ...this.config.getClientConfig(),
      callback,
    });
  }

  hasGrantedAllScopes(token: TokenResponse, ...scopes: string[]) {
    // @ts-ignore
    return google.accounts.oauth2.hasGrantedAllScopes(token, ...scopes);
  }

  getUserProfile(token: string): Observable<GoogleUser> {
    return this.http.get<GoogleUser>(
      `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${encodeURIComponent(
        token,
      )}`,
    );
  }

  onSheetsLoaded(): Promise<any> {
    if (!this.gapiSheetsLoaded) {
      this.gapiSheetsLoaded = this.loadGapi()
        .then(() => gapi.client.load('sheets', 'v4'))
        .then(() => gapi.client.sheets);
    }

    return this.gapiSheetsLoaded;
  }

  onDriveLoaded(): Promise<any> {
    if (!this.gapiDriveLoaded) {
      this.gapiDriveLoaded = this.loadGapi()
        .then(() => gapi.client.load('drive', 'v3'))
        .then(() => gapi.client.drive);
    }

    return this.gapiDriveLoaded;
  }

  loadGapi(): Promise<void> {
    if (!this.gapiLoaded) {
      this.gapiLoaded = new Promise((resolve) => {
        const node = document.createElement('script');
        node.src = this.gapiUrl;
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
        node.onload = () => {
          gapi.load('client', () => {
            gapi.client.init({});
            resolve();
          });
        };
      });
    }

    return this.gapiLoaded;
  }

  loadGIS(): Promise<void> {
    if (!this.gisLoaded) {
      this.gisLoaded = new Promise((resolve) => {
        const node = document.createElement('script');
        node.src = this.gisUrl;
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
        node.onload = () => resolve();
      });
    }

    return this.gisLoaded;
  }

  revoke(email: string): Promise<void> {
    return new Promise((resolve) => {
      // @ts-ignore
      google.accounts.id.revoke(email, () => resolve());
    });
  }
}
