import {
  Component,
  Input,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'sp-table-nested-rows',
  templateUrl: 'table-nested-rows.component.html',
  styleUrls: ['table-nested-rows.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableNestedRowsComponent {
  @Input() columnsDef: object[];
  @Input() rowsDef: object;
  @Input() data: object[];

  constructor() {}
}
