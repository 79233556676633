import { Project } from '@app/providers/projects';

export enum Studio {
  live = 'Live Games',
  one = 'Studio 1',
  five = 'Studio 5',
  kraken = 'KrakenLab',
  tactical = 'Tactical',
  beetle = 'Beetle Gym',
  technology = 'Technology',
  testing = 'Testing',
  dots = 'Dots',
  juntos = 'Juntos',
  gaudi = 'Gaudi',
  zynga = 'Zynga',
  naturalMotion = 'NaturalMotion',
  gramGames = 'GramGames',
  zyngaStudioI = 'Zynga Studio-I',
}

export const studioOrder = [
  Studio.live,
  Studio.one,
  Studio.five,
  Studio.kraken,
  Studio.tactical,
  Studio.beetle,
  Studio.technology,
  Studio.juntos,
  Studio.gaudi,
  Studio.dots,
  Studio.zynga,
  Studio.naturalMotion,
  Studio.gramGames,
  Studio.zyngaStudioI,
  Studio.testing,
];

export function studioGroupFn(project: Project): string {
  return project.config.studio ? project.config.studio : Studio.testing;
}
