import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { Hub, ConsoleLogger } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import { AUTH_CONFIG } from '@app/providers/auth/auth.model';
import {
  AuthCognitoConfig,
  CognitoUserData,
} from '@app/providers/auth/cognito/cognito.model';
import { first } from 'rxjs/operators';

@Injectable()
export class CognitoService {
  user: CognitoUser;
  userChanges$ = new ReplaySubject<CognitoUser>(1);
  authErrors: string[] = [];

  private sessionChanges$ = new ReplaySubject<CognitoUserSession>(1);
  private config: AuthCognitoConfig;
  private flowType;

  constructor(@Inject(AUTH_CONFIG) config: AuthCognitoConfig) {
    // Filtering forbidden urls if defined
    if (
      config.forbiddenPaths &&
      config.forbiddenPaths.some(
        (url) => window.location.href.indexOf(url) !== -1,
      )
    ) {
      return;
    }
    // Cognito auth logger, set to "DEBUG" for debugging
    ConsoleLogger.LOG_LEVEL = config.debug ? 'DEBUG' : null;

    this.config = config;
    this.listenEvents();
    this.configure();
  }

  configure() {
    Auth.configure({
      identityPoolId: this.config.identityPoolId,
      region: this.config.region,
      userPoolId: this.config.userPoolId,
      userPoolWebClientId: this.config.userPoolWebClientId,
      mandatorySignIn: true,
      oauth: {
        domain: this.config.appClientDomain,
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: this.config.redirectSignIn,
        redirectSignOut: this.config.redirectSignOut,
        responseType: 'code',
      },
    });
  }

  listenEvents() {
    Hub.listen('auth', ({ payload: { event, data = {} } }) => {
      if (event === 'parsingCallbackUrl') {
        this.fetchErrors(new URL(data.url));
      } else if (event === 'signIn') {
        this.fetchAuthenticatedUser().catch((err) =>
          console.error(`Unexpected Error: onEvent=${event} error=${err}`),
        );
      } else if (event === 'signIn_failure') {
        this.setUser(null);
      } else if (event === 'implicitFlow') {
        this.flowType = 'implicit';
      } else if (event === 'codeFlow') {
        this.flowType = 'code';
      } else if (event === 'configured') {
        if (!this.flowType) {
          this.fetchAuthenticatedUser().catch((err) =>
            console.warn(`Warn: ${err}`),
          );
        }
      }
    });
  }

  async login(): Promise<unknown> {
    return Auth.federatedSignIn({
      customProvider: this.config.identityProvider,
    });
  }

  async logout(): Promise<void> {
    return Auth.signOut();
  }

  async getCredentials(): Promise<any> {
    return Auth.currentUserCredentials();
  }

  async getEssentialCredentials(): Promise<any> {
    const credentials = await this.getCredentials();

    return Auth.essentialCredentials(credentials);
  }

  async getSession(): Promise<CognitoUserSession> {
    const session = await firstValueFrom<CognitoUserSession>(
      this.sessionChanges$.pipe(
        first((sessionChange) => sessionChange != null),
      ),
    );

    if (!session.isValid()) {
      return await this.requestSession();
    }

    return session;
  }

  async getIdToken(): Promise<string> {
    return (await this.getSession()).getIdToken().getJwtToken();
  }

  async getAccessToken(): Promise<string> {
    return (await this.getSession()).getAccessToken().getJwtToken();
  }

  async getGroups(): Promise<string[]> {
    return (await this.getSession()).getAccessToken().payload['cognito:groups'];
  }

  async getUserData(): Promise<CognitoUserData> {
    return Auth.currentUserInfo();
  }

  async verifyUserAttributes(user: CognitoUser): Promise<void> {
    await Auth.userAttributes(user);
  }

  private setUser(user: CognitoUser) {
    if (this.user !== user) {
      this.user = user;
      this.userChanges$.next(user);

      this.requestSession().catch((error) => {
        console.error(error);
      });
    }
  }

  private async requestSession(): Promise<CognitoUserSession> {
    let session: CognitoUserSession;
    try {
      session = await Auth.currentSession();
    } catch {
      session = null;
    }
    this.sessionChanges$.next(session);

    return session;
  }

  private fetchErrors(url: URL) {
    const error = url.searchParams.get('error');
    const errorDescription = url.searchParams.get('error_description');

    if (error != null) {
      this.authErrors.push(`Error: ${error}`);
      if (errorDescription != null) {
        this.authErrors.push(`Description: ${errorDescription.split(';')[0]}`);
      }
    }
  }

  private async fetchAuthenticatedUser(): Promise<CognitoUser> {
    let user;

    try {
      user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    } catch (err) {
      this.setUser(null);
      throw new Error('User is not authenticated');
    }

    try {
      await this.verifyUserAttributes(user);
    } catch (e) {
      await this.logout();
      throw new Error('User has no attributes verified');
    }

    this.setUser(user);
    return user;
  }
}
