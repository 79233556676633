import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared';
import { AppRoutingModule } from './app-routing.module';
import { NavigationModule } from './shared/navigation/navigation.module';
import { SidenavViewerModule } from './shared/sidenav-viewer/sidenav-viewer.module';
import { LayoutModule } from './shared/layout/layout.module';
import { HeaderBarModule } from './shared/header-bar/header-bar.module';
import { AppComponent } from './app.component';
import { InitComponent } from './app-init.component';
import { swConfig } from '@env/environment';
import { HomeModule } from '@app/home/home.module';
import { ActionCountdownModule } from '@app/shared/action-countdown/action-countdown.module';

@NgModule({
  declarations: [InitComponent, AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', swConfig),
    // Jarvis
    CoreModule,
    SharedModule,
    NavigationModule,
    SidenavViewerModule,
    HeaderBarModule,
    LayoutModule,
    HomeModule,
    ActionCountdownModule,
    // AppRoutingModule must be the last one to be loaded
    // because has the wildcard route, and more specific routes
    // should be placed above less specific routes.
    AppRoutingModule,
  ],
  providers: [Title],
  bootstrap: [InitComponent],
})
export class AppModule {}
