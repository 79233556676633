declare let kendo: any;

const cleanCell = (cell: any): any => {
  const cleanedCell = {};

  ['color', 'background', 'value'].forEach((key) => {
    if (key in cell) {
      cleanedCell[key] = cell[key];
    }
  });

  return cleanedCell;
};

// @dynamic
export class KendoService {
  static getVisibleBoundaryRow(sheet: any): number {
    return sheet._rows.lastVisible();
  }

  static getVisibleBoundaryColumn(sheet: any): number {
    return sheet._columns.lastVisible();
  }

  static getVisibleBoundaryCell(sheet: any): any {
    return new kendo.spreadsheet.CellRef(
      KendoService.getVisibleBoundaryRow(sheet),
      KendoService.getVisibleBoundaryColumn(sheet),
    );
  }

  static getVisibleRange(sheet: any): any {
    const startCell = new kendo.spreadsheet.CellRef(0, 0); // aR, aC
    const endCell = KendoService.getVisibleBoundaryCell(sheet);

    return sheet.range(new kendo.spreadsheet.RangeRef(startCell, endCell));
  }

  static getColumnVisibleRange(sheet: any, column: number): any {
    const startCell = new kendo.spreadsheet.CellRef(0, column); // aR, aC
    const endCell = new kendo.spreadsheet.CellRef(
      KendoService.getVisibleBoundaryRow(sheet),
      column,
    );

    return sheet.range(new kendo.spreadsheet.RangeRef(startCell, endCell));
  }

  static getColumnVisibleValues(
    sheet: any,
    column: number,
  ): (string | number)[] {
    const values = KendoService.getColumnVisibleRange(sheet, column)
      .values()
      .flatMap((arr) => [...arr])
      .filter((d) => d !== null);
    values.shift(); // remove the first item because is the header
    return values;
  }

  static getDataRange(sheet: any): any {
    let range = KendoService.getVisibleRange(sheet);

    // Trim empty rows from the bottom of the range
    while (range._ref.height() && KendoService.isLastRowEmpty(range))
      range = range.resize({ bottom: -1 });

    return range;
  }

  static getRangeRef(
    rowA: number,
    colA: number,
    rowB: number = rowA,
    colB: number = colA,
  ): any {
    const cellA = new kendo.spreadsheet.CellRef(rowA, colA);
    const cellB = new kendo.spreadsheet.CellRef(rowB, colB);

    return new kendo.spreadsheet.RangeRef(cellA, cellB);
  }

  static isSheetValid(sheet: any): boolean {
    let range = KendoService.getVisibleRange(sheet);
    let isValid = true;

    // Trim empty rows from the bottom
    while (range._ref.height() >= 3 && KendoService.isLastRowEmpty(range)) {
      range = range.resize({ bottom: -1 });
    }

    range.forEachCell((row, col, cell) => {
      if (cell.validation) {
        isValid = isValid && cell.validation.value;
      }
    });

    return isValid;
  }

  static getSheetJSON(sheet: any): any {
    const rows = [];

    KendoService.getVisibleRange(sheet)
      .resize({ bottom: Infinity })
      .forEachCell((row, col, cell) => {
        cell = cleanCell(cell);
        if (Object.keys(cell).length === 0) return;

        cell.index = col;
        if (cell.color) {
          cell.color = kendo.parseColor(cell.color).toCss();
        }
        if (cell.background) {
          cell.background = kendo.parseColor(cell.background).toCss();
        }

        if (rows[row] === undefined) {
          rows[row] = { index: row, cells: [] };
        }
        rows[row].cells.push(cell);
      });

    return {
      rows: rows
        // Filter empty rows
        .filter((row) => row && row.cells.length)
        // Reassign index
        .map((row, index) => Object.assign(row, { index })),
    };
  }

  static getColumns(sheet: any): string[] {
    const endCol = KendoService.getVisibleBoundaryColumn(sheet);
    const range = sheet.range(this.getRangeRef(0, 0, 0, endCol));

    const columns: string[] = [];
    range.forEachCell((_r, col, cell) => {
      columns.push(cell.value);
    });

    return columns;
  }

  static getColumnsMapByRow(sheet: any, row: number): Map<string, number[]> {
    const endCol = KendoService.getVisibleBoundaryColumn(sheet);
    const range = sheet.range(this.getRangeRef(row, 0, row, endCol));

    const columns: Map<string, number[]> = new Map();
    range.forEachCell((_r, col, cell) => {
      if (!columns.has(cell.value)) {
        columns.set(cell.value, [col]);
      } else {
        columns.get(cell.value).push(col);
      }
    });

    return columns;
  }

  static getColumnByRowValue(sheet: any, row: number, value: string): number {
    const columns = this.getColumnsMapByRow(sheet, row);

    return columns.has(value) ? columns.get(value)[0] : null;
  }

  static getValueByRowColumn(sheet: any, column: number, row: number): string {
    return sheet.range(this.getRangeRef(row, column)).value();
  }

  static isBoundaryRow(sheet: any, row: number): boolean {
    return !sheet.isHiddenRow(row) && sheet.isHiddenRow(row + 1);
  }

  static isRangeEmpty(range: any): boolean {
    let isEmpty = true;

    range.forEachCell((row, col, cell) => {
      isEmpty = isEmpty && !('value' in cell);
    });

    return isEmpty;
  }

  static isLastRowEmpty(range: any): boolean {
    return KendoService.isRangeEmpty(range.bottomRow());
  }

  static isLastColumnEmpty(range: any): boolean {
    return KendoService.isRangeEmpty(range.rightColumn());
  }

  static insertVisibleRow(sheet: any): void {
    sheet.insertRow(KendoService.getVisibleBoundaryRow(sheet) + 1);
  }

  static lockSheet(sheet: any): void {
    sheet.range(kendo.spreadsheet.SHEETREF).enable(false);
  }

  static unlockSheet(sheet: any): void {
    sheet.range(kendo.spreadsheet.SHEETREF).enable(null);
  }

  static lockCellByRowValue(sheet: any, row: number, value: string): void {
    const columns = this.getColumnsMapByRow(sheet, row);
    if (columns.has(value))
      sheet.range(row, columns.get(value)[0]).enable(false);
  }

  static unlockCellByRowValue(sheet: any, row: number, value: string): void {
    const columns = this.getColumnsMapByRow(sheet, row);
    if (columns.has(value))
      sheet.range(row, columns.get(value)[0]).enable(null);
  }

  static suspendChanges(enabled: boolean, sheet: any, fn: () => void): void {
    const previousValue = sheet.suspendChanges();
    sheet.suspendChanges(enabled);
    fn();
    sheet.suspendChanges(previousValue);
  }
}
