import { Component, Input } from '@angular/core';

import { Project } from '@app/providers/projects';

@Component({
  selector: 'sp-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss'],
})
export class ProjectItemComponent {
  @Input() project: Project;
}
