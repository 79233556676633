import { InjectionToken } from '@angular/core';
import { DomainCheckerSettings } from '@app/shared/utils/regexp/domain-checker.model';

export enum GithubStatus {
  loading = 'loading',
  unauthorized = 'unauthorized',
  authorizing = 'authorizing',
  validating = 'validating',
  authorized = 'authorized',
}

export const GITHUB_INTERCEPTOR_SETTINGS: InjectionToken<DomainCheckerSettings> =
  new InjectionToken<DomainCheckerSettings>('github.interceptor.settings');

export const GITHUB_CONFIG: InjectionToken<GithubConfig> =
  new InjectionToken<GithubConfig>('github.config');

export interface GithubUser {
  login: string;
  id: number;
  avatar_url: string;
  type: string;
  site_admin: boolean;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface GithubRelease {
  id: number;
  tag_name: string;
  name: string;
  body: string;
  prerelease: boolean;
  draft: boolean;
  created_at: string;
  author: string;
  html_url: string;
  target_commitish: string;
  make_latest: string;
}

export interface GithubData {
  state: string;
  code: string;
}

export interface GithubPermissionStatus {
  status: boolean;
  response?: GithubData;
  error?: string;
}

export interface GithubResponse {
  access_token: string;
}

export interface GithubResponseError {
  message: string;
}

export interface GithubConfig {
  forbiddenPaths?: string[];
}

export interface GithubParams {
  interceptor?: DomainCheckerSettings;
  config?: GithubConfig;
}

export interface GithubStatusSummaryResponse {
  page: {
    updated_at: string;
  };
  status: {
    indicator: string; // sample: 'none'
    description: string; // sample: 'All Systems Operational'
  };
  components: {
    id: string; // sample: '8l4ygp009s5s';
    name: string; // sample: 'Git Operations';
    status: string; // sample: 'operational';
    updated_at: string; // sample: '2024-03-12T00:55:20.988Z';
    position: number; // sample: 1;
    description: string; // sample: 'Performance of git clones, pulls, pushes, and associated operations';
  }[];
}
