import { UniqueStreamValidator } from '../validator/uniqueStream.validator';
import {
  UNDEFINED_ULID_VALUE,
  ValidatorRegex,
} from '@app/config-manager/editor/editor.model';

declare let kendo: any;

export class ValidateUlidFunction {
  static register(registerFunc = kendo.spreadsheet.defineFunction) {
    registerFunc('VALIDATE_ULID', function (str: string) {
      if (str === UNDEFINED_ULID_VALUE) return true;

      const isUnique = UniqueStreamValidator.get(
        this,
        'validate_ulid',
      ).isUnique(this.formula.row, str);

      return isUnique && ValidatorRegex.ulid.test(str);
    }).args([['str', 'string']]);
  }
}
