import { Injectable } from '@angular/core';
import { EMPTY, Observable, ReplaySubject, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scroll$ = new ReplaySubject<Event>(1);
  private lastSubscription: Subscription;

  constructor() {
    this.setScroll(EMPTY);
  }

  setScroll(scrollable: Observable<Event>): void {
    if (this.lastSubscription) {
      this.lastSubscription.unsubscribe();
    }

    this.lastSubscription = scrollable.subscribe((ev: Event) => {
      this.scroll$.next(ev);
    });
  }

  getScroll(auditTimeInMs?: number): Observable<Event> {
    if (auditTime != null) {
      return this.scroll$.pipe(auditTime(auditTimeInMs));
    }

    return this.scroll$;
  }
}
