import { InjectionToken } from '@angular/core';
import { SemVer, coerce, satisfies, gt } from 'semver';
import * as moment from 'moment';

import { NgGapiClientConfig } from '@app/providers/google/api/google-api.model';

export const RELEASE_CONTROL_CURRENT_BUILD: InjectionToken<string> =
  new InjectionToken<NgGapiClientConfig>('release-control.build');

export interface ReleaseData {
  require: string;
  version: string;
  build: string;
}

export class Release {
  build: SemVer;
  require: string;

  constructor(build: string, require = null) {
    this.build = this.getSemVer(build);
    this.require = require;
  }

  compare(release: ReleaseData): ReleaseCompare {
    return new ReleaseCompare(
      this,
      new Release(release.build, release.require),
    );
  }

  getSemVer(version: string): SemVer {
    return coerce(version.replace(/(^|\.)0+(\d)/g, '$1$2'));
  }
}

export class ReleaseCompare {
  constructor(public versionA: Release, public versionB: Release) {}

  /**
   * If ReleaseA is older than ReleaseB
   */
  isNewer(): boolean {
    return gt(this.versionB.build, this.versionA.build);
  }

  /**
   * If ReleaseA does NOT satisfy ReleaseB requirements (we require a force upgrade)
   */
  isForceUpdate(): boolean {
    return !satisfies(this.versionA.build, this.versionB.require);
  }
}

export function fixReleaseData(releaseData: ReleaseData): any {
  const data: ReleaseData = {
    require: '>=0',
    version: '0',
    build: '0.0.1',
    ...releaseData,
  };

  if (data.build.startsWith('<')) {
    data.build = coerce(data.version).raw;
  }

  return data;
}

export function getBuildDate(build: string): Date {
  const buildRegExp = /^\d+\.(\d{4})(\d{2})(\d{2})\.(\d{2})(\d{2})(\d{2})$/i;
  const match = buildRegExp.exec(build);
  if (match == null) return new Date();

  const year = parseInt(match[1], 10);
  const month = parseInt(match[2], 10);
  const day = parseInt(match[3], 10);
  const hour = parseInt(match[4], 10);
  const min = parseInt(match[5], 10);
  const sec = parseInt(match[6], 10);

  return moment(new Date(year, month, day, hour, min, sec)).utc(true).toDate();
}
