import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared';
import { CheckerFindingsComponent } from './checker-findings.component';

@NgModule({
  imports: [SharedModule],
  exports: [CheckerFindingsComponent],
  declarations: [CheckerFindingsComponent],
})
export class CheckerFindingsModule {}
