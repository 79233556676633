import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name',
})
export class NamePipe implements PipeTransform {
  transform(ids: number | Array<any>, list: Array<any>): string {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return ids
      .reduce((acc: Array<any>, id: number) => {
        const result = list.find((data: object) => data['id'] === id);

        if (typeof result !== 'undefined') {
          acc.push(result.name);
        }

        return acc;
      }, [])
      .join(', ');
  }
}
