import { RegExpMorph } from './reg-exp-morph';
import { DomainCheckerSettings } from './domain-checker.model';

export class DomainChecker {
  private readonly whitelist: RegExp;
  private readonly blacklist: RegExp;

  constructor(settings: DomainCheckerSettings) {
    this.whitelist = RegExpMorph.listOfDomains(settings.whitelisting, null);
    this.blacklist = RegExpMorph.listOfDomains(settings.blacklisting, null);
    if (this.whitelist != null && this.blacklist != null) {
      throw Error(
        'Domain checker cannot be configured with whitelisting and blacklisting at the same time',
      );
    }
  }

  isAllowed(url: string): boolean {
    if (this.whitelist != null) {
      return this.whitelist.test(url);
    }
    if (this.blacklist != null) {
      return !this.blacklist.test(url);
    }
    return true;
  }

  isForbidden(url: string): boolean {
    return !this.isAllowed(url);
  }
}
