import { Cron } from '../cron';
import { CronPreset } from './index';
import { dayOfWeekParse, dayOfWeekUnparse } from './helpers';

const dayOfWeekRegex = /^[0-6](:?,[0-6]){0,6}$/;

export const presetWeekly: CronPreset = {
  id: 'weekly',
  default: '0 0 * * 0',

  detect: (cron: Cron): boolean =>
    cron.minute !== '*' &&
    cron.hour !== '*' &&
    cron.dayOfMonth === '*' &&
    cron.month === '*' &&
    dayOfWeekRegex.test(cron.dayOfWeek),

  set: (
    cron: Cron,
    params: { minute: number; hour: number; dayOfWeek: number[] },
  ): Cron => {
    const { minute, hour, dayOfWeek } = params;

    cron.minute = minute != null ? minute.toString() : cron.minute;
    cron.hour = hour != null ? hour.toString() : cron.hour;
    cron.dayOfMonth = '*';
    cron.month = '*';
    cron.dayOfWeek =
      dayOfWeek != null ? dayOfWeekUnparse(dayOfWeek) : cron.dayOfWeek;

    return cron;
  },

  get: (cron: Cron): { minute: number; hour: number; dayOfWeek: number[] } => ({
    minute: parseInt(cron.minute, 10),
    hour: parseInt(cron.hour, 10),
    dayOfWeek: dayOfWeekParse(cron.dayOfWeek),
  }),
};
