import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, forkJoin, of, timer } from 'rxjs';
import { map, switchMapTo } from 'rxjs/operators';
import * as Moment from 'moment';
import * as MomentRange from 'moment-range';

const moment = MomentRange.extendMoment(Moment);

import { Project, ProjectService } from '@app/providers/projects';
import { Api } from '@app/providers/api/api';
import { ContentModelView } from '@app/lomt/contents/contents.model';
import { DataModel as ABTest } from '@app/lomt/ab/manager/ab-manager.model';
import { PushNotificationApiService } from '@app/push-notification/push-notification-api.service';
import { PaymentValidationApiService } from '@app/payment-validation/payment-validation-api.service';
import { DataTable as Schedule } from '@app/lomt/schedule/list-legacy/schedule-list-legacy.model';
import { LomtEnvironmentService } from '@app/lomt/environments/environment.service';
import { Environment } from '@app/lomt/environments/environment.model';
import { Pod } from '@app/dynamic-environment/shared/dynamic-environment-api.model';
import { DynamicEnvironmentService } from '@app/dynamic-environment/shared/dynamic-environment-api.service';
import { Notification } from '@app/push-notification/push-notification.model';
import {
  ChartDataSet,
  Counter,
  maxTicks,
  TimeAggregate,
  TimeData,
  timeUnit,
} from './dashboard.model';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    DynamicEnvironmentService,
    PushNotificationApiService,
    PaymentValidationApiService,
    DashboardService,
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  loading = false;
  project: Project;
  selected: Counter;
  items$: Observable<Counter>[] = [];
  contents: ContentModelView[];
  abtests: ABTest[];
  notifications: Notification[];
  sizes: { [key: string]: [number, number][] };
  refunds: [number, number][];
  environments: Pod[];
  chart$: Observable<any>;
  aggregate: string = TimeAggregate.monthly;
  aggregates = Object.keys(TimeAggregate);
  options: any;

  private subscription: Subscription;

  constructor(
    private projectService: ProjectService,
    private api: Api,
    private service: DashboardService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.projectService.project$.subscribe(
      (project: Project) => {
        this.project = project;

        this.chart$ = null;
        this.items$ = [
          Counter.create('list', 'LiveOps', 'live', 'completed')
            .withChart(() => (this.chart$ = this.liveOpsChart()))
            .from(
              this.service.fetchActiveContents(
                (contents) => (this.contents = contents),
              ),
            ),

          Counter.create('call_split', 'A/B Tests', 'live', 'completed')
            .withChart(() => (this.chart$ = this.abTestsChart()))
            .from(
              this.service.fetchActiveABTests(
                (abtests) => (this.abtests = abtests),
              ),
            ),

          Counter.create('sms', 'Campaigns', 'running', 'terminating')
            .withChart(() => (this.chart$ = this.notificationsChart()))
            .from(
              this.service.fetchActiveNotifications(
                (notifications) => (this.notifications = notifications),
              ),
            ),

          Counter.create('fitness_center', 'Size', 'kb', 'active')
            .withChart(() => (this.chart$ = this.sizesChart()), {
              'scales.y.stacked': false,
            })
            .from(this.service.fetchSize((sizes) => (this.sizes = sizes))),

          Counter.create('credit_card', 'Refunds', 'today', 'error')
            .withChart(() => (this.chart$ = this.refundsChart()))
            .from(
              this.service.fetchActivePayments(
                (refunds) => (this.refunds = refunds),
              ),
            ),

          Counter.create('computer', 'Environments', 'active', 'active')
            .withChart(() => (this.chart$ = this.environmentsChart()), {
              'scales.y.stacked': false,
            })
            .from(
              this.service.fetchActiveDynamicEnvironments(
                (environments) => (this.environments = environments),
              ),
            ),
        ];

        this.items$[0].subscribe((counter: Counter) =>
          this.selectCounter(counter),
        );
      },
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectCounter(counter: Counter): void {
    if (this.selected !== counter && counter.chartFn) {
      this.selected = counter;
      this.refreshGraph();
    }
  }

  selectAggregate({ value }: { value: TimeAggregate }): void {
    if (this.aggregate !== value) {
      this.aggregate = value;
      this.refreshGraph();
    }
  }

  liveOpsChart(): Observable<{
    datasets: ChartDataSet[];
    labels: Moment.Moment[];
  }> {
    return forkJoin([
      this.api.rpc({ repository: 'scheduletimeranges', handler: 'all' }),
      this.api.rpc({ repository: 'environments', handler: 'all' }),
    ]).pipe(
      map(([timeranges, environments]: [Schedule[], Environment[]]) => {
        const series = new Set();
        const data: TimeData = new Map();

        timeranges
          .filter((item) =>
            LomtEnvironmentService.hasProd(environments, item.environments),
          )
          .forEach((item) => {
            const content = this.contents.find(
              (contentItem) => contentItem.id === item.content,
            );
            if (!content.content_type) return;

            const visited = new Set();
            const seriesId = content.content_type.name;
            series.add(seriesId);

            Array.from<Moment.Moment>(
              moment
                .range(moment.unix(item.start), moment.unix(item.end))
                .by('day'),
            )
              .map((momentDate) => this.getStartOf(momentDate))
              // Filter duplicate values "by date and content.id"
              .filter(
                (date) =>
                  !visited.has(`${content.id}_${date}`) &&
                  visited.add(`${content.id}_${date}`),
              )
              .forEach((date) => this.addLabelDateAsSum(data, seriesId, date));
          });

        return this.getGraphData(data, <any>Array.from(series));
      }),
    );
  }

  abTestsChart(): Observable<{
    datasets: ChartDataSet[];
    labels: Moment.Moment[];
  }> {
    return this.api.rpc({ repository: 'environments', handler: 'all' }).pipe(
      map((environments: Environment[]) => {
        const series = ['AB Tests'];
        const data: TimeData = new Map();

        this.abtests
          .filter((item) =>
            LomtEnvironmentService.hasProd(environments, item.environments),
          )
          .forEach((item) => {
            const visited = new Set();
            Array.from<Moment.Moment>(
              moment
                .range(moment.unix(item.start), moment.unix(item.end))
                .by('day'),
            )
              .map((momentDate) => this.getStartOf(momentDate))
              // Filter duplicate values "by date"
              .filter((date) => !visited.has(date) && visited.add(date))
              .forEach((date) => this.addLabelDateAsSum(data, series[0], date));
          });

        return this.getGraphData(data, series);
      }),
    );
  }

  notificationsChart(): Observable<{
    datasets: ChartDataSet[];
    labels: Moment.Moment[];
  }> {
    const series = ['Campaigns'];
    const data: TimeData = new Map();

    this.notifications
      .filter((item) => item.first_delivery_date)
      .map((item) => this.getStartOf(item.first_delivery_date * 1000))
      .forEach((date) => {
        this.addLabelDateAsSum(data, series[0], date);
      });

    return timer(200).pipe(switchMapTo(of(this.getGraphData(data, series))));
  }

  sizesChart(): Observable<{
    datasets: ChartDataSet[];
    labels: Moment.Moment[];
  }> {
    const series = ['Avg Size (KB)', 'Percentile 95 (KB)', 'Max Size (KB)'];
    const data: TimeData = new Map();

    this.sizes.avg.forEach(([k, v]) =>
      this.addLabelDateAsAvg(data, series[0], this.getStartOf(k), v),
    );
    this.sizes.percentile95.forEach(([k, v]) =>
      this.addLabelDateAsAvg(data, series[1], this.getStartOf(k), v),
    );
    this.sizes.max.forEach(([k, v]) =>
      this.addLabelDateAsAvg(data, series[2], this.getStartOf(k), v),
    );

    return timer(200).pipe(switchMapTo(of(this.getGraphData(data, series))));
  }

  refundsChart(): Observable<{
    datasets: ChartDataSet[];
    labels: Moment.Moment[];
  }> {
    const series = ['Android refunds'];
    const data: TimeData = new Map();

    this.refunds.forEach(([key, value]) =>
      this.addLabelDateAsSum(data, series[0], this.getStartOf(key), value),
    );

    return timer(200).pipe(switchMapTo(of(this.getGraphData(data, series))));
  }

  environmentsChart(): Observable<{
    datasets: ChartDataSet[];
    labels: Moment.Moment[];
  }> {
    const series = ['Created', 'Active'];
    const data: TimeData = new Map();

    this.environments
      .filter((item) => item.status === 'Active')
      .forEach((item) => {
        this.addLabelDateAsSum(
          data,
          series[0],
          this.getStartOf(item.timestamp * 1000),
        );
        Array.from(
          moment
            .range(
              moment(this.getStartOf(item.timestamp * 1000)),
              moment.min(moment(item.expiration * 1000), moment()),
            )
            .by(timeUnit[this.aggregate]),
        ).forEach((date) => {
          this.addLabelDateAsSum(data, series[1], date.valueOf() as number);
        });
      });

    return timer(200).pipe(switchMapTo(of(this.getGraphData(data, series))));
  }

  private refreshGraph() {
    this.options = this.service.getGraphOptions(this.aggregate);
    this.selected.applyChartOptions(this.options);
    this.selected.openChart();
  }

  // Start the helper methods for graphs

  private getStartOf(date: number | Moment.Moment): number {
    const instance = moment.isMoment(date) ? date : moment(date);
    return instance.startOf(timeUnit[this.aggregate]).valueOf();
  }

  private addLabelDateAsSum(
    data: TimeData,
    label: string,
    date: number,
    value: number = 1,
  ): void {
    if (!data.has(date)) {
      data.set(date, { [label]: value });
    } else {
      const labels = data.get(date);
      const sum = labels[label] || 0;

      /* streaming sum calculation */
      labels[label] = sum + value;
    }
  }

  private addLabelDateAsAvg(
    data: TimeData,
    label: string,
    date: number,
    value: number,
  ): void {
    const avgKey = `_avg_${label}`;
    if (!data.has(date)) {
      data.set(date, { [label]: value, [avgKey]: 1 });
    } else {
      const labels = data.get(date);
      const average = labels[label] || 0;
      const count = labels[avgKey] || 0;

      /* streaming average calculation */
      labels[label] = (average * count + value) / (count + 1);
      labels[avgKey] = count + 1;
    }
  }

  private getGraphData(
    data: TimeData,
    series: string[],
  ): { datasets: ChartDataSet[]; labels: Moment.Moment[] } {
    const labels = this.getLabels(data);
    const datasets = series.map((title) =>
      new ChartDataSet(
        title,
        labels
          .map((momentDate) => momentDate.valueOf())
          .map((date) => (data.has(date) ? data.get(date)[title] || 0 : 0)),
      ).withStackedOptions(),
    );

    return { datasets, labels };
  }

  private getLabels(data: TimeData): Moment.Moment[] {
    const start = moment(Array.from(data.keys()).sort().shift());
    const min = moment()
      .subtract(maxTicks[this.aggregate], timeUnit[this.aggregate])
      .startOf(timeUnit[this.aggregate]);

    return Array.from(
      moment
        .range(moment.max(min, start), moment())
        .by(timeUnit[this.aggregate]),
    );
  }
}
