import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import { Observable } from 'rxjs';

@Component({
  selector: 'sp-kibana-graph',
  templateUrl: './kibana-graph.component.html',
  styleUrls: ['./kibana-graph.component.scss'],
})
export class KibanaGraphComponent implements OnInit {
  @Input() query: Observable<any>;

  loading = true;
  datasets: any[];
  labels: any[];

  options = {
    scales: {
      x: {
        type: 'time',
        distribution: 'linear',
        ticks: {
          source: 'auto',
        },
      },
      y: {
        ticks: {
          min: 0,
        },
        scaleLabel: {
          display: true,
        },
      },
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
  };

  ngOnInit(): void {
    this.query.subscribe(
      (response: { labels: moment.Moment[]; users: number[] }) => {
        this.labels = response.labels;
        this.datasets = [
          this.getTimeDataset('Unique users / minute', response.users, 'bar'),
        ];
        this.loading = false;
      },
    );
  }

  getTimeDataset(
    label: string,
    data: any[],
    type: string = 'line',
    more?: any,
  ): any {
    return Object.assign(
      {
        label,
        data,
        type,
        fill: false,
        pointRadius: 0,
        borderWidth: 1,
      },
      more,
    );
  }
}
