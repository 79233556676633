import { Provider, InjectionToken } from '@angular/core';
import { DomainCheckerSettings } from '@app/shared/utils/regexp/domain-checker.model';

export const AUTH_CONFIG: InjectionToken<AuthConfig> =
  new InjectionToken<AuthConfig>('auth.config');

export const AUTH_INTERCEPTOR_SETTINGS: InjectionToken<DomainCheckerSettings> =
  new InjectionToken<DomainCheckerSettings>('auth.interceptor.settings');

export interface AuthProfile {
  id: string;
  name: string;
  email: string;
}

export interface AuthConfig {
  [key: string]: any;
}

export interface AuthParams {
  providers: Provider[];
  config: AuthConfig;
  interceptor?: DomainCheckerSettings;
}
