import { ModuleWithProviders, NgModule } from '@angular/core';

import { RELEASE_CONTROL_CURRENT_BUILD } from '@app/providers/release-control/release-control.model';
import { ReleaseControlService } from '@app/providers/release-control/release-control.service';

@NgModule({})
export class ReleaseControlModule {
  static forRoot(): ModuleWithProviders<ReleaseControlModule> {
    return {
      ngModule: ReleaseControlModule,
      providers: [ReleaseControlService],
    };
  }
}
