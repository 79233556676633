<button
  mat-icon-button
  *ngIf="display"
  [mdePopoverTriggerFor]="appPopover"
  mdePopoverTriggerOn="click"
>
  <mat-icon>insert_emoticon</mat-icon>
</button>

<mde-popover
  #appPopover="mdePopover"
  [mdePopoverOverlapTrigger]="false"
  mdePopoverArrowColor="#FFF"
  [mdePopoverCloseOnClick]="false"
>
  <emoji-mart
    class="emoji"
    (emojiClick)="handleSelection($event)"
    [exclude]="['recent']"
  ></emoji-mart>
</mde-popover>
