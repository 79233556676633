import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  CanActivate,
  Router,
  UrlTree,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    try {
      return (
        (await this.auth.isAuthenticated()) ||
        this.router.createUrlTree(['/login'])
      );
    } catch (e) {
      return this.router.createUrlTree(['/login']);
    }
  }

  canActivateChild(route: any, state: any): Promise<boolean | UrlTree> {
    return this.auth.isAuthenticated();
  }
}
