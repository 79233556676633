export const allowedDomains = ['socialpoint.es'];

export interface Profile {
  id: string;
  name: string;
  givenName: string;
  familyName: string;
  imageUrl: string;
  email: string;
}

export interface GoogleUser {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  hd: string;
  locale: string;
  name: string;
  picture: string;
  sub: string;
}

export interface TokenResponse {
  access_token: string;
  authuser: string;
  expires_in: number;
  hd: string;
  prompt: string;
  scope: string;
  token_type: string;
  expires_at: number;
}

export type GSITokenClient = any;
export type GSITokenCallback = (TokenResponse) => void;
