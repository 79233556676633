import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, interval, Observable } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  ReleaseData,
  Release,
  ReleaseCompare,
  fixReleaseData,
  RELEASE_CONTROL_CURRENT_BUILD,
} from '@app/providers/release-control/release-control.model';
import { ActionCountdownComponent } from '@app/shared/action-countdown/action-countdown.component';
import { ActionCountdownData } from '@app/shared/action-countdown/action-countdown.model';
import { environment } from '@env/environment';

declare let window: Window;

export const DEFAULT_PERIOD = 60 * 1000;

@Injectable()
export class ReleaseControlService {
  private release: Release;

  constructor(
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
    @Optional() @Inject(RELEASE_CONTROL_CURRENT_BUILD) build: string,
  ) {
    this.release = new Release(build || environment.build);
  }

  onVersionUpdate(): Observable<ReleaseCompare> {
    return interval(DEFAULT_PERIOD).pipe(
      switchMap(() =>
        this.httpClient.get<ReleaseData>(`./release.json?t=${Date.now()}`, {
          responseType: 'json',
        }),
      ),
      catchError(() => EMPTY),
      map(fixReleaseData),
      distinctUntilKeyChanged('build'),
      map((releaseData) => this.release.compare(releaseData)),
      filter((rc) => rc.isNewer()),
    );
  }

  listenForUpdates(): void {
    this.onVersionUpdate().subscribe((rc) => {
      this.showUpdateNotification(rc.isForceUpdate());
    });
  }

  showUpdateNotification(force = false): void {
    // First check the updates (may take some time)
    this.checkForUpdate().then(() => {
      this.snackBar.openFromComponent(ActionCountdownComponent, {
        verticalPosition: 'top',
        data: <ActionCountdownData>{
          title:
            'New update for Jarvis Suite is available.' +
            (force ? '\nThis is an important update.' : ''),
          action: force ? 'FORCE UPDATE' : 'UPDATE NOW',
          timeout: force ? 5 * 60 : 0,
          callback: async () => {
            await this.activateUpdate();

            window.location.reload();
          },
        },
      });
    });
  }

  private async checkForUpdate(): Promise<boolean> {
    if (
      'serviceWorker' in navigator &&
      navigator.serviceWorker.controller !== null
    ) {
      return this.swUpdate.checkForUpdate();
    }
  }

  private async activateUpdate(): Promise<boolean> {
    if (
      'serviceWorker' in navigator &&
      navigator.serviceWorker.controller !== null
    ) {
      return this.swUpdate.activateUpdate();
    }
  }
}
