import { CheckerCheck } from '../checker.model';

export type GithubCheckerPermission =
  | 'admin'
  | 'pull'
  | 'push'
  | 'maintain'
  | 'triage';

export type GithubGlobalCheckId = 'status';

export type GithubCheckerRepository = (params: any) => string;

export interface GithubCheckerCheck extends CheckerCheck {
  repo: GithubCheckerRepository;
  permissions: GithubCheckerPermission[];
}

export interface GithubGlobalCheckerCheck extends CheckerCheck {
  id: GithubGlobalCheckId;
}

export const statusEmoji: Record<string, string> = {
  partial_outage: '🟡',
  major_outage: '🔴',
};
