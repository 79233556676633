import { UniqueStreamValidator } from '../validator/uniqueStream.validator';

declare let kendo: any;

export class ValidateUniqueFunction {
  static register(registerFunc = kendo.spreadsheet.defineFunction) {
    registerFunc(
      'VALIDATE_UNIQUE',
      function (str: string, horizontal: boolean) {
        const ref = horizontal ? this.formula.col : this.formula.row;

        return UniqueStreamValidator.get(
          this,
          'validate_unique',
          horizontal,
        ).isUnique(ref, str);
      },
    ).args([
      ['str', 'string'],
      ['horizontal', ['or', 'logical', 'null']],
    ]);
  }
}
