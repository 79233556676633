/**
 * Unique Stream Validator
 *
 * Store a pool of validation unique streams
 */
import { FunctionContext } from '../function/function.tools';

export class UniqueStreamValidator {
  static storage: Map<string, UniqueStreamValidator> = new Map();

  private dataSet: Set<string> = new Set();
  private lastRef = 0;

  /**
   * Clear the internal storage
   */
  static clear(): void {
    this.storage.clear();
  }

  /**
   * Return the reference key for the storage
   *
   * @param {FunctionContext} ctx
   * @param {string} id
   * @param {boolean} horizontal
   * @returns {string}
   */
  static getKey(ctx: FunctionContext, id: string, horizontal: boolean): string {
    return `${ctx.formula.sheet}.${id}.${
      horizontal ? `row.${ctx.formula.row}` : `col.${ctx.formula.col}`
    }`;
  }

  /**
   * Returns an stream instance
   *
   * @param {FunctionContext} ctx
   * @param {string} id
   * @param {boolean} horizontal
   * @returns {UniqueStreamValidator}
   */
  static get(
    ctx: FunctionContext,
    id: string,
    horizontal = false,
  ): UniqueStreamValidator {
    const key = this.getKey(ctx, id, horizontal);

    if (!this.storage.has(key)) {
      this.storage.set(key, new this());
    }
    return this.storage.get(key);
  }

  /**
   * Validate that the current validator is unique compared with all the previous ones
   *
   * @param {number} ref
   * @param {string} value
   * @returns {boolean}
   */
  isUnique(ref: number, value: string): boolean {
    if (this.lastRef >= ref) this.dataSet.clear();
    this.lastRef = ref;

    if (this.dataSet.has(value)) return false;

    this.dataSet.add(value);
    return true;
  }
}
