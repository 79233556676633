import { UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';

import { Cron } from '@app/shared/utils/cron';
import { validateCronExpression } from './repeat/repeat.model';

export const resetDynamicValidators = (form: UntypedFormGroup) => {
  form.get('scheduled_date').setValidators(null);
  form.get('scheduled_time').setValidators(null);
  form
    .get('cron_expression')
    .setValidators([Validators.required, validateCronExpression]);
};

export enum RepeatEnds {
  'never' = 'never',
  'on' = 'on',
}

export enum ScheduleDynamic {
  'daily' = 'daily',
  'weekly' = 'weekly',
  'monthly' = 'monthly',
  'custom' = 'custom',
  'edit' = 'edit',
}

export const scheduleDynamicMap: Map<ScheduleDynamic, Cron> = new Map([
  [ScheduleDynamic.daily, Cron.fromPreset('daily')],
  [ScheduleDynamic.weekly, Cron.fromPreset('weekly')],
  [ScheduleDynamic.monthly, Cron.fromPreset('monthly')],
  [ScheduleDynamic.custom, Cron.fromPreset('custom')],
]);

const getDefaultCronExpression = (): [ScheduleDynamic, Cron] => [
  ScheduleDynamic.daily,
  scheduleDynamicMap.get(ScheduleDynamic.daily),
];

export const setupCronExpression = (
  cronExpression: string,
): [ScheduleDynamic, Cron] => {
  if (!cronExpression) {
    return getDefaultCronExpression();
  }
  const cron = Cron.parse(cronExpression);

  const cronType = whatPreset(cron);
  if (cronType === ScheduleDynamic.custom) {
    const customCron = Cron.parse(cronExpression);
    return [cronType, customCron];
  }
  if (scheduleDynamicMap.has(cronType)) {
    return [cronType, scheduleDynamicMap.get(cronType)];
  } else {
    return getDefaultCronExpression();
  }
};

const whatPreset = (cron: Cron): ScheduleDynamic => {
  const cronType = cron.detectPreset() as ScheduleDynamic;
  switch (cronType) {
    case ScheduleDynamic.weekly:
      const dayOfWeek = cron.dayOfWeek.split(',');
      if (dayOfWeek.length && dayOfWeek.length > 1) {
        return ScheduleDynamic.custom;
      } else {
        return ScheduleDynamic.weekly;
      }
    default:
      return cronType;
  }
};

export const dateEndLessThanStart = (control: AbstractControl) => {
  const date1 = control.root.get('scheduled_date').value;
  const date2 = control.root.get('scheduled_end_date').value;
  if (date1 !== null && date2 !== null && date1 > date2) {
    return { dateEndLessThanStart: true };
  }
  return null;
};
