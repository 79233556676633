export class Author {
  static clean(value: string): string {
    if (typeof value !== 'string') {
      return 'n/a';
    }

    let author = value.toLowerCase();
    if (author.indexOf('@') > -1) {
      author = author.split('@')[0];
    }
    if (author.indexOf('+') > -1) {
      author = author.split('+')[1];
    }
    if (author.startsWith('sp-')) {
      author = author.replace('sp-', '').replace(/-/g, '.');
    }
    return author;
  }
}
