import { Pipe, PipeTransform } from '@angular/core';
import { Observable, timer, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { Chronos } from '../utils/chronos';

@Pipe({
  name: 'formatDuration',
})
export class FormatDurationPipe implements PipeTransform {
  transform(duration: number): string {
    return moment.duration(duration, 'seconds').humanize();
  }
}

// TODO: Remove this pipe in favor of Angular datePipe
@Pipe({
  name: 'customDate',
})
export class DatePipe implements PipeTransform {
  transform(date: any): string {
    if (!date) return '-';

    switch (typeof date) {
      case 'number':
        return Chronos.createFromServer(date).forTable();
      default:
        return Chronos.createFromDatepicker(date).forTable();
    }
  }
}

@Pipe({
  name: 'timeFromNow',
})
export class TimeFromNowPipe implements PipeTransform {
  transform(date: any): Observable<string> {
    if (!date) return of('-');

    const targetDate = moment.unix(date);
    return timer(0, 1000).pipe(map(() => targetDate.fromNow()));
  }
}

@Pipe({
  name: 'timeToDate',
})
export class TimeToDatePipe implements PipeTransform {
  transform(date: any): Observable<string> {
    if (!date) return of('-');

    const targetDate = moment.unix(date);
    return timer(0, 1000).pipe(
      map(() => String(targetDate.diff(moment(), 'seconds'))),
    );
  }
}
