import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared';
import { HeaderBarComponent } from './header-bar.component';
import { TodayModule } from '@app/today/today.module';
import { ProjectModule } from '@app/shared/project/project.module';
import { CheckerFindingsModule } from '@app/shared/checker-findings/checker-findings.module';

@NgModule({
  imports: [
    RouterModule,

    // Jarvis
    SharedModule,
    TodayModule,
    ProjectModule,
    CheckerFindingsModule,
  ],
  declarations: [HeaderBarComponent],
  exports: [HeaderBarComponent],
})
export class HeaderBarModule {}
