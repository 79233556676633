import { ulid } from 'ulid';
import { HttpParams } from '@angular/common/http';

import { RepeatEnds } from './manager/recurrence/recurrence.model';
import { Metadata } from '../lomt/shared/metadata/metadata.interface';
import { PushNotificationHelperService as Helper } from './push-notification-helper.service';
import { Translation } from './manager/translation/translation.model';
import {
  unparseTranslationsEntities,
  parseTranslationsEntities,
} from './manager/translation/translation.model';

interface Extradata {
  url?: any;
}

// Notification entity model
export class Notification {
  schedule_type: ScheduleType;
  id: string;
  game: string;
  name: string;
  target: string;
  target_type: TargetType;
  scheduled_date: string | Date;
  scheduled_end_date: string | Date;
  scheduled_time: string;
  timezone: string;
  translations: object | Translation[];
  status: Status;
  campaign_id?: string;
  store_campaign_in_mdi = false;
  live_run: boolean;
  template: boolean;
  parent: string;
  cron_expression: string;
  first_delivery_date?: number = null;
  target_platforms: string[];
  extra: Extradata;
  aborted: boolean;
}

export interface NotificationComponent extends Notification, Metadata {
  scheduled_date: Date;
  scheduled_end_date: Date;
  translations: Translation[];
  repeat_ends: string;
  url_params: any;
  extra_params: any;
}

export const createNotificationComponent = (
  notificationObj: any = {},
): NotificationComponent =>
  Object.assign(new Notification(), notificationObj, {
    scheduled_date: notificationObj.scheduled_date
      ? Helper.stringDateToDate(notificationObj.scheduled_date)
      : Helper.getDateEpoch(),
    scheduled_end_date: notificationObj.scheduled_end_date
      ? Helper.stringDateToDate(notificationObj.scheduled_end_date)
      : Helper.getDateEpoch(),
    translations: parseTranslationsEntities(notificationObj.translations),
    url_params: parsePlatformURL(notificationObj.game, notificationObj.extra),
    extra_params: parseExtraParams(notificationObj.extra),
  });

export const notificationComponentToNotification = (
  notificationComponent: NotificationComponent,
): Notification => {
  const {
    id,
    game,
    status,
    name,
    target,
    target_type,
    timezone,
    campaign_id,
    store_campaign_in_mdi,
    target_platforms,
    extra,
    schedule_type,
    scheduled_date,
    scheduled_end_date,
    repeat_ends,
    scheduled_time,
    translations,
    live_run,
    template,
    cron_expression,
    first_delivery_date,
    parent,
    aborted,
  } = notificationComponent;

  let scheduledTime;
  let scheduledDate;
  let scheduledEndDate;
  if (template) {
    scheduledTime = scheduled_time;
    scheduledDate = Helper.dateToStringDate(scheduled_date);
    scheduledEndDate =
      repeat_ends === RepeatEnds.never
        ? ''
        : Helper.dateToStringDate(scheduled_end_date);
  } else {
    scheduledTime = schedule_type === ScheduleType.now ? '' : scheduled_time;
    scheduledDate =
      schedule_type === ScheduleType.now
        ? ''
        : Helper.dateToStringDate(scheduled_date);
    scheduledEndDate = '';
  }

  // delete url property if no canvas platform selected
  if (!isCanvasEnable(notificationComponent)) {
    delete extra.url;
  }

  return Object.assign(
    {},
    {
      id,
      game,
      status,
      name,
      target,
      target_type,
      schedule_type,
      campaign_id,
      store_campaign_in_mdi,
      target_platforms,
      extra,
      live_run,
      aborted,
      template,
      cron_expression,
      first_delivery_date,
      parent,
      scheduled_time: scheduledTime,
      scheduled_date: scheduledDate,
      scheduled_end_date: scheduledEndDate,
      timezone: schedule_type === ScheduleType.later ? timezone : '',
      translations: unparseTranslationsEntities(translations),
    },
  );
};

export const createAddComponentNotification = (
  gameShortName: string,
  isDynamic: boolean,
): NotificationComponent => {
  const id = ulid();
  return createNotificationComponent({
    id,
    game: gameShortName,
    schedule_type: isDynamic ? ScheduleType.user : ScheduleType.now,
    scheduled_date: isDynamic
      ? Helper.dateToStringDate(Helper.getTomorrowDate())
      : '',
    scheduled_end_date: '',
    scheduled_time: '00:00',
    timezone: getTimezone(),
    target_type: TargetType.query,
    translations: {},
    status: Status.draft,
    aborted: false,
    template: isDynamic,
    cron_expression: '',
    parent: '',
    live_run: false,
    target_platforms: [PlatformType.ios, PlatformType.android],
    extra: {
      url: createPlatformURL(gameShortName, id),
    },
  });
};

export const createEditComponentNotification = (
  notification: Notification,
  isClone: boolean,
): NotificationComponent => {
  const id = isClone ? ulid() : notification.id;
  const name = isClone ? `${notification.name} (clone)` : notification.name;
  const status = isClone ? Status.draft : notification.status;

  // make sure url is an object
  if (notification.extra == null) {
    notification.extra = {};
  }
  // refresh url ID (clone case)
  const urlParams = parsePlatformURL(notification.game, notification.extra);
  return createNotificationComponent({
    ...notification,
    id,
    name,
    status,
    timezone: getTimezone(notification.timezone),
    extra: Object.assign({}, notification.extra, {
      url: updatePlatformURL(urlParams, notification.game, id),
    }),
  });
};

const parseExtraParams = (extraParams: any): any => {
  const obj = Object.assign({}, extraParams);
  delete obj.url;
  return Object.keys(obj).reduce(
    (acc, curr) => Object.assign(acc, { [curr]: JSON.stringify(obj[curr]) }),
    {},
  );
};

export const createPlatformURL = (game: string, id: string): string => {
  if (noCanvasGame(game)) return '';
  return (
    canvasGameUrls.get(game) +
    '?' +
    new HttpParams({ fromString: `bso=push&bsid=${id}` })
  );
};

const parsePlatformURL = (game: string, extraObj: any): any => {
  if (!extraObj.url) return {};
  const url = extraObj.url;
  const gameURLLength = canvasGameUrls.get(game) + '?';
  const paramString = url.substring(gameURLLength.length, url.length);
  let urlParams = new HttpParams({ fromString: paramString });
  urlParams = urlParams.delete('bso');
  urlParams = urlParams.delete('bsid');
  const paramArray = urlParams.keys();
  return paramArray.reduce(
    (acc, key) => Object.assign(acc, { [key]: urlParams.get(key) }),
    {},
  );
};

export const updatePlatformURL = (
  paramsObj: any,
  game: string,
  id: string,
): string => {
  let urlParams = new HttpParams();
  let urlString = '';
  if (paramsObj) {
    Object.keys(paramsObj).forEach(
      (key) => (urlParams = urlParams.set(key, paramsObj[key])),
    );
    urlString = urlParams.toString() ? '&' + urlParams.toString() : '';
  }
  return createPlatformURL(game, id) + urlString;
};

export const isErasableNotification = (
  notification: NotificationComponent,
): boolean => {
  if (notification.template) {
    return notification.status === Status.draft;
  } else {
    const isNotErasable =
      (notification.status === Status.running ||
        notification.status === Status.scheduled) &&
      !notification.aborted;
    return !isNotErasable;
  }
};

export const isCanvasEnable = (notification: NotificationComponent): boolean =>
  !noCanvasGame(notification.game) &&
  notification.target_platforms.includes(PlatformType.canvas);

export const noCanvasGame = (game: string): boolean =>
  !canvasGameUrls.has(game);

export const getTimezone = (desiredTimezone?: string) =>
  timezones.find((timezone) => timezone === desiredTimezone) || timezones[0];

export const canvasGameUrls = new Map([
  ['mc', 'https://apps.facebook.com/monsterlegends'],
  ['dc', 'https://apps.facebook.com/dragoncity'],
]);

export enum PlatformType {
  'ios' = 'ios',
  'android' = 'android',
  'canvas' = 'canvas',
}

export enum ScheduleType {
  'now' = 'now',
  'later' = 'at_global_time',
  'user' = 'at_user_time',
}

export enum Status {
  draft = 'draft',
  scheduled = 'scheduled',
  running = 'running',
  completed = 'completed',
  abort = 'abort',
}

export enum TargetType {
  'segment' = 'segment',
  'query' = 'query',
  'user' = 'user',
}

export const statusIcons = {
  [Status.draft]: 'edit',
  [Status.scheduled]: 'insert_invitation',
  [Status.running]: 'linear_scale',
  [Status.completed]: 'done',
  [Status.abort]: 'warning',
};

export const timezones: string[] = ['UTC', 'Europe/Madrid'];

const sqlTemplate: { [key: string]: string } = {
  q15days: `SELECT DISTINCT user_id
FROM t_user
WHERE date_last_logged >= CURRENT_DATE - 14
ORDER BY 1`,
  dcReawak: `SELECT DISTINCT user_id
FROM t_user
WHERE date_last_logged < CURRENT_DATE - 14 AND date_last_logged >= CURRENT_DATE - 90
AND (register_device_ios IS NOT NULL OR register_device_android IS NOT NULL)
ORDER BY 1`,
  mcReawak: `SELECT DISTINCT user_id
FROM t_user
WHERE date_last_logged < CURRENT_DATE - 14 AND date_last_logged >= CURRENT_DATE - 90
AND (register_device_ios IS NOT NULL OR register_device_android IS NOT NULL)
AND user_category <> 'bot'
AND migrate_date_orphaned IS NULL
ORDER BY 1`,
};

export const segmentsMap: Map<string, string[][]> = new Map([
  [
    'dc',
    [
      ['15day', sqlTemplate.q15days],
      ['reawak_3months', sqlTemplate.dcReawak],
    ],
  ],
  [
    'mc',
    [
      ['15day', sqlTemplate.q15days],
      ['reawak_3months', sqlTemplate.mcReawak],
    ],
  ],
]);
