import { ProjectConfig } from './project-config.model';

export interface Project {
  name?: string;
  alias: string;
  id: number;
  short_name: string;
  short_name_cm: string;
  icon: string;
  data: string;

  // Fields extracted from .data (JSON string)
  config: ProjectConfig;
}

export const createProject = (project: Project) => {
  const config = ProjectConfig.create()
    .withLocalProject(project.short_name)
    .withRemoteJson(project.data);

  return Object.assign({}, project, {
    icon: project.icon || `assets/project-icon/${project.alias}.png`,
    short_name_cm: shortNameCm.get(project.short_name) || project.short_name,
    config,
  });
};

const shortNameCm = new Map<string, string>([['mc', 'ml']]);

export enum ProjectName {
  dc = 'dragoncity',
  mc = 'monstercity',
  demo = 'demo',
}

export function getProjectRepository(project: Project): string {
  return `backend-services-${project.alias}-cm`;
}
