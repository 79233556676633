<sp-content-toolbar [loader]="loading">
  <div class="tool-title">{{ project.name }} Dashboard</div>
</sp-content-toolbar>

<sp-content-layout>
  <div class="vertical-layout">
    <div class="counter">
      <ng-container *ngFor="let counter$ of items$">
        <mat-card
          appearance="outlined"
          *ngIf="counter$ | async as counter; else loader"
          class="mat-mdc-elevation-specific mat-elevation-z0"
          [class.mat-card-selected]="selected === counter"
          [class.mat-card-clickable]="counter.chartFn"
          (click)="selectCounter(counter)"
        >
          <mat-card-content class="counter-content">
            <mat-icon>{{ counter.icon }}</mat-icon>
            <div>
              <h3>{{ counter.total !== null ? counter.total : 'n/a' }}</h3>
              <h6>
                {{ counter.title }}
                <span class="badge" [ngClass]="counter.badge">{{
                  counter.subtitle
                }}</span>
              </h6>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>

    <div class="graph">
      <mat-card
        appearance="outlined"
        class="toggle-wrapper mat-mdc-elevation-specific mat-elevation-z0"
      >
        <mat-card-content>
          <mat-button-toggle-group
            (change)="selectAggregate($event)"
            [value]="aggregate"
          >
            <mat-button-toggle *ngFor="let aggr of aggregates" [value]="aggr">{{
              aggr
            }}</mat-button-toggle>
          </mat-button-toggle-group>
        </mat-card-content>
      </mat-card>

      <mat-card
        appearance="outlined"
        class="chart mat-mdc-elevation-specific mat-elevation-z0"
      >
        <mat-card-content>
          <div *ngIf="chart$ | async as chart; else loaderMain">
            <canvas
              *ngIf="chart.datasets.length"
              baseChart
              [datasets]="chart.datasets"
              [labels]="chart.labels"
              type="line"
              [options]="options"
            ></canvas>
            <p *ngIf="!chart.datasets.length">No chart available</p>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</sp-content-layout>

<ng-template #loader>
  <mat-card
    appearance="outlined"
    class="mat-mdc-elevation-specific mat-elevation-z0"
  >
    <mat-card-content>
      <mat-spinner diameter="20" class="spinner"></mat-spinner>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #loaderMain>
  <mat-spinner diameter="20" class="spinner"></mat-spinner>
</ng-template>
