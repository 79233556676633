<div #overlay></div>

<mat-toolbar class="header-bar mat-elevation-z1" color="primary">
  <div class="left-nav">
    <button
      class="btn-home"
      mat-icon-button
      (click)="sidenav.toggle()"
      [disabled]="!authenticated || !project"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <div class="app-name">
      <span>JARVIS SUITE</span>
      <app-today></app-today>
    </div>
    <div
      class="switch-project"
      *ngIf="authenticated && projects"
      [spOverlayButton]="!project"
      [overlay]="overlay"
      [matTooltipDisabled]="!!project"
      matTooltipPosition="right"
      matTooltip="You must select a project first."
    >
      <button
        mat-raised-button
        color="primary"
        (click)="changeProject()"
        [ngClass]="{ 'project-selected': !!project }"
        class="condensed"
      >
        <sp-project-item
          *ngIf="project; else selectProject"
          [project]="project"
        ></sp-project-item>
        <ng-template #selectProject>
          <mat-icon>stars</mat-icon>
          <span>Select Project</span>
        </ng-template>
      </button>
    </div>
  </div>

  <div class="right-nav">
    <div class="btns-right-nav">
      <sp-checker-findings></sp-checker-findings>
      <button
        mat-icon-button
        matTooltip="Contact"
        [matMenuTriggerFor]="contactMenu"
      >
        <mat-icon matBadge>drafts</mat-icon>
      </button>
      <mat-menu #contactMenu="matMenu" [overlapTrigger]="false">
        <a
          mat-menu-item
          href="{{ getContactUrl('Feature Request') }}"
          target="_blank"
        >
          <mat-icon>record_voice_over</mat-icon>
          <span>Feature Request</span>
        </a>
        <a
          mat-menu-item
          href="{{ getContactUrl('Bug Report') }}"
          target="_blank"
        >
          <mat-icon>bug_report</mat-icon>
          <span>Bug Report</span>
        </a>
      </mat-menu>
    </div>
    <div *ngIf="authenticated; else loginTmpl">
      <button
        class="user-img"
        mat-mini-fab
        color="primary"
        [matMenuTriggerFor]="menu"
      >
        <img
          *ngIf="userImg; else ico"
          [src]="userImg"
          (error)="
            $event.currentTarget.src = 'assets/project-icon/default_avatar.png'
          "
        />
        <ng-template #ico><i class="material-icons">person</i></ng-template>
      </button>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button
          mat-menu-item
          *ngIf="githubStatus$ | async as githubStatus"
          disabled
        >
          <mat-icon
            svgIcon="github"
            class="github-status-icon status-{{ githubStatus }}"
          ></mat-icon>
          <span>Github: {{ githubStatus }}</span>
        </button>
        <button mat-menu-item (click)="auth.logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item disabled [ngStyle]="appInfoStyles">
          <span>Build: v{{ build }}</span>
        </button>
        <button mat-menu-item disabled [ngStyle]="appInfoStyles">
          <em>{{ buildDate }}</em>
        </button>
      </mat-menu>
    </div>
    <ng-template #loginTmpl>
      <button
        mat-raised-button
        color="primary"
        (click)="login()"
        [spOverlayButton]="!authenticated"
        [overlay]="overlay"
        matTooltipPosition="left"
        matTooltip="You must log in first."
      >
        Login
      </button>
    </ng-template>
  </div>
</mat-toolbar>
