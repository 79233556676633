import { Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { BetaComponent } from './beta/beta.component';

export const homeRoutes: Routes = [
  {
    path: 'home',
    data: {
      mainTool: 'home',
      title: 'Home',
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          tool: 'dashboard',
          title: 'Dashboard',
        },
      },
      {
        path: 'public-beta',
        component: BetaComponent,
        data: {
          tool: 'public-beta',
          title: 'Public Beta',
        },
      },
    ],
  },
];
