import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import {
  NotificationMessage,
  notificationPosition,
} from '@app/shared/notification-message/notification-message.model';
import { NotificationMessageComponent } from './notification-message.component';

@Injectable()
export class NotificationMessageService {
  constructor(private snackBar: MatSnackBar) {}

  open(data: Partial<NotificationMessage>): void {
    if (/\r|\n/.test(data.text)) {
      data.html = data.text.replace(/\r|\n/, '<br>');
      delete data.text;
    }

    this.snackBar.openFromComponent(NotificationMessageComponent, <
      MatSnackBarConfig
    >{
      ...notificationPosition,
      data: { ...data },
    });
  }

  success(data?: Partial<NotificationMessage>): void {
    this.snackBar.openFromComponent(NotificationMessageComponent, <
      MatSnackBarConfig
    >{
      ...notificationPosition,
      data: { ...Object.assign({ type: 'success' }, data) },
      duration: 2000,
    });
  }

  dismiss(): void {
    this.snackBar.dismiss();
  }
}
