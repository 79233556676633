import { InjectionToken, Type } from '@angular/core';

export interface CheckerCheck {
  [key: string]: any;
}

export interface CheckerPluginChecks {
  plugin: Type<CheckerPlugin>;
  checks?: CheckerCheck[];
}

export enum CheckerStatus {
  ok = 'ok',
  warn = 'warn',
  error = 'error',
}

export interface CheckerFinding {
  status: CheckerStatus;
  title: string;
  message?: string;
  link?: string;
}

export interface CheckerPluginFinding {
  check: CheckerCheck;
  finding: CheckerFinding;
}

export type CheckerFindings = Record<CheckerStatus, CheckerFinding[]>;

export interface CheckerPlugin {
  globalCheck(check: any): Promise<CheckerPluginFinding>;

  pageCheck(
    url: string,
    params: Record<string, any>,
    check: any,
  ): Promise<CheckerPluginFinding>;
}

export const CHECKER_PLUGINS: InjectionToken<CheckerPluginChecks[]> =
  new InjectionToken<CheckerPluginChecks[]>('checker.params');
