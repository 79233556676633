import { Inject, Injectable, Injector, Type } from '@angular/core';

import {
  CheckerPlugin,
  CheckerPluginFinding,
  CHECKER_PLUGINS,
  CheckerPluginChecks,
  CheckerFindings,
  CheckerStatus,
} from './checker.model';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class CheckerService {
  findings$: ReplaySubject<CheckerFindings> = new ReplaySubject();

  private _globalFindings: CheckerPluginFinding[] = [];
  private _pageFindings: CheckerPluginFinding[] = [];

  constructor(
    @Inject(CHECKER_PLUGINS) private pluginsChecks: CheckerPluginChecks[] = [],
    private injector: Injector,
  ) {}

  private getPlugin(plugin: Type<CheckerPlugin>): CheckerPlugin {
    return this.injector.get(plugin);
  }

  async doGlobalCheck(): Promise<void> {
    const findings = [];
    for (const { plugin, checks } of this.pluginsChecks) {
      for (const check of checks) {
        findings.push(await this.getPlugin(plugin).globalCheck(check));
      }
    }

    this.globalFindings = findings;
  }

  async doPageCheck(
    path: string,
    params: Record<string, any>,
    pageChecks: CheckerPluginChecks[] = [],
  ): Promise<void> {
    const findings = [];
    for (const { plugin, checks } of pageChecks) {
      for (const check of checks) {
        findings.push(
          await this.getPlugin(plugin).pageCheck(path, params, check),
        );
      }
    }
    this.pageFindings = findings;
  }

  consolidate(...findings: CheckerPluginFinding[]): CheckerFindings {
    const output: CheckerFindings = {
      [CheckerStatus.ok]: [],
      [CheckerStatus.warn]: [],
      [CheckerStatus.error]: [],
    };

    for (const f of findings) {
      output[f.finding.status].push(f.finding);
    }

    return output;
  }

  updateFindings(): void {
    this.findings$.next(
      this.consolidate(...this._globalFindings, ...this._pageFindings),
    );
  }

  set globalFindings(findings: CheckerPluginFinding[]) {
    this._globalFindings = findings;
    this.updateFindings();
  }

  set pageFindings(findings: CheckerPluginFinding[]) {
    this._pageFindings = findings;
    this.updateFindings();
  }
}
