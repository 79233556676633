import { PipeTransform, Pipe } from '@angular/core';
/**
 * Iteratble to Array Pipe
 * Example:
 *  <div *ngFor="keyValuePair of someIterable | iterableToArray">
 *    key {{keyValuePair.key}} and value {{keyValuePair.val}}
 *  </div>
 */
@Pipe({ name: 'iterableToArray' })
export class IterableToArray implements PipeTransform {
  transform(value: any) {
    const result: Array<any> = [];
    value = typeof value === 'string' ? value.split('') : value;
    if (value[Symbol.iterator]) {
      value.forEach((val, key) => {
        result.push({ key, val });
      });
    }
    return result;
  }
}
