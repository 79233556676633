import { NavigationExtras, Router } from '@angular/router';
import { WindowRefService } from './window-ref.service';
import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  constructor(
    private router: Router,
    private windowRefService: WindowRefService,
  ) {}

  getRouterUrl(urlSegments: any[], extras?: NavigationExtras): string {
    return this.router.serializeUrl(
      this.router.createUrlTree(urlSegments, extras),
    );
  }

  openInNewWindow(urlSegments: any[], extras?: NavigationExtras): Window {
    const url = this.getRouterUrl(urlSegments, extras);
    return this.windowRefService.nativeWindow.open(url, '_blank');
  }

  navigate(urlSegments: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(urlSegments, extras);
  }
}
