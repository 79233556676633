// Spreadsheet Api interfaces
export namespace GapiSheets {
  export enum NumberFormatType {
    numberFormatTypeUnspecified = 'NUMBER_FORMAT_TYPE_UNSPECIFIED',
    text = 'TEXT',
    number = 'NUMBER',
    percent = 'PERCENT',
    currency = 'CURRENCY',
    date = 'DATE',
    time = 'TIME',
    dateTime = 'DATE_TIME',
    scientific = 'SCIENTIFIC',
  }

  export enum Dimension {
    dimensionUnspecified = 'DIMENSION_UNSPECIFIED',
    rows = 'ROWS',
    columns = 'COLUMNS',
  }

  export interface Spreadsheet {
    spreadsheetId?: string;
    properties: SpreadsheetProperties;
    sheets: Sheet[];
  }

  export interface SpreadsheetProperties {
    title: string;
    locale?: string;
    timeZone?: string;
    defaultFormat?: CellFormat;
  }

  export interface Sheet {
    properties: SheetProperties;
    data?: GridData[];
    protectedRanges?: ProtectedRange[];
  }

  export interface SheetProperties {
    title: string;
    sheetId?: number;
    index?: number;
    hidden?: boolean;
  }

  export interface ProtectedRange {
    protectedRangeId?: number;
    description?: string;
    namedRangeId?: string;
    range?: GridRange;
    warningOnly?: boolean;
  }

  export interface GridData {
    rowData: RowData[];
  }

  export interface RowData {
    values: CellData[];
  }

  export interface CellData {
    userEnteredValue?: any;
    readonly formattedValue?: string;
    readonly effectiveValue?: ExtendedValue;
    userEnteredFormat?: CellFormat;
    readonly effectiveFormat?: CellFormat;
  }

  export interface ExtendedValue {
    numberValue?: number;
    stringValue?: string;
    boolValue?: boolean;
    formulaValue?: string;
    errorValue?: ErrorValue;
  }

  export interface CellFormat {
    numberFormat?: NumberFormat;
    backgroundColor?: Color;
  }

  export interface ErrorValue {
    type?: string;
    message?: string;
  }

  export interface NumberFormat {
    type: NumberFormatType;
    pattern?: string;
  }

  export interface Color {
    red?: number;
    green?: number;
    blue?: number;
    alpha?: number;
  }

  export interface GridRange {
    sheetId?: number;
    startRowIndex?: number;
    startColumnIndex?: number;
    endRowIndex?: number;
    endColumnIndex?: number;
  }

  export interface GridCoordinate {
    sheetId?: number;
    rowIndex?: number;
    columnIndex?: number;
  }

  interface DimensionRange {
    dimension?: Dimension;
    endIndex?: number;
    sheetId?: number;
    startIndex?: number;
  }

  export interface AddSheetRequest {
    properties?: SheetProperties;
  }

  export interface AddSheetResponse {
    properties?: SheetProperties;
  }

  export interface UpdateCellsRequest {
    fields?: string;
    range?: GridRange;
    rows?: RowData[];
    start?: GridCoordinate;
  }

  export interface DeleteRangeRequest {
    range?: GridRange;
    shiftDimension?: string;
  }

  export interface AddProtectedRangeRequest {
    protectedRange: ProtectedRange;
  }

  export interface AddProtectedRangeResponse {
    protectedRange: ProtectedRange;
  }

  export interface DeleteProtectedRangeRequest {
    protectedRangeId: number;
  }

  export interface InsertDimensionRequest {
    inheritFromBefore?: boolean;
    range?: DimensionRange;
  }

  export interface Request {
    addSheet?: AddSheetRequest;
    updateCells?: UpdateCellsRequest;
    deleteRange?: DeleteRangeRequest;
    addProtectedRange?: AddProtectedRangeRequest;
    deleteProtectedRange?: DeleteProtectedRangeRequest;
    insertDimension?: InsertDimensionRequest;
  }

  export interface Response {
    addSheet?: AddSheetResponse;
    addProtectedRange?: AddProtectedRangeResponse;
  }

  export interface BatchUpdateSpreadsheetRequest {
    requests?: Request[];
  }

  export interface BatchUpdateSpreadsheetResponse {
    replies?: Response[];
    spreadsheetId?: string;
  }
}

// Custom interfaces
export interface DriveFile {
  id: string;
  name: string;
  modifiedTime?: string;
}
