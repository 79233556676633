import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@app/providers/auth/auth.service';

declare let window: any;

@Component({
  selector: 'callback-service',
  template: '',
})
export class CallbackComponent {
  constructor(route: ActivatedRoute, router: Router, injector: Injector) {
    const serviceId = route.snapshot.params.service;

    if (serviceId === 'github') {
      window.callbackData = {
        code: route.snapshot.queryParams.code,
        state: route.snapshot.queryParams.state,
      };
    } else if (serviceId === 'cognito') {
      injector
        .get(AuthService)
        .isAuthenticated()
        .finally(() => router.navigate(['login']));
    }
  }
}
