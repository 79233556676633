import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { MdePopoverModule } from '@app/shared/mde-popover';

import { MaterialDesignModule } from '../shared/md';
import { PipesModule } from '../shared/pipes';
import { ToolHeaderInfoComponent } from './tool-header-info/tool-header-info.component';
import { TooltipContentComponent } from './tooltip-content/tooltip-content.component';
import { DropdownAutocompleteComponent } from './dropdown-autocomplete/dropdown-autocomplete.component';
import { TableNestedRowsComponent } from './table-nested-rows/table-nested-rows.component';
import { CaretHandlerComponent } from './caret-handler/caret-handler.component';
import { OverlayButtonDirective } from './overlay-button/overlay-button.directive';
import { SelectSearchComponent } from './select-search/select-search.component';
import { KibanaGraphComponent } from './kibana-graph/kibana-graph.component';
import { MetadataInfoComponent } from './metadata-info/metadata-info.component';
import { SelectListComponent } from './select-list/select-list.component';
import { SelectOptionGroupSearchComponent } from '@app/atoms/select-option-group/select-option-group-search.component';
import { FieldErrorsComponent } from '@app/atoms/field-errors/field-errors.component';
import { ChartsModule } from '@app/shared/charts/charts.module';
import { FixEmojiService } from '@app/atoms/caret-handler/fix-emoji.service';

export const imports: Array<any> = [];

@NgModule({
  imports: [
    CommonModule,
    MaterialDesignModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    ChartsModule,
    PickerModule,
    MdePopoverModule,
  ],
  declarations: [
    ToolHeaderInfoComponent,
    TooltipContentComponent,
    DropdownAutocompleteComponent,
    TableNestedRowsComponent,
    CaretHandlerComponent,
    OverlayButtonDirective,
    SelectSearchComponent,
    SelectListComponent,
    KibanaGraphComponent,
    MetadataInfoComponent,
    SelectOptionGroupSearchComponent,
    FieldErrorsComponent,
  ],
  providers: [{ provide: EmojiService, useClass: FixEmojiService }],
  exports: [
    ToolHeaderInfoComponent,
    TooltipContentComponent,
    DropdownAutocompleteComponent,
    TableNestedRowsComponent,
    CaretHandlerComponent,
    OverlayButtonDirective,
    SelectSearchComponent,
    KibanaGraphComponent,
    MetadataInfoComponent,
    SelectListComponent,
    SelectOptionGroupSearchComponent,
    FieldErrorsComponent,
  ],
})
export class AtomsModule {}
