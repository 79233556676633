import { Provider } from '@angular/core';

import { AuthService } from '../auth.service';
import { CognitoService } from './cognito.service';
import { CognitoAuthService } from './cognito-auth.service';

export const cognitoAuthProviders: Provider[] = [
  CognitoService,
  {
    provide: AuthService,
    useClass: CognitoAuthService,
  },
];
