import { ModuleWithProviders, NgModule } from '@angular/core';
import { WindowService } from './window.service';
import { WindowRefService } from './window-ref.service';

@NgModule()
export class WindowModule {
  static forRoot(): ModuleWithProviders<WindowModule> {
    return {
      ngModule: WindowModule,
      providers: [WindowService, WindowRefService],
    };
  }
}
