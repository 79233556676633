import { Project } from '@app/providers/projects';
import { ALL_PROJECTS, FAVORITE_PROJECTS } from './plugin.model';

export class FavoriteProjects {
  private data: string[];
  private readonly key = 'jarvis:project:favorites';

  constructor(private storage: Storage = localStorage) {
    this.load();
  }

  toggle(project: Project) {
    if (this.isFavorite(project)) {
      const index = this.data.findIndex((fav) => project.short_name === fav);
      this.data.splice(index, 1);
      this.save();
    } else {
      this.data.push(project.short_name);
      this.save();
    }
  }

  isFavorite(project: Project): boolean {
    return this.data.includes(project.short_name);
  }

  private load(): void {
    try {
      this.data = JSON.parse(this.storage.getItem(this.key) || '[]');
    } catch {
      this.data = [];
    }
  }

  private save(): void {
    this.storage.setItem(this.key, JSON.stringify(this.data));
  }
}

export const favoriteProjects = new FavoriteProjects();
export const favoriteOrder = [FAVORITE_PROJECTS, ALL_PROJECTS];

export function favoritesGroupFn(project: Project): string {
  return favoriteProjects.isFavorite(project)
    ? FAVORITE_PROJECTS
    : ALL_PROJECTS;
}
