import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ProjectDialogData } from './project-dialog.model';
import { Project } from '@app/providers/projects';

@Component({
  selector: 'project-dialog',
  template:
    '<sp-project-selector [projects]="data.projects" (select)="onSelect($event)"></sp-project-selector>',
})
export class ProjectDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectDialogData,
  ) {
    this.data = Object.assign(new ProjectDialogData(), data);
  }

  onSelect(project: Project) {
    this.dialogRef.close(project);
  }
}
