import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type TimeData = Map<number, { [key: string]: number }>;

export enum TimeAggregate {
  monthly = 'monthly',
  weekly = 'weekly',
  daily = 'daily',
}

export const timeUnit = {
  [TimeAggregate.monthly]: 'month',
  [TimeAggregate.weekly]: 'week',
  [TimeAggregate.daily]: 'day',
};

export const maxTicks = {
  [TimeAggregate.monthly]: 24,
  [TimeAggregate.weekly]: 52,
  [TimeAggregate.daily]: 90,
};

// @dynamic
export class Counter {
  chartFn: Function;
  chartOptions: any;

  static create(
    icon: string,
    title: string,
    subtitle: string,
    badge: string,
    total = 0,
  ) {
    return new this(icon, title, subtitle, badge, total);
  }

  constructor(
    public icon: string,
    public title: string,
    public subtitle: string,
    public badge: string,
    public total: number,
  ) {}

  withTotal(total: number): this {
    this.total = total;
    return this;
  }

  withChart(chartFn: Function, chartOptions: any = {}): this {
    this.chartFn = chartFn;
    this.chartOptions = chartOptions;
    return this;
  }

  applyChartOptions(options: any) {
    if (this.chartOptions) {
      Object.keys(this.chartOptions).forEach((path) => {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const inner = keys.reduce(
          (target, slice) =>
            slice in target ? target[slice] : (target[slice] = {}),
          options,
        );

        inner[lastKey] = this.chartOptions[path];
      });
    }
  }

  openChart(): void {
    if (this.chartFn) this.chartFn();
  }

  from(count$: Observable<number>): Observable<Counter> {
    return count$.pipe(map((count) => this.withTotal(count)));
  }
}

export class ChartPoint {
  constructor(public x: number, public y: number) {}
}

export class ChartDataSet {
  fill: boolean;
  lineTension: number;
  pointRadius: number;
  pointHitRadius: number;
  pointHoverRadius: number;
  borderWidth: number;
  cubicInterpolationMode: string;

  constructor(public label: string, public data: (number | ChartPoint)[]) {}

  withStackedOptions(options: Partial<ChartDataSet> = {}): this {
    return Object.assign(
      this,
      {
        fill: true,
        lineTension: 0.1,
        pointRadius: 0,
        pointHitRadius: 3,
        pointHoverRadius: 3,
        borderWidth: 1,
      },
      options,
    );
  }
}
