<sp-content-toolbar>
  <div class="tool-title">Public Beta Features</div>
</sp-content-toolbar>

<sp-content-layout>
  <div class="feature-cards">
    <mat-card
      class="feature-card mat-mdc-elevation-specific mat-elevation-z6"
      [class.active]="status[beta]"
      *ngFor="let beta of betas"
    >
      <mat-card-header>
        <mat-card-title>{{ info[beta].title }}</mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="{{ info[beta].image }}"
        alt="{{ info[beta].title }}"
      />
      <mat-card-content>
        <p>{{ info[beta].description }}</p>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-flat-button
          color="primary"
          [disabled]="status[beta]"
          (click)="enable(beta)"
        >
          Enable
        </button>
        <button
          mat-flat-button
          [disabled]="!status[beta]"
          (click)="disable(beta)"
        >
          Disable
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</sp-content-layout>
