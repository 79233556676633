import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContent } from '@angular/material/sidenav';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';

import { GoogleAnalyticsService } from '@app/providers/google/analytics/google-analytics.service';
import { ProjectService, Project } from '@app/providers/projects';
import { ScrollService } from '@app/providers/scroll/scroll.service';
import { sidenavData, Sidenav } from '@app/shared/navigation/navigation.model';
import { ReleaseControlService } from '@app/providers/release-control/release-control.service';
import { NavigationService } from '@app/shared/navigation/navigation.service';
import { NotificationMessageService } from '@app/shared/notification-message/notification-message.service';
import { AuthService } from '@app/providers/auth/auth.service';
import { AuthProfile } from '@app/providers/auth/auth.model';
import { ConfigManagerService } from '@app/config-manager/shared/api.service';

declare let window: Window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  static CM_LAST_USER_KEY = 'jarvis:cm:last_user';

  @ViewChild('sidenavContent', { static: true })
  sidenavContent: MatSidenavContent;

  project: Project;
  sidenavTools: Sidenav[];
  sidenavTool: Sidenav;
  isProjectPage: boolean;

  constructor(
    private analytics: GoogleAnalyticsService,
    private projectService: ProjectService,
    private navigationService: NavigationService,
    private message: NotificationMessageService,
    private httpClient: HttpClient,
    private scroll: ScrollService,
    private releaseControl: ReleaseControlService,
    private authService: AuthService,
  ) {
    this.fixSWLatestHash();
    this.afterAuthActions();
    this.removeAriaOwns();
  }

  ngOnInit() {
    this.navigationService.isProjectPage$.subscribe(
      (isProjectPage) => (this.isProjectPage = isProjectPage),
    );
    // Start tracking user visited pages in Google Analytics
    this.analytics.trackLocation();

    this.sidenavTools = sidenavData;

    this.navigationService.tools$.subscribe((sidenav: Sidenav) => {
      this.sidenavTool = sidenav;
    });

    this.projectService.project$.subscribe((project: Project) => {
      if (project !== this.project) {
        this.project = project;
      }
    });

    this.releaseControl.listenForUpdates();

    // Check Adblock
    this.httpClient.get('./assets/ads.png', { responseType: 'blob' }).subscribe(
      () => {},
      () => {
        this.message.open({
          html: 'Jarvis has detected that you have the Adblock enabled.<br>Turn it off, please!',
        });
      },
    );
  }

  ngAfterViewInit(): void {
    this.scroll.setScroll(this.sidenavContent.elementScrolled());
  }

  /**
   * Remove the cache "ngsw:db:control" -> "/latest" when its value is null
   */
  private fixSWLatestHash(): void {
    const cacheSwDbControlKey = 'ngsw:db:control';
    const latestUrl = '/latest';

    if (!window || !window.caches || !window.caches.open) return;
    window.caches
      .open(cacheSwDbControlKey)
      .then((cache: Cache) => cache.match(latestUrl))
      .then((req: Response) => req.json())
      .then(({ latest }: { latest: string }) => {
        if (!latest) {
          return window.caches.delete(cacheSwDbControlKey);
        }
      })
      .catch(() => {});
  }

  /**
   * After user has profile actions handler
   */
  private afterAuthActions(): void {
    this.authService.profile$
      .pipe(first((profile) => !!profile && !!profile.email))
      .subscribe((profile) => {
        this.createUserInConfigManager(profile);
      });
  }

  /**
   * Create user in ConfigManager (only once per email)
   */
  private createUserInConfigManager({ email }: AuthProfile): void {
    if (localStorage.getItem(AppComponent.CM_LAST_USER_KEY) === email) return;

    ConfigManagerService.CreateUser(this.httpClient, email).subscribe(() => {
      localStorage.setItem(AppComponent.CM_LAST_USER_KEY, email);
    });
  }

  private removeAriaOwns(): void {
    document.addEventListener('DOMNodeInserted', () => {
      document
        .querySelectorAll('label[aria-owns]')
        .forEach((el) => el.removeAttribute('aria-owns'));
    });
  }
}
