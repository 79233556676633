import { Injectable } from '@angular/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { first, timeout } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { CognitoService } from './cognito.service';
import { CognitoProfile, CognitoUserData } from './cognito.model';

@Injectable()
export class CognitoAuthService implements AuthService {
  profile: CognitoProfile = null;
  profile$: ReplaySubject<CognitoProfile> = new ReplaySubject(1);
  authenticated$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private cognito: CognitoService) {
    this.listenUser();
  }

  async login(): Promise<void> {
    await this.cognito.login();
  }

  async logout(): Promise<void> {
    await this.cognito.logout();
  }

  async isAuthenticated(timeLimit: number = 5000): Promise<boolean> {
    return firstValueFrom(this.authenticated$.pipe(timeout(timeLimit)));
  }

  async getIdToken(): Promise<string> {
    return await this.cognito.getIdToken();
  }

  async getAccessToken(): Promise<string> {
    return await this.cognito.getAccessToken();
  }

  getAuthErrors(): string[] {
    return this.cognito.authErrors;
  }

  hasAuthErrors(): boolean {
    return this.cognito.authErrors.length > 0;
  }

  async hasGroup(group: string): Promise<boolean> {
    const groups = await this.cognito.getGroups();
    if (!groups) return false;

    return groups.some((g) => g === group);
  }

  private listenUser() {
    this.cognito.userChanges$.subscribe((user) => {
      this.authenticated$.next(!!user);
      if (!user) return;

      this.cognito
        .getUserData()
        .then(
          ({ id, username: name, attributes: { email } }: CognitoUserData) => {
            this.profile = { id, name, email };
            this.profile$.next(this.profile);
          },
        );
    });
  }
}
