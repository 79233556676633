import { Pipe, PipeTransform } from '@angular/core';

import { Content } from '@app/lomt/contents';

@Pipe({
  name: 'contentName',
})
export class ContentNamePipe implements PipeTransform {
  transform(content: number, contents: Array<Content>): string {
    const result = contents.find((row: Content) => row.id === content);
    return result.name;
  }
}
