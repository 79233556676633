import { Chronos } from '@app/shared/utils/chronos';

export const enum Message {
  Timezone = 'timezone',
}

export const tooltipMessages: Map<string, string> = new Map([
  [
    Message.Timezone,
    `Dates are shown in your local time ${Chronos.getZone()}.`,
  ],
]);
