import { Project } from '@app/providers/projects';

import { ALL_PROJECTS, RECENT_PROJECTS } from './plugin.model';

export class RecentProjects {
  private data: string[];
  private readonly key = 'jarvis:project:recent';

  constructor(private storage: Storage = localStorage) {
    this.load();
  }

  add(project: Project): void {
    if (this.isRecent(project)) return;

    this.data = [project.short_name, ...this.data].slice(0, 4);
    this.save();
  }

  isRecent(project: Project): boolean {
    return this.data.includes(project.short_name);
  }

  private load(): void {
    try {
      this.data = JSON.parse(this.storage.getItem(this.key) || '[]');
    } catch {
      this.data = [];
    }
  }

  private save(): void {
    this.storage.setItem(this.key, JSON.stringify(this.data));
  }
}

export const recentOrder = [RECENT_PROJECTS, ALL_PROJECTS];

export const recentProjects = new RecentProjects();

export function recentGroupFn(project: Project): string {
  return recentProjects.isRecent(project) ? RECENT_PROJECTS : ALL_PROJECTS;
}
