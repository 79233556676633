import { Injectable } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

import {
  Profile,
  allowedDomains,
  GoogleUser,
  TokenResponse,
  GSITokenClient,
} from './google-auth.models';
import { GoogleApiService } from '../api/google-api.service';

@Injectable()
export class GoogleAuthService {
  private static DRIVE_SCOPES: string[] = [
    'https://www.googleapis.com/auth/drive.readonly',
    'https://www.googleapis.com/auth/spreadsheets',
  ];

  profile: Profile;

  private initialized: Promise<void>;
  private tokenChanged$: Subject<TokenResponse> = new Subject();
  private _token: TokenResponse;
  private _user: GoogleUser;
  private client: GSITokenClient;
  public $hasDrivePermissions: Subject<boolean> = new Subject<boolean>();

  set user(value: GoogleUser) {
    this._user = value;
    this.profile = this.getProfileFromUser(value);
  }

  get user(): GoogleUser {
    return this._user;
  }

  set token(value: TokenResponse) {
    this._token = value;
    this.tokenChanged$.next(value);
  }

  get token(): TokenResponse {
    return this._token;
  }

  constructor(private googleApi: GoogleApiService) {}

  public async requestAccessToken() {
    const client = await this.onClientReady();
    await client.requestAccessToken();
  }

  private getProfileFromUser(user: GoogleUser): Profile {
    if (user == null) return null;
    return {
      id: user.sub,
      name: user.name,
      givenName: user.given_name,
      familyName: user.family_name,
      imageUrl: user.picture,
      email: user.email,
    };
  }

  private isAllowedDomain(domain: string) {
    return allowedDomains.includes(domain);
  }

  private tokenIsValid(): boolean {
    if (!this.user || !this.token) {
      return false;
    }
    const secondsToExpire = this.token.expires_at - moment().unix();
    return secondsToExpire > 0;
  }

  private removeUserData() {
    this.user = null;
    this.token = null;
  }

  private async loginCallback(response: TokenResponse): Promise<void> {
    if (!response) {
      return this.removeUserData();
    }

    this.$hasDrivePermissions.next(
      this.googleApi.hasGrantedAllScopes(
        response,
        ...GoogleAuthService.DRIVE_SCOPES,
      ),
    );

    const expiresAt = moment().add(response.expires_in, 'seconds');
    this.token = {
      ...response,
      expires_at: expiresAt.unix(),
    };
    this.user = await this.fetchUser();

    // Check domain after fetching user (user is required in case of logout)
    if (!this.isAllowedDomain(this.token.hd)) {
      return this.logout();
    }
  }

  private fetchUser(): Promise<GoogleUser> {
    return firstValueFrom(
      this.googleApi.getUserProfile(this.token.access_token),
    );
  }

  private getNextToken(): Promise<TokenResponse> {
    return firstValueFrom(this.tokenChanged$.pipe(first()));
  }

  private async initClient(): Promise<void> {
    await this.googleApi.onAuthLoaded();
    return (this.client = this.googleApi.initGISClient((token) =>
      this.loginCallback(token),
    ));
  }

  onClientReady(): Promise<GSITokenClient> {
    if (!this.initialized) {
      this.initialized = this.initClient();
    }

    return this.initialized;
  }

  private async fetchToken(): Promise<void> {
    const client = await this.onClientReady();
    client.requestAccessToken();

    if (!(await this.getNextToken())) {
      throw Error('No Google token found');
    }
  }

  async logout(): Promise<void> {
    await this.onClientReady();
    if (this.user?.email) {
      await this.googleApi.revoke(this.user.email);
    }

    this.removeUserData();
  }

  async getAccessToken(): Promise<string> {
    if (!this.tokenIsValid()) {
      await this.fetchToken();
    }
    return this.token.access_token;
  }
}
