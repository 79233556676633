<mat-form-field>
  <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
  <mat-select
    #selectElement
    [multiple]="multiple"
    [required]="required"
    [(ngModel)]="selected"
    (selectionChange)="onChange($event)"
    (blur)="onBlur()"
    [disableOptionCentering]="disableOptionCentering"
    [disabled]="disabled"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="itemFilterCtrl"
        placeholderLabel="Search..."
        noEntriesFoundLabel="No options found"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let item of filteredItems | async"
      [value]="getValueFromOption(item)"
      [disabled]="item.disabled"
      >{{ getOptionDisplay(item) }}</mat-option
    >
  </mat-select>
  <mat-error *ngIf="error">{{ error }}</mat-error>
</mat-form-field>
