import {
  ConfigLegacy,
  MetadataExcludedLegacy,
} from '@app/config-manager/editor/editor.model';
import { GapiSheets } from '@app/providers/google/sheets/google-sheets.model';

declare let kendo: any;

export interface SearializedCell {
  value: string;
  background?: string;
}

export interface ProjectFeatures {
  ignoreColumns: boolean;
  ignoreMetadata: boolean;
}

/**
 * Serialize a Spreadsheet cell
 *
 * @param {GapiSheets.CellData} cell
 * @returns {SearializedCell}
 */
export function serializeCell(cell: GapiSheets.CellData): SearializedCell {
  const sCell = Object.assign(
    {
      formattedValue: null,
      effectiveValue: {},
      effectiveFormat: {},
    },
    cell,
  );

  let value = sCell.formattedValue;
  if ('boolValue' in sCell.effectiveValue) {
    value = sCell.effectiveValue.boolValue;
  } else if ('numberValue' in sCell.effectiveValue) {
    value = sCell.effectiveValue.numberValue;
  }

  const background = rgb2css(sCell.effectiveFormat.backgroundColor);

  return { value, background };
}

/**
 * Extract the columns of a ConfigLegacy
 *
 * @param {ConfigLegacy} src
 * @returns {string[]}
 */
export function getColumnNames(src: ConfigLegacy): string[] {
  const columns = src.data.length ? Object.keys(src.data[0]) : [];

  if (src.metadata.excluded) {
    src.metadata.excluded.forEach(({ name }: MetadataExcludedLegacy) =>
      columns.push(name),
    );
  }

  return columns;
}

/**
 * Get the columns diff A - B (A columns not included in B)
 *
 * @param {string[]} a
 * @param {string[]} b
 * @returns {string[]}
 */
export function getColumnsDiff(a: string[], b: string[]): string[] {
  return a.filter((value) => !b.includes(value));
}

export function rgb2css(rgb: GapiSheets.Color): string {
  if (!rgb) return null;
  if (!rgb.red && !rgb.green && !rgb.blue) return null;
  if (rgb.red === 1 && rgb.green === 1 && rgb.blue === 1) return null;

  const color = Object.assign({ red: 0, green: 0, blue: 0 }, rgb);
  return kendo.Color.fromRGB(color.red, color.green, color.blue).toCss();
}

export function css2rgb(css: string): GapiSheets.Color {
  if (!css) return null;

  const color = new kendo.Color(css);
  return { red: color.r / 255, green: color.g / 255, blue: color.b / 255 };
}
